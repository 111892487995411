//
//  topMenu - scss for the menu located in header
//
//  2015 - Sam Pettersson
//

.topMenu {
  display: none;

  @include breakpoint(desktop) {
      display: block;
      z-index: 1;
      margin-top: -5px;
      right: 0px;
      position: relative;
      top: -8px;
  

  ul {
    margin-top: 5px;
    float: right;

    li {
      display: inline-block;
      padding: 10px 15px 10px 15px;
      background: none;
      transition: background 250ms $apple-ease, color 250ms $apple-ease, opacity 250ms $apple-ease;

      @include breakpoint(tiny) {
        padding: 10px 10px 10px 10px;
      }

      i {
        font-size: 20px;
        position: relative;
        top: 1px;
      }

      &.home {
          
      }

      &.languageInvoker {
        //display: none !important;
      }

      &.searchInvoker {
        &.-activated {
          background: none;

          i {
            color: $color-black;

            @include breakpoint(desktop) {
              color: inherit;
            }
          }
        }
      }

      .-menuActivated & {
        &.menuInvoker {
          opacity: 1;

          &:hover {
            background: none;

            & i {
              color: inherit;
            }
          }

          .fa-bars:before {
            content: '\f00d';
            position: relative;
            z-index: 7;
          }
        }
      }

      &:hover {
        background-color: $color-black;
        
        @include breakpoint(desktop) {
          background: none;
        }

        & i {
          color: $color-yellow;

          @include breakpoint(desktop) {
            color: inherit;
          }

        }
      }
    }
  }

  .-menuActivated & {
    z-index: auto;
  }
}
}
