.splash {
  background: $color-white url('/webdav/files/resources/img/background-welcome.png');
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .hexa {
    background: url('/webdav/files/resources/img/hexagon-welcome.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding: 160px;
    min-width: 600px;
    text-align: center;
  }

  h2 {
    font-size: 40px !important;
  }

  p {
    font-size: 20px !important;
    max-width: 300px;
  }

  &.-out {
    animation: animateSplash 1000ms forwards $apple-ease;
    animation-delay: 0ms;
  }

  @keyframes animateSplash {
    0% {
      opacity: 1;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1.1);
      visibility: hidden;
    }
  }

  @include breakpoint(tablet) {
    .hexa {
      padding: 120px;
    }

    h2 {
      font-size: 25px !important;
    }

    p {
      font-size: 15px !important;
    }

  }

}

.splashContainer {
  width: 100%;
  height: 100%;
}
