// General typography
body,
body a {
  font-family: $font-family;
  font-style: normal;
  font-size: $font-size;
}

b,
strong {
  font-weight: $font-weight-medium !important;
  line-height: inherit;
}

i,
em {
  font-size: italic;
  line-height: inherit;
}

.sv-text-portlet {
  h1,
  h2,
  h3,
  h4 {
    strong {
      font-weight: $font-weight-super !important;
    }
  }

}

.sv-portlet-image-caption {
  color: $color-ultra-dark-gray;
  font-family: $font-family;
  font-weight: $font-weight-normal;
  font-size: 14px !important;
  margin-top: 5px !important;
  text-align: right;
}

blockquote.sv-blockquote,
blockquote.sv-blockquote-edit {
  position: relative;
  text-align: center;
  padding: 40px;
  background: none;

  &:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-ultra-light-gray-alt;
    border-top: dotted 1px $color-yellow;
    border-bottom: dotted 1px $color-yellow;
    transform: skewX(-8deg);
  }

  & p {
    font-size: 20px !important;
    font-family: $font-family !important;
    font-weight: $font-weight-super;
    color: $color-black;
    margin-bottom: 8px !important;

    & + p {
      font-size: 18px !important;
      font-family: $font-family !important;
      font-weight: $font-weight-normal;
      color: $color-ultra-dark-gray;
      margin-bottom: 0 !important;
    }
  }

}

.title {
  font-family: $font-family !important;
  font-weight: $font-weight-super !important;
  padding: 0;

  &__h1 {
    @extend .title;
    font-size: 40px !important;
    line-height: 1.25em !important;
    margin-bottom: .2em;

    @include breakpoint(mobile) {
      font-size: 30px !important;
    }
  }

  &__h2 {
    @extend .title;
    font-size: 24px !important;
    line-height: 1.25em !important;
    margin-bottom: .3em;

    @include breakpoint(mobile) {
      font-size: 22px !important;
    }
  }

  &__h3 {
    @extend .title;
    font-size: 20px !important;
    line-height: 1.4em !important;
    margin-bottom: .3em;

    @include breakpoint(mobile) {
      font-size: 17px !important;
    }
  }

  &__h4 {
    @extend .title;
    font-size: 18px !important;
    line-height: 1.4em !important;

    @include breakpoint(mobile) {
      font-size: 17px !important;
    }
  }
}

h1 {
  @extend .title__h1;
}

h2 {
  @extend .title__h2;
}

h3 {
  @extend .title__h3;
}

h4 {
  @extend .title__h4;
}

p {
  display: inline-block !important;
  font-family: $font-family !important;
  font-weight: $font-weight-normal;
  font-size: 16px !important;
  line-height: 1.5em !important;

  @include breakpoint(mobile) {
    font-size: 15px !important;
  }
}

p.normal,
p.sv-font-normal-white,
p.sv-font-normal-gray, {
  font-family: $font-family !important;
  display: block !important;
  margin-bottom: 1em;
  line-height: 1.4em;
}

p.sv-font-ingress,
p.sv-font-ingress-white {
  display: block !important;
  font-family: $font-family !important;
  font-weight: $font-weight-light;
  font-size: 20px !important;
  line-height: 1.4em !important;
  margin-bottom: 1em;

  @include breakpoint(mobile) {
    font-size: 17px !important;
  }
}

p.normal strong,
.sv-text-portlet p.normal strong,
p.sv-font-ingress strong, {
  font-family: $font-family !important;
  font-weight: $font-weight-medium !important;
}

p.normal a,
p.sv-font-ingress a {
  font-family: $font-family !important;
  position: relative;
  color: $color-dark-gray;
  text-decoration: none;
  transition: color .2s $apple-ease;

  &:after {
    display: block;
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    content: '';
    background-color: $color-gray;
    height: 1px;
    transition: background-color .2s $apple-ease;
  }

  &:hover {
    color: $color-black;

    &:after {
      background-color: $color-black;
    }
  }
}

a {
  outline: 0;
}

.tableheading {
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 15px;
}
