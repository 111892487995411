.sv-login-form {
  color: $color-white;
  margin: 0 auto;
  width: 100%;

  text-align: center;

  form {
    display: inline-block;

    width: 80%;
    max-width: 500px;

    text-align: left;
    margin-top: 50px;
    margin-bottom: 50px;

    padding: 20px;

    label {
      color: $color-black;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      padding-bottom: 5px;
      margin-top: 10px;
    }

    input {
      width: 100%;
      border: 2px solid $color-black;
      outline: 0;
      padding: 10px;
      font-family: $font-family;
    }

    input[type="submit"] {
      margin-top: 10px;
    }
  }
}
