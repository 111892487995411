.api {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;

  h1, h2 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  h2, p {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-weight: 800;
  }

  .endpoint-call {
    padding: 20px;
    background: rgba($color-yellow, 1);
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-weight: 800;
    border-radius: 2.5px;
  }

  ul li {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
