.section {
  padding-top: 50px;
  padding-bottom: 50px;
  background-size: cover;

  & + .section {
    border-top: solid 1px $color-light-gray;
  }

  &.-small {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  &.-large {
    padding-top: 130px;
    padding-bottom: 130px;
  }

  &.-bottom {
    padding-bottom: 0;
  }

  &.-image {
    height: 30vw;
    max-height: 550px;
    min-height: 200px;
    background-size: cover;

    &.-tilted {
      background-position: left top;
      transform-style: preserve-3d;
      transform-origin: center center;
      transform: rotate(-1deg);
      margin-right: -10px;
      margin-left: -10px;
    }
  }

  &.-tilted {
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    border-top: none;

    @include breakpoint(tablet) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    &:last-of-type {
      margin-bottom: -25px;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: -10%;
      bottom: 0;
      left: -10%;
      background-size: cover;
      background-position: left top;
      transform-style: preserve-3d;
      transform-origin: center center;
    }

    // Background
    &.-black {

      &:after {
        background-color: $color-black;
        background-image: url('../img/patterns/feature-black.jpg');
      }

      & h2 {
        color: $color-yellow;
      }

    }

    &.-darkGray {

      &:after {
        background-color: $color-ultra-dark-gray;
        background-image: url('../img/patterns/feature-darkGray.jpg');
      }

    }

    &.-gray {

      &:after {
        background-color: $color-gray;
        background-image: url('../img/patterns/feature-grey.jpg');
      }

    }

    &.-yellow {

      &:after {
        background-color: $color-yellow;
        background-image: url('../img/patterns/feature-yellow.jpg');
      }

    }

    // Rotation
    &.-left {
      &:after {
        transform: rotate(-1deg);
      }
    }

    &.-right {
      &:after {
        transform: rotate(1deg);
      }
    }

    &.-straightBottom {
      overflow: hidden;

    }

    & + .-tilted {
      margin-top: -25px;
    }

    & + .section {
      border-top: none;
    }
  }

  // Legacy tilted sections, might still be used in some places
  &.-tiltedDark {
    position: relative;
    background: transparent;
    border: none;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: -10%;
      bottom: 0;
      left: -10%;
      transform: rotate(-1deg);
      background-color: $color-black;
      background-size: cover;
      background-position: left top;
      background-image: url('../img/patterns/dark-grey.png');
      transform-style: preserve-3d;
      backface-visibility: hidden;
    }

    & h2 {
      color: $color-yellow;
    }

    & + .section {
      border-top: none;
    }
  }

  &.-tiltedYellow {
    position: relative;
    background: transparent;
    border: none;

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      top: 0;
      right: -10%;
      bottom: 0;
      left: -10%;
      transform: rotate(-1deg);
      background-color: $color-yellow;
      background-size: cover;
      background-position: left top;
      background-image: url('../img/patterns/feature-yellow.jpg');
      transform-style: preserve-3d;
      backface-visibility: hidden;
    }

    & h2 {
      color: $color-black;
    }

    & + .section {
      border-top: none;
    }

  }

  &.-marginTop {
    margin-top: 30px;
  }

  &.-smallBottom {
    padding-bottom: 30px;
  }

  &.-smallTop {
    padding-top: 30px;
  }

  &.-borderTop {
    border-top: solid 1px $color-light-gray;
  }

  &.-borderless {
    border-top: none;
  }

  &.-lightGray {
    background-color: $color-ultra-light-gray-alt;
  }

  .sv-text-portlet:last-child .sv-text-portlet-content:last-child  {
    margin-bottom: 0;
  }

}
