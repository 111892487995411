//
// Layout
// --------------------------

// Breakpoints
$breakpoints: (
  tiny: 358px,
  mobile: 520px,
  tablet: 900px,
  handheld: 1024px,
  desktop: 1170px
) !default;

// Tweakpoints
$tweakpoints: ();

// Grid
$grid-width: 100% !default;
$grid-max-width: 1230px !default;
$grid-columns: 12 !default;
$grid-columns-width: (100 / $grid-columns) !default;

// Grid gutter
$grid-gutter: (
  'desktop': 15px,
  'tablet': 15px,
  'mobile': 10px
) !default;

// Grid column gutter
$grid-column-gutter: (
  'desktop': 15px,
  'tablet': 10px,
  'mobile': 5px
) !default;


//
// Typography
// --------------------------

// Font
$font-family: 'akzidenz-grotesk-next', sans-serif;
$font-family-icon: "FontAwesome", sans-serif !default;

$font-weight-super: 800;
$font-weight-medium: 500;
$font-weight-normal: 400;
$font-weight-light: 300;

$font-size: 17px !default; // Base font size


//
// Animation
// --------------------------

// Easings
$swift-out: cubic-bezier(.55, 0, .1, 1);
$apple-ease: cubic-bezier(.4, .01, .165, .99);
$apple-ease-two: cubic-bezier(.28, .11, .32, 1);

// Default easing
$default-easing: $swift-out !default;


//
// Colors
// --------------------------

$color-solid-black: #000000;
$color-black: #171717;
$color-light-black: #222222;
$color-ultra-light-black: #2E2E2E;
$color-ultra-dark-gray: #666666;
$color-dark-gray: #8F8F8F;
$color-gray: #BABABA;
$color-light-gray: #DDDDDD;
$color-ultra-light-gray: #F0F0F0;
$color-ultra-light-gray-alt: #F7F7F7;
$color-yellow: #FFD300;
$color-white: #FFFFFF;
$color-green: #3AAA35;
$color-red: #F55858;
$color-table-ultra-light-gray: #FBFBFB;

$color-facebook: #3b579d;
$color-twitter: #5faade;
$color-googleplus: #dd4b38;

//
// Fixed heights
// --------------------------
$header-height: 75px;
$startpage-background-height: 700px;
$startpage-background-noble-height: 70vh;
