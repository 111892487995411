.card {
  display: flex;
  flex-flow: column wrap;
  width: calc(25% - 22.5px);
  margin-right: 30px;
  margin-bottom: 30px;
  background-color: $color-white;

  .ieLessThan11 &,
  .no-flexbox & {
    display: inline-block;
    vertical-align: top;
  }

  &:nth-child(4n + 4) {
    margin-right: 0;
  }

  @include breakpoint(handheld) {
    width: calc(50% - 15px);

    &:nth-child(2n + 2) {
      margin-right: 0;
    }
  }

  @include breakpoint(tablet) {
    width: calc(50% - 7px);
    margin-right: 14px;
    margin-bottom: 14px;
  }

  @include breakpoint(tiny) {
    width: 100%;
    margin-right: 0;
  }

  &__link {
    flex: 1;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    text-decoration: none;
    border: 1px solid $color-light-gray;
    transition: border .2s $apple-ease;

    &:hover,
    &:focus {
      border-color: $color-dark-gray;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      display: block;
    }
  }

  &__figure {
    height: 150px;
    width: 100%;
    background-image: url('../img/patterns/feature-grey-small.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__new {
    position: relative;
    left: calc(100% - 50px);
    top: 50px;
    height: 50px;
    width: 50px;
    margin-top: -50px;

    &.-large {
      left: calc(100% - 81px);
      top: -1px;
      height: 80px;
      width: 80px;
      margin-top: -80px;
    }
  }


  &__body {
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    padding: 15px 15px 10px;
    white-space: normal;

    & p {
      font-size: 15px !important;
      max-width: 100%;
      word-break: break-word;

      @include breakpoint(mobile) {
        font-size: 13px !important;
      }
    }
  }

  &__tags {
    width: 100%;
    text-align: center;
    font-size: 0;
    padding: 0 10px 10px;
    white-space: normal;

    & .tag {
      margin: 2px;
    }
  }

  &__tag {
    margin-top: 4px;
  }

  &__footer {
    align-self: flex-end;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 30px);
    margin: 0 15px;
    padding: 12px 0 16px;
    border-top: 1px dotted $color-gray;

    @include breakpoint(mobile) {
      padding: 6px 0 12px;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      @include clearfix;
      display: block;
    }

    & .card__tag {
      .ieLessThan11 &,
      .no-flexbox & {
        float: right;
        margin-top: 0;
      }
    }
  }

  &__date {
    display: inline-block;
    vertical-align: middle;
    margin-top: 4px;
    font-size: 12px;
    font-family: $font-family;
    color: $color-ultra-dark-gray;

    @include breakpoint(mobile) {
      font-size: 8px;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      float: left;
      margin-right: 4px;
      margin-top: 6px;
    }
  }

  &__star {
    .ieLessThan11 &,
    .no-flexbox & {
      float: left;
      margin-right: 4px;
    }
  }

  &__more {
    display: inline-block;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    color: $color-ultra-dark-gray;
    font-size: 16px;
    transition: color .2s $apple-ease;

    @include breakpoint(tablet) {
      font-size: 14px;
    }

    &:hover {
      color: $color-black;
    }

    & span {
      font-family: inherit;
      font-weight: inherit;
    }

    & p {
      font-family: $font-family !important;
      font-weight: $font-weight-medium;
    }

    & i {
      vertical-align: baseline;
      color: $color-yellow;
      margin-left: 6px;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      float: right;
      margin-top: 6px;
    }
  }

  &.-product {
    text-align: center;

    & .card__figure {
      height: 200px;
      margin-top: 15px;
      margin-right: 5px;
      margin-left: 5px;
      width: calc(100% - 10px);
      background-size: contain;
      background-image: none;

      @include breakpoint(mobile) {
        height: 140px;
      }
    }
  }

}
