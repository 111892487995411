.button {
  display: inline-block;
  cursor: pointer;

  &__container {
    position: relative;
    overflow: hidden;
    display: inline-block;
    border-radius: 5px;
    background: $color-yellow;
    z-index: 1;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08);
    transition: box-shadow 200ms;

    a {
      padding: 18px 70px 18px 70px;
      display: inline-block;
      text-decoration: none;
      font-size: 16px;
      position: relative;
      z-index: 2;
      font-family: $font-family;
      font-weight: $font-weight-medium;

      @media all and (max-width: 960px) {
        padding: 18px 35px 18px 35px;
      }
    }

    p {
      margin-top: 0 !important;
    }

    &:hover {
      &:before {
        transform: translate3d(-25%,0,0) skewX(-20deg);
      }
    }

    &:before {
      content: '';
      position: absolute;
      // TODO: fix color
      background: #f2c800;
      width: 150%;
      height: 100%;

      left: 0;
      top: 0;

      transform: translate3d(-125%,0,0) skewX(-20deg);
      transition: transform 800ms $apple-ease;
    }

    &:active {
      box-shadow: none;
    }

    // Sitevision editor fix
    .sv-portlet {
      display: none;
    }

    .sv-handle-move, .sv-editor-frame {
      display: none !important;
    }
  }
}

input[type="submit"] {
  @extend .btn;
  margin-top: 30px !important;
}

.button-secondary {
  display: inline-block;
  background: $color-white;
  border: 1px solid $color-dark-gray;
  padding: 10px 20px 10px 20px;
  border-radius: 30px;
  transition: border-color 250ms $apple-ease;

  span {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    color: $color-dark-gray;

    transition: color 250ms $apple-ease;
    font-size: 15px;
  }

  &.-button-agree {
    span {
      &:before {
        content: '\f00c';
        font-family: $font-family-icon;
        color: $color-yellow;
        padding-right: 10px;
      }
    }
  }

  &.-button-consent {
    span {
      &:before {
        content: '\f15c';
        font-family: $font-family-icon;
        color: $color-yellow;
        padding-right: 10px;
      }
    }
  }

  &:hover {
    span {
      color: $color-black;
    }
  }

  &:active {
    border: 1px solid $color-black;
  }
}

// New button class
.btn {
  @include reset-input('button');
  position: relative;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  padding: 23px 40px 20px;
  border-radius: 2px;
  overflow: hidden;
  border-bottom: 1px solid #e6bf00;
  width: 100%;
  font-family: $font-family;
  font-weight: $font-weight-medium;
  font-size: 16px;
  line-height: 1em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  color: $color-black;
  background-color: $color-yellow;
  user-select: none;

  @include breakpoint(tablet) {
    padding-right: 25px;
    padding-left: 25px;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 120%;
    height: 100%;
    background-color: #f2c800;
    transform: translateX(-110%) skewX(-20deg);
    transition: transform .4s $apple-ease;
    will-change: transform;
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
      transform: translateX(-10%) skewX(-20deg);
    }
  }

  &:active {
    &:before {
      background-color: #edc600;
    }
  }

  @include breakpoint(tablet) {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  & + .btn {
    margin-left: 6px;

    @include breakpoint(tablet) {
      margin-left: 0;
    }
  }

  & span,
  & i {
    display: inline-block;
    vertical-align: baseline;
  }

  & i {
    color: $color-white;
    margin-top: -2px;
  }

  & span + i,
  & i + span {
    margin-left: 13px;
  }

  &.-outlined {
    position: static;
    width: auto;
    padding: 18px 25px 16px;
    border: 1px solid $color-light-gray;
    box-shadow: none;
    border-radius: 30px;
    background-color: #fff;
    color: $color-ultra-dark-gray;
    font-size: 14px;
    transition: color .2s $apple-ease, border .2s $apple-ease;

    &:before {
      display: none;
    }

    &:hover,
    &:focus {
      color: $color-black;
      border-color: $color-dark-gray;
    }

    &:active {
      color: $color-black;
      border-color: $color-black;
    }

    & i {
      color: $color-yellow;
    }

    & span + i,
    & i + span {
      margin-left: 8px;
    }
  }

  &.-autoWidth {
    width: auto;
  }

  &.-mobileRound {
    @include breakpoint(tablet) {
      width: 50px;
      height: 50px;
      padding-right: 0;
      padding-left: 0;

      & i {
        margin-left: 0 !important;
      }

      & span {
        display: none;
      }
    }
  }

  &.-round {
    width: 50px;
    padding-right: 0;
    padding-left: 0;

    & i {
      margin-left: 0;
    }

    & span {
      display: none;
    }
  }
}

// Sitevision type format buttons
.sv-font-button {
  width: 100%;
  margin-top: 1em;

  & a {
    @extend .btn;

    &:after {
      display: inline-block;
      vertical-align: baseline;
      content: '\f054';
      font-family: $font-family-icon;
      color: $color-white;
      margin-left: 14px;
      margin-top: -2px;
    }
  }
}

.sv-font-button-outlined,
.sv-font-button-outlined-white  {
  width: 100%;
  margin-top: 1em;

  & a {
    @extend .btn;
    @extend .-outlined;

    &:after {
      display: inline-block;
      vertical-align: baseline;
      content: '\f054';
      font-family: $font-family-icon;
      color: $color-yellow;
      margin-left: 8px;
      margin-top: -2px;
    }
  }
}

.sv-font-button-outlined-white {
  & a {
    border-color: $color-dark-gray;
    background: transparent;
    color: $color-white;

    &:hover,
    &:focus {
      border-color: $color-white;
      color: $color-white;
    }
  }
}
