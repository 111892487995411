$gutter: 25px;
$gutter-handheld: 14px;

.app {
  padding: $gutter 0;
  display: grid;
  grid-gap: $gutter 0;
  grid-template-columns: $gutter 1fr $gutter;
  align-content: start;
  @include breakpoint(tablet) {
    padding: $gutter-handheld 0;
    grid-gap: $gutter-handheld 0;
    grid-template-columns: $gutter-handheld 1fr $gutter-handheld;
  }
}

.app > * {
  grid-column: 2 / -2;
}

.app > .full {
  grid-column: 1 / -1;
}

.hs {
  display: grid;
  //grid-gap: calc(#{$gutter} / 2);
  grid-template-columns: $gutter;
  grid-template-rows: minmax(150px, 1fr);
  grid-auto-flow: column;
  grid-auto-columns: calc(20% - #{$gutter} * 2);
  @include breakpoint(handheld) {
    grid-template-columns: $gutter;
    grid-auto-columns: calc(35% - #{$gutter} * 2);
  }
  @include breakpoint(tablet) {
    grid-template-columns: $gutter-handheld;
    grid-auto-columns: calc(50% - #{$gutter-handheld} * 2);
  }
  @include breakpoint(mobile) {
    grid-template-columns: $gutter-handheld;
    grid-auto-columns: calc(85% - #{$gutter-handheld} * 2);
  }
  overflow-x: scroll;
  scroll-snap-type: x proximity;
  padding-bottom: calc(.75 * #{$gutter});
  margin-bottom: calc(-.25 * #{$gutter});
  @include breakpoint(tablet) {
    padding-bottom: calc(.75 * #{$gutter-handheld});
    margin-bottom: calc(-.25 * #{$gutter-handheld});
  }
}

.hs:before,
.hs:after {
  content: '';
  width: 0;
  //width: $gutter;
  @include breakpoint(tablet) {
    //width: $gutter-handheld;
  }
}


/* Demo styles */

.app {
  //width: 375px;
  //height: 667px;
  //background: #DBD0BC;
  //overflow-y: scroll;
  //overflow-x: scroll;
  @include breakpoint(desktop) {
    //overflow-x: hidden;
  }
}

.hs > li,
.hscard {
  display: flex;
  flex-flow: column wrap;
  width: auto;
  //width: calc(25% - 22.5px);
  //margin-right: 30px;
  //margin-bottom: 30px;
  margin: 0;
  background-color: $color-white;

  .ieLessThan11 &,
  .no-flexbox & {
    display: inline-block;
    vertical-align: top;
  }

  &:nth-child(4n + 4) {
    //margin-right: 0;
  }

  @include breakpoint(handheld) {
    //width: calc(50% - 15px);
    width: auto;

    &:nth-child(2n + 2) {
      //margin-right: 0;
    }
  }

  @include breakpoint(tablet) {
    //width: calc(50% - 7px);
    width: auto;
    //margin-right: 14px;
    //margin-bottom: 14px;
    margin-right: 0;
    margin-bottom: 0;
  }

  @include breakpoint(tiny) {
    width: auto;
    //width: 100%;
    margin-right: 0;
  }

  &__link {
    width: 100%;
    flex: 1;
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
    text-decoration: none;
    border: 1px solid $color-light-gray;
    transition: border .2s $apple-ease;

    &:hover,
    &:focus {
      border-color: $color-dark-gray;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      display: block;
    }
  }

  &__figure {
    height: 150px;
    width: 100%;
    background-image: url('../img/patterns/feature-grey-small.png');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  &__new {
    position: relative;
    left: calc(100% - 50px);
    top: 50px;
    height: 50px;
    width: 50px;
    margin-top: -50px;

    &.-large {
      left: calc(100% - 81px);
      top: -1px;
      height: 80px;
      width: 80px;
      margin-top: -80px;
    }
  }


  &__body {
    flex-grow: 1;
    width: 100%;
    overflow: hidden;
    padding: 15px 15px 10px;
    white-space: normal;

    & p {
      font-size: 15px !important;
      max-width: 100%;
      word-break: break-word;

      @include breakpoint(mobile) {
        font-size: 13px !important;
      }
    }
  }

  &__tags {
    width: 100%;
    text-align: center;
    font-size: 0;
    padding: 0 10px 10px;
    white-space: normal;

    & .tag {
      margin: 2px;
    }
  }

  &__tag {
    margin-top: 4px;
  }

  &__footer {
    align-self: flex-end;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 30px);
    margin: 0 15px;
    padding: 12px 0 16px;
    border-top: 1px dotted $color-gray;

    @include breakpoint(mobile) {
      padding: 6px 0 12px;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      @include clearfix;
      display: block;
    }

    & .hscard__tag {
      .ieLessThan11 &,
      .no-flexbox & {
        float: right;
        margin-top: 0;
      }
    }
  }

  &__date {
    display: inline-block;
    vertical-align: middle;
    margin-top: 4px;
    font-size: 12px;
    font-family: $font-family;
    color: $color-ultra-dark-gray;

    @include breakpoint(mobile) {
      font-size: 8px;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      float: left;
      margin-right: 4px;
      margin-top: 6px;
    }
  }

  &__star {
    .ieLessThan11 &,
    .no-flexbox & {
      float: left;
      margin-right: 4px;
    }
  }

  &__more {
    display: inline-block;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    color: $color-ultra-dark-gray;
    font-size: 16px;
    transition: color .2s $apple-ease;

    @include breakpoint(tablet) {
      font-size: 14px;
    }

    &:hover {
      color: $color-black;
    }

    & span {
      font-family: inherit;
      font-weight: inherit;
    }

    & p {
      font-family: $font-family !important;
      font-weight: $font-weight-medium;
    }

    & i {
      vertical-align: baseline;
      color: $color-yellow;
      margin-left: 6px;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      float: right;
      margin-top: 6px;
    }
  }

  &.-product {
    text-align: center;

    & .hscard__figure {
      height: 200px;
      margin-top: 15px;
      margin-right: 5px;
      margin-left: 5px;
      //width: calc(100% - 10px);
      background-size: contain;
      background-image: none;

      @include breakpoint(mobile) {
        height: 140px;
      }
    }
  }

  scroll-snap-align: center;
  //padding: calc(#{$gutter} / 2 * 1.5);
  padding: 0;
  padding-right: $gutter;
  @include breakpoint(tablet) {
    padding-right: $gutter-handheld;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  //background: #fff;
  //border-radius: 8px;
}


.no-scrollbar {
  scrollbar-width: none;
  margin-bottom: 0;
  padding-bottom: 0;
}
.no-scrollbar::-webkit-scrollbar {
  display: none;
}