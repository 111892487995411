.referenceBlock {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  li {
    display: flex;
    width: calc(25% - 20px);
    border: 1px solid $color-light-gray;
    font-size: 1em;
    //overflow: hidden;
    margin-right: 26.66px;
    //box-shadow: 0 1px 4px rgba(0,0,0,.3),0 0 40px rgba(0,0,0,.1) inset;
    margin-top: 20px;
    position: relative;
    transition: transform 250ms $apple-ease;

    &:before
    {
      content:"";
      position:absolute;
      z-index: 0;
      box-shadow:0 0 20px rgba(0,0,0,0.2);
      bottom: 7px;
      left: 5%;
      border-radius:100px / 10px;
      width: calc(90% - 5px);
      height: calc(90% - 7px);
      opacity: 0;

      animation: initFeatureShadow 500ms forwards;

      transition: box-shadow 250ms $apple-ease, height 250ms $apple-ease;

      transform: translateY(4px);

      @keyframes initFeatureShadow {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }

    &:hover {
      transform: translateY(-5px);

      &:before {
        box-shadow:0 0 50px rgba(0,0,0,0.4);
      }
    }

    &:nth-child(4n + 4) {
      margin-right: 0;
    }

    a {
      text-decoration: none;
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    .image {
      height: 280px;
      background-size: cover;
      position: relative;

      .description {
        position: absolute;
        background: rgba($color-white, 0.8);
        display: inline-block;
        width: calc(80% + 50px);
        left: -35px;
        padding: 10px 10px 10px 50px;
        bottom: 10%;
        transform: skewX(-12deg);

        h4 {
          transform: skewX(12deg);
        }
      }
    }

    .reference__footer {
      text-align: center;
      padding-top: 15px;
      position: relative;
      background: $color-white;
      flex: 1;

      p {
        color: $color-dark-gray;
        font-size: 12px !important;
        font-family: $font-family !important;
        font-weight: $font-weight-medium;
        max-width: 80%;
      }

      img {
        width: 30px;
        position: absolute;
        bottom: 10px;
        right: 10px;
      }

      ul {
        margin: 0 auto 0 auto;
        padding-bottom: 20px;
        min-height: 20px;
        text-align: center;
        width: 80%;

        li {
          display: inline-block;
          width: auto;

          font-size: 12px;
          padding: 5px 15px;
          margin-right: 10px;
          margin-top: 10px;

          box-shadow: none;

          &:before {
            display: none;
          }

          transform: none;
        }
      }
    }

    @media all and (max-width: 950px) {
      width: calc(50% - 10px);
      margin-right: 20px;

      &:nth-child(2n + 2) {
        margin-right: 0;
      }
    }

    @media all and (max-width: 500px) {
      width: calc(100%);
      margin-right: 0;
    }
  }
}
