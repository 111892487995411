.subCategoryCard {
  min-height: 390px;
  border: 1px solid $color-yellow;
  text-align: center;
  transition: border 250ms $apple-ease;

  &:hover {
    border: 1px solid $color-dark-gray;

    .link {
      background: $color-dark-gray;
      color: $color-white;

      &:after {
        color: $color-yellow;
      }
    }
  }

  &.-loading {
    animation: loadingSubCategoryCard 2000ms forwards;
    animation-iteration-count: infinite;

    .link {
      animation: loadingSubCategoryCardLink 2000ms forwards;
      animation-iteration-count: infinite;
    }

    @keyframes loadingSubCategoryCardLink {
      0% {
        background: $color-dark-gray;
        color: $color-yellow;
      }
      50% {
        background: $color-yellow;
        color: $color-white;
      }
      100% {
        background: $color-dark-gray;
        color: $color-yellow;
      }
    }

    @keyframes loadingSubCategoryCard {
      0% {
        border-color: $color-dark-gray;
      }
      50% {
        border-color: $color-yellow;
      }
      100% {
        border-color: $color-dark-gray;
      }
    }
  }

  a {
    text-decoration: none;
    display: flex;
    flex-flow: column;
    flex: 1;

    .ieLessThan11 &,
    .no-flexbox & {
      display: block;
    }
  }

  h3 {
    margin-top: 30px;
  }

  .card__image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;

    .image__figure {
      height: 200px;
      margin-top: 15px;
      margin-right: 5px;
      margin-left: 5px;
      width: calc(100% - 10px);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  .link {
    background: $color-yellow;
    padding: 20px;
    font-weight: $font-weight-medium;
    font-size: 16px;
    color: $color-black;
    display: block;
    transition: background 250ms $apple-ease, color 250ms $apple-ease;
    word-wrap: break-word;

    &:after {
      content: '\f054';
      font-family: "FontAwesome";
      color: $color-white;
      margin-left: 10px;
      margin-top: 14px;
      position: relative;
      top: 1px;
      transition: color 250ms $apple-ease;
    }
  }
}
