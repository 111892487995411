.cookie-banner {
  position: fixed;
  bottom: 0;
  padding: 9px 0 9px 0;
  background: rgba($color-light-gray, 0.95);
  z-index: 5;
  width: 100%;
  font-size: 15px;
  transform: translate3d(0, 100%, 0);

  transition: transform 500ms $apple-ease;

  .no-csstransforms3d & {
    left: 100%;
  }

  &.-activated {
    transform: translate3d(0, 0, 0);

    .no-csstransforms3d & {
      left: 0;
    }

    .content {
      &__container {
        .description {
          opacity: 1;
        }

        .button-secondary {
          opacity: 1;
        }
      }
    }
  }

  .content {
    width: 100%;
    //width: calc(100% - 250px);

    &__container {
      max-width: 1170px;
      margin: 0 auto;

      padding: 25px;

      .description {
        position: relative;
        top: 12.5px;

        opacity: 0;

        transition: opacity 1000ms $apple-ease;

        .no-cssanimations & {
          opacity: 1;
        }

        a {
          font-size: 15px;
        }
      }

      .button-secondary {
        float: right;
        opacity: 0;
        margin-left: 10px;

        transition: opacity 1000ms $apple-ease;

        .no-cssanimations & {
          opacity: 1;
          }
      }

      &:after {
        clear: both;
        display: table;
        content: '';
      }

      .consent {

      }

      .links {
          padding-top: 25px;
      }
    }

    @media all and (max-width: 1170px) {
      width: 100%;
    }
  }

  @media all and (max-width: 1170px) {
    .-menuActivated &.-activated {
      transform: translate3d(-250px, 0, 0);

      .no-csstransforms3d & {
        transform: translateX(-250px);
      }
    }
  }
  
  @media all and (max-width: 750px) {
    text-align: center;

    .content {
      &__container {
        .description {
          display: block;
          position: static;
          padding-bottom: 15px;
        }

        .button-secondary {
          float: none;
        }
      }
    }
  }
}