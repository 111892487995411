.search {
  &__header {
    background: $color-ultra-light-gray;
    padding-top: 25px;
    padding-bottom: 25px;

    .searchInput {
      margin-top: 15px;
      font-size: 0;

      input {
        width: 60%;
        outline: 0;
        border: 1px solid #DDD;
        padding: 14px 20px 14px 25px;
        border-radius: 3px 0 0 3px;
        border-right: 0;
        font-size: 16px;
        font-family: $font-family;
        display: inline-block;
        vertical-align: top;
      }

      button {
        width: 65px;
        margin-top: 0 !important;
        height: 49px;
        background: $color-yellow;
        border: 0;
        color: $color-white;
        font-size: 17px;
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
        cursor: pointer;
        outline: 0;

        &:after {
          content: '\f002';
          font-family: "FontAwesome";
          display: block;
        }
      }
    }
  }

  &__result {
    padding-top: 15px;
    padding-bottom: 25px;
    background: $color-ultra-light-gray;
  }

  h2 {
    padding-bottom: 10px;
    padding-top: 25px;
    margin-top: 25px;
    border-top: 1px solid $color-light-gray;

    &:first-child {
      margin-top: 0;
      border-top: 0;
      padding-top: 15px;
    }
  }

  .pages {
    li {
      background: $color-white;
      margin-top: 15px;
      padding: 25px;
      border: 1px solid $color-light-gray;
      transition: border 250ms $apple-ease;
      position: relative;

      &:hover,
      &:focus {
        border-color: $color-dark-gray;
      }

      &:after {
        content: '\f054';
        font-family: 'FontAwesome';
        position: absolute;
        font-size: 25px;
        color: $color-yellow;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
      }

      a {
        text-decoration: none;
        width: calc(100% - 50px);
        display: inline-block;
      }

      p {
        display: block !important;
      }

      .breadcrumb {
        display: block;
        color: $color-ultra-dark-gray;
        margin-top: 15px;
      }
    }
  }

}
