.footer {
  background-color: $color-white;

  &__row {
    padding: 40px 0;
    border-top: 1px solid $color-light-gray;

    @include breakpoint(tablet) {
      padding: 30px 0;
    }

    &.-social {
      border-top: none;
      padding: 30px 0 30px;
    }

    &.myFavorites__container {
      padding-top: 20px;
    }
  }

  &__links {
    text-align: center;
    color: $color-black;
    font-size: 17px;
    font-family: $font-family;

    ul {
      display: inline-block;
      padding: 0 10px 0 10px;

      li {
        display: inline-block;
        padding: 0 10px 0 10px;

        a {
          text-decoration: none;
          font-family: $font-family;
        }

        &:first-child {
          padding-left: 0;
        }

        i {
          color: $color-black;
          padding-right: 10px;
        }
      }
    }

    @media all and (max-width: 970px) {
      ul {
        display: block;
        padding: 0;
        padding-top: 10px;

        li {
          display: inline-block;
          padding-left: 0;
          padding-bottom: 10px;
        }
      }
    }
  }

  & .sv-row {
    display: flex;
    flex-flow: row wrap;
  }

  & .sv-column-6 {
    width: calc(50% - 16px) !important;
    margin-right: 0 !important;
    padding: 45px;
    background: url('/webdav/files/resources/img/patterns/black.jpg');
    transition: padding-bottom 500ms $apple-ease;
    overflow: hidden;
    background-size: cover;

    @include breakpoint(tablet) {
      width: 100% !important;
      margin-bottom: 20px;
      padding: 35px 25px;
    }

    & + .sv-column-6 {
      margin-left: 30px;

      @include breakpoint(tablet) {
        margin-left: 0;
      }
    }
  }

  & .title__h3.-tilted {
    background: $color-yellow;
    transform: skew(-5deg);
    display: inline-block;
    padding: 17px 30px 13px 30px;
    margin-bottom: 20px;

    & span {
      transform: skew(5deg);
      display: inline-block;
      margin-bottom: 0;
      padding: 0;
    }
  }

  & p {
    &.js-newsletter-form-response,
    &.subscription-form-response {
      margin-bottom: 0;
      color: $color-white;
      padding: 15px 0 0;
      font-size: 14px !important;
      font-weight: 500;
      display: none!important;

      &.-active {
          display: inherit!important;
      }
    }
  }

  & form {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    @include breakpoint(tablet) {
      margin-bottom: 40px;
    }

    & p {
      margin-bottom: 0;
      color: $color-white;
      padding: 15px 0 0;
      font-size: 14px !important;
      font-weight: 500;
    }

    & div {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      margin-bottom: 20px;

      & input {
        width: calc(50% - 20px);
        margin-right: 0 !important;

        @include breakpoint(mobile) {
          width: 100%;
        }

        & + input {
          margin-left: 20px;

          @include breakpoint(mobile) {
            margin-left: 0;
            margin-top: 20px;
          }
        }
      }
    }
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="tel"] {
    @include reset-input('text');
    font-family: $font-family;
    font-size: 16px;
    padding: 13px 15px 12px;
    border-radius: 2px;
    background-color: $color-white;
    flex-grow: 1;
    margin-right: 20px;
    border: solid 2px $color-white;

    @include breakpoint(mobile) {
      margin-right: 0;
    }

    &::placeholder {
      color: $color-black;
    }

    &.-invalid {
      border-color: $color-red;
    }

    &.full {
        margin-right: 0px;
        margin-bottom: 20px;
    }

  }

  label {
      padding-left: 20px;
      text-indent: -24px;
      font-size: 16px;
      color: $color-white;

      &.-invalid {
        color: $color-red;

        a {
          color: $color-red;
        }
      }

      + label {
          padding-top: 10px;
          padding-bottom: 20px;
        }
    }

    .consent {
      font-size: 16px;
      color: $color-white;

      &.-invalid {
        text-decoration: underline;
        text-decoration-color: $color-red;
      }
    }

    input[type="checkbox"] {
      width: 16px;
      height: 16px;
      padding: 0;
      vertical-align: bottom;
      position: relative;
      top: 1px;
      *overflow: hidden;
    }
  

  & button {
    @extend .btn;
    padding: 14px 25px 13px;
    width: auto;

    @include breakpoint(tablet) {
      margin: 0;
    }

    @include breakpoint(mobile) {
      width: 100%;
      margin-top: 20px;
    }

    & span + i,
    & i + span {
      margin-left: 8px;
    }

    &.-loading {

      &:before {
        transform: translateX(-10%) skewX(-20deg);
      }

      &::after {
        content: '';
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: -2px 0 -4px;
        border: solid 2px $color-white;
        border-left-color: transparent;
        border-radius: 50%;
        animation: spinner 1s linear infinite;
      }

      & span,
      & i {
        display: none;
      }
    }
  }

  .dropdown {
    width: 100%;
    z-index: 1;

    li {
      width: 100%;
    }
  }

}

.answers,
.newsletter {
  transition: all 200ms $swift-out;

  &.-expand {
    padding-bottom: 125px;
  }

  .g-recaptcha {
    
    overflow: hidden;
    margin-bottom: 0px;
    margin-top: 20px;
    
    div {
      margin-bottom: 0px;
    }

    .grecaptcha-badge {
        position: relative!important;
        right: calc(-100% + 70px)!important;
        bottom: 0px!important;

        &:hover {
            right: calc(-100% + 256px)!important;
        }
    }
  }


.sv-font-button {
  margin-bottom: 20px!important;

  a {
    width: calc((100% - 20px) / 2);

    + a {
        margin-left: 20px;
    }

    &:after {
      transition: transform 200ms $swift-out;
    }

    &.-activated {
      &:after {
        transform: rotate(90deg);
      }
    }
  }
}
}