.myFavorites__container {
  border-top: 1px solid $color-light-gray;
  display: none;

  &.-activated {
    display: block;
  }

  .myFavorites {

    .heading {
      .title {
        display: inline-block;
        position: relative;
        top: 6px;

        h3 {
          display: inline-block;
        }

        .clearBookmarks {
          display: inline-block;
          font-size: 15px;
          color: $color-dark-gray;
          position: relative;
          left: 10px;
          top: 5px;
        }
      }

      .share {
        display: inline-block;
        //display: none !important;
        float: right;

        .email {
          background: $color-white;
          border: 1px solid $color-light-gray;
          font-size: 15px;
          font-family: $font-family;
          font-weight: $font-weight-medium;
          padding: 15px;
          padding-left: 25px;
          padding-right: 25px;
          border-radius: 30px;
          color: $color-light-black;

          i {
            color: $color-yellow;
          }
        }

        .print {
          position: relative;
          top: 2px;
          color: $color-yellow;

          i {
            padding: 15px;
            border: 1px solid $color-light-gray;
            border-radius: 50%;
            font-size: 20px;
          }
        }
      }

      @media all and (max-width: 900px) {
        .title {
          width: 100%;
          text-align: center;
          margin-bottom: 10px;
        }

        .share {
          float: none;
          width: 100%;
          text-align: center;
          margin-top: 15px;
        }
      }

      &:after {
        content: '';
        clear: both;
        display: table;
      }
    }

    .emptyMessage {
      display: none;

      &.-activated {
        display: block;
      }
    }

    .favorites {
      display: flex;
      flex-wrap: wrap;

      li {
        width: 170px;
        text-align: center;
        border: 1px solid $color-light-gray;
        padding: 20px 15px 15px 15px;
        margin-right: 20px;
        margin-top: 20px;
        position: relative;
        display: inline-block;
        display: flex;
        flex-flow: column wrap;

        transition: border 250ms;

        &:hover, &:focus {
          border: 1px solid $color-dark-gray;
        }

        .ieLessThan11 &, .no-flexbox & {
          display: block;
        }

        & > .container {
          display: flex;
          flex: 1;
          flex-flow: row wrap;
          align-items: flex-start;

          .ieLessThan11 &, .no-flexbox & {
            display: block;
          }
        }

        .remove {
          position: absolute;
          top: 15px;
          right: 15px;
          color: $color-dark-gray;
          z-index: 5;
        }

        .image {
          height: 150px;
          width: 80%;
          margin: 0 auto;
          display: inline-block;

          .container {
            display: inline-block;
            vertical-align: middle;
            height: 150px;
            width: 100%;

            img {
              max-width: 100%;
              max-height: 100%;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .name {
          font-size: 20px;
          font-family: $font-family;
          font-weight: $font-weight-super;
          margin-top: 15px;
          display: block;
          word-wrap: break-word;
          padding-bottom: 15px;
          flex-grow: 1;
        }

        .divider {
          border-top: 1px dotted $color-dark-gray;
          margin-top: 15px;
        }

        .product__footer {
          width: 100%;

          .ieLessThan11 &, .no-flexbox & {
            display: block;
          }

          .container {
            display: flex;
            width: 100%;
            flex-flow: column wrap;
            align-self: flex-end;

            .divider {
              align-self: flex-start;
              width: 100%;
            }
          }
        }

        .moreInfo {
          float: right;
          text-decoration: none;
          padding-top: 15px;
          font-family: $font-family;
          font-weight: $font-weight-medium;
          color: $color-dark-gray;
          transition: color 250ms $apple-ease;
          width: 100%;
          text-align: right;

          &:after {
            content: '\f054';
            font-family: FontAwesome;
            padding-left: 10px;
            color: #FFD300;
          }

          &:hover {
            color: $color-black;
          }
        }

        &:after {
          display: table;
          clear: both;
          content: '';
        }

        @media all and (max-width: 900px) {

          .name {
            font-size: 17px !important;
          }

          .moreInfo {
            font-size: 14px !important;
          }

          width: calc(50% - 5px);
          margin-right: 0;
          margin-left: 10px;

          &:first-child, &:nth-child(2n + 3) {
            margin-left: 0;
          }
        }
      }
    }
  }
}
