.productSystem__container {
  background: url('/webdav/files/resources/img/patterns/dark-grey.png');
  background-size: cover;

  .productSystem {
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;

    h3 {
      color: $color-white;
    }

    p {
      color: $color-yellow;
    }

    ul {
      font-size: 0;
      margin-top: 20px;

      li {
        font-size: 1em;
        width: 25%;
        display: inline-block;
        vertical-align: top;

        .icon__holder {
          width: 100%;
          height: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        h3 {
          color: $color-yellow;
        }

        p {
          color: $color-white;
        }
      }
    }

    a {
      display: inline-block;
      text-align: center;
      text-decoration: none;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      padding: 15px 40px 15px 40px;
      border-radius: 30px;
      font-size: 15px;
      border: 1px solid $color-light-gray;
      color: $color-white;
      margin-top: 60px;

      &:after {
        content: '\f054';
        font-family: "FontAwesome";
        font-size: 13px;
        position: relative;
        left: 8px;
        color: $color-yellow;
      }
    }

    @media all and (max-width: 900px) {
      ul {
        li {
          width: 80%;
          margin: 25px auto 0 auto;

          &:first-child {
            margin: 0 auto;
          }
        }
      }
    }
  }
}
