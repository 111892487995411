.sv-form-portlet {
  margin-top: 20px;

  & label {
    font-family: $font-family;
    font-weight: 400 !important;
    font-size: 14px;
    color: $color-ultra-dark-gray;
  }

  & input[type="text"],
  & input[type="email"],
  & input[type="tel"],
  & input[type="number"] {
    border: solid 1px $color-light-gray;
    border-radius: 2px;
    box-shadow: none !important;
    padding: 12px 10px 10px;
    font-family: $font-family;
    font-size: 16px;
    line-height: 1em;
    color: $color-ultra-dark-gray;
    height: auto;

    &:focus {
      outline: none;
      border-color: $color-dark-gray;
    }
  }

  & input[type="submit"],
  & input[type="button"] {
    @extend .btn;
    margin-top: 0 !important;

    &:hover {
      background-color: $color-yellow;
      border-color: #e6bf00;
      color: $color-black;
    }
  }

}
