.dealersPage {
  background: $color-ultra-light-gray;

  .dealersHeader {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  #map {
    width: 100%;
    height: 600px;

    @media all and (max-width: 600px) {
      display: none;
    }
  }

  .partner {
    background: $color-white;
    display: inline-block;
    width: 350px;
    padding: 10px;
    font-size: 1rem;

    span {
      line-height: 100%;
      font-family: $font-family;
    }

    h3 {
      padding-bottom: 5px;
    }

    a {
      text-decoration: none;
    }

    .divider {
      border-top: 1px dotted $color-dark-gray;
      margin-top: 10px;
    }

    .contact {
      padding-top: 15px;
      padding-bottom: 5px;

      i {
        padding-right: 10px;
        color: $color-black;
      }

      .phone {
        width: 100%;
        display: block;
        font-size: 14px;
        color: $color-dark-gray;
      }

      .email {
        width: 100%;
        display: block;
        margin-top: 15px;
        font-size: 14px;
        color: $color-dark-gray;
      }
    }

    .sub {
      .directions {
        margin-top: 16px;
        display: inline-block;
        text-decoration: none;
        font-family: $font-family;
        font-weight: $font-weight-medium;
        font-size: 14px;
        padding: 10.5px 15px 10.5px 15px;

        i {
          color: $color-yellow;
          margin-right: 5px;
        }

        border: 1px solid $color-light-gray;
        border-radius: 30px;
      }

      .external {
        float: right;
        margin-top: -5px;

        a {
          display: inline-block;
          text-align: center;
          width: 40px;
          height: 40px;
          border: 1px solid $color-light-gray;
          border-radius: 50%;
          color: $color-ultra-dark-gray;

          i {
            position: relative;
            top: 10px;
            font-size: 13px;
            color: $color-yellow;
          }

          &:first-child {
            margin-right: 5px;
          }
        }
      }

      &:after {
        clear: both;
        display: table;
        content: '';
      }
    }

    @media all and (max-width: 1300px) {
      width: 48%;

      &:nth-child(3n + 3) {
        margin-right: 2%;
      }
    }

    @media all and (max-width: 700px) {
      width: 100%;
      margin-right: 0;
    }

    &.-googleWindow .sub .external {
      margin-top: 16px;
    }
  }

  .content {
    margin-top: -105px;
    z-index: 1;
    position: relative;
    width: 100%;
    background: $color-ultra-light-gray;
    padding: 25px;

    .filter {
      .fields {
        font-size: 0;

        .dropdown {
          width: 26%;
        }

        .dropdown--region {
          margin-left: 2%;
          margin-right: 2%;
        }

        .filterSearch {
          display: inline-block;
          width: 72%;
          position: relative;
          margin-left: 2%;
          //top: 4px;
          vertical-align: middle;

          input {
            width: 89%;
            outline: 0;
            border: 1px solid $color-light-gray;
            padding: 12px 20px 12px 17px;
            font-family: $font-family;
            font-size: 16px;
            border-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-right: 0;
            display: table-cell;
          }

          a {
            position: absolute;
            right: 0;
            top: 0;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            background: $color-yellow;

            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;

            text-decoration: none;

            width: 14%;
            height: 100%;

            padding-left: 25px;
            padding-right: 25px;

            font-family: $font-family;
            font-weight: $font-weight-medium;

            color: $color-white;

            .fa {
              position: relative;
              top: 12px;
              right: 2px;
            }
          }
        }

        @media all and (max-width: 1170px) {
          .dropdown {
            width: 49%;
          }

          .dropdown--region {
            margin-right: 0;
          }

          .filterSearch {
            width: 100%;
            margin-top: 15px;
          }
        }

        @media all and (max-width: 700px) {
          .dropdown {
            width: 100%;
          }

          .dropdown--region {
            margin-left: 0;
            margin-top: 20px;
          }
        }
      }

      .alphabetFilter {
        ul {
          margin-top: 20px;
          margin-left: 2px;
          line-height: 150%;

          li {
            margin-right: 2.5px;
            display: none;
            cursor: pointer;

            &.-active {
              display: inline-block;
            }

            a {
              text-decoration: none;

              &.-selected {
                font-weight: bold;
              }
            }
          }
        }
      }
    }

    .results {
      ul {
        margin-top: 20px;
        font-size: 0;

        li {
          background: $color-white;
          display: flex;
          width: 32%;
          padding: 20px;
          margin-top: 20px;
          margin-right: 2%;
          font-size: 1rem;
          flex-flow: column;

          .ieLessThan11 & {
            display: inline-block !important;
            vertical-align: top;
          }

          &:nth-child(3n + 3) {
            margin-right: 0;
          }

          .main {
            flex: 1;
          }

          .sub {
            .external {
              margin-top: 15px;
            }
          }

          @media all and (max-width: 950px) {
            width: 49%;

            &:nth-child(2n + 2) {
              margin-right: 0;
            }

            &:nth-child(3), &:nth-child(6n + 9) {
              margin-right: 2%;
            }
          }

          @media all and (max-width: 700px) {
            width: 100%;
            margin-right: 0;

            &:nth-child(3), &:nth-child(6n + 9) {
              margin-right: 0;
            }
          }
        }
      }
    }

    @media all and (max-width: 600px) {
      margin-top: 0;
    }
  }
}
