// Fix stupid sitevision things
.sv-portlet p {
  margin-top: 0;
}

.sv-responsive .sv-image-portlet img {
  width: auto;
}

.-full-width {
  width: 100%;
}

.-padding {
  padding-left: 25px;
  padding-right: 25px;

  @include breakpoint(tablet) {
    padding-right: 14px;
    padding-left: 14px;
  }
}

@media all and (min-width: 1170px) {
  .-hide-desktop {
    display: none !important;
  }
}

.sv-editable-layout.sv-empty-layout,
.sv-editable-layout.sv-linked-decoration .sv-decoration-content {
  display: none;
}

.sv-image-portlet img {
  max-width: 100% !important;
}

.sv-grid-responsive {
  padding-right: 25px;
  padding-left: 25px;

  @include breakpoint(tablet) {
    padding-right: 14px;
    padding-left: 14px;
  }
}

.sv-image-portlet.-full-width img {
  width: 100% !important;
  max-height: none !important;
  max-width: none !important;
}

.-splitTablet {
  .sv-column-6 {
    @include breakpoint(tablet) {
      width: 100% !important;
    }
  }

  .sv-column-3 {
    @include breakpoint(tablet) {
      width: 50% !important;
    }
  }
}


