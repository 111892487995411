.languageSwitcher {
  position: absolute;
  background: rgba($color-ultra-light-gray, 1);
  width: 100%;
  z-index: 1002;
  text-align: center;
  top: 0;
  max-height: 0;
  overflow: hidden;
  transition: max-height 500ms $apple-ease-two;

  &__container {
    padding-top: 50px;
    padding-bottom: 60px;
    opacity: 0;
    transform: translate3d(0, -40px, 0);
    transition: transform 500ms $apple-ease, opacity 300ms $apple-ease;

    .-closeLanguage {
      position: absolute;
      right: 0;
      font-size: 20px;
      color: $color-dark-gray;
    }

    h1 {
      font-size: 30px;
    }

    p {
      font-size: 14px;
    }

    .languages {
      ul {
        li {
          background-color: $color-white;
          display: inline-block;
          margin-right: 10px;
          margin-top: 30px;
          min-width: 110px;
          width: 110px;
          vertical-align: top;
          padding: 10px 5px;
          border: 1px $color-light-gray solid;
          position: relative;
          height: 170px;

          &:last-child {
            margin-right: 0;
          }

          .flag {
            &.-current {
              .svg_outline {
                display: block;
              }
            }

            .hover {
                display: block;
            }
          }

          a {
            display: inline-block;
            font-family: $font-family;
            font-weight: $font-weight-medium;
            text-decoration: none;
            width: 100%;
            position: absolute;
            bottom: 10px;
            left: 0;
            /*
            &:hover,
            .-current {
              .svg_outline {
                display: block;
              }
            }
            */
            &.-current {
              font-weight: $font-weight-super;
            }
          }

          svg {
            display: block;
            width: 80px;
            pointer-events: none;
            margin: auto;

            @keyframes dash {
              to {
                stroke-dashoffset: 0;
              }
            }

            & .svg_outline {
              stroke-dasharray: 1000;
              stroke-dashoffset: 1000;
              animation: dash 1.2s linear both;
            }
          }

          span {
            display: block;
            margin-top: 5px;
            font-family: inherit;
            font-weight: inherit;
            font-size: 0.8em;
          }

          div+span {
              height: 30px;
              font-size: 0.7em;
          }
        }
      }

      @media all and (max-width: 960px) {
        ul {
          li {
            img {
              width: 60px;
            }
          }
        }
      }
    }
  }

  &.-activated {
    max-height: none;

    .languageSwitcher__container {
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }
  }
}
