.referencePage {
  margin-top: 25px;

  .imageBlock {
    width: 100%;
    padding-right: 30px;

    .primary {
      padding: 0;
      background-size: cover;

      iframe {
        width: 100%;
        height: 100%;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    @media all and (max-width: 950px)  {
      & {
        display: none;
      }
    }
  }

  .imageBlock--mobile {
    display: none;
  }


  @media all and (max-width: 950px)  {
    .imageBlock--mobile {
      display: block;

      .imageBlock {
        display: block;
      }
    }
  }

  .pagecontent {
    // h1 {
    //   padding-bottom: 10px;
    // }

    // h2 {
    //   padding-bottom: 5px;
    // }

    // .sv-font-ingress {
    //   padding-bottom: 20px;
    // }
  }

  .sv-script-portlet {
    &:after {
      display: table;
      content: '';
    }
  }

  .tags {
    margin-bottom: 15px;
    width: 90%;
    float: left;

    ul {
      li {
        display: inline-block;
        margin-top: 15px;

        a {
          display: inline-block;
          font-size: 12px;
          text-decoration: none;
          padding: 10px 15px 10px 15px;
          border-radius: 30px;
          border: 1px solid $color-dark-gray;
          font-family: $font-family;
          font-weight: $font-weight-medium;
        }
      }
    }
  }

  .country {
    float: right;
    width: 8%;
    display: inline-block;
    margin-top: 15px;

    img {
      width: 40px;
    }
  }

  .quote {
    background: $color-ultra-light-gray;
    padding: 30px;
    text-align: center;
    transform: skewX(-8deg);
    border-top: 1px dotted $color-yellow;
    border-bottom: 1px dotted $color-yellow;
    margin-top: 25px;
    margin-bottom: 25px;

    .resetSkew {
      transform: skewX(8deg);

      p {
        color: $color-ultra-dark-gray;
        margin-top: 10px;
      }
    }
  }

  .referenceEmailSignup {
    background: url('/webdav/files/resources/img/patterns/dark-grey.png');
    padding: 25px;
    padding-left: 50px;
    padding-right: 50px;
    margin-bottom: 25px;

    &:after {
      display: table;
      clear: both;
      content: '';
    }

    .description {
      width: 50%;
      float: left;
      color: $color-white;

      h3 {
        color: $color-white
      }
    }

    .input {
      width: 50%;
      float: right;
      display: table;
      overflow: hidden;

      input {
        border-radius: 2.5px;
        width: 97.5%;
        padding: 13.5px 25px 13.5px 25px;
        font-family: $font-family;
        font-size: 16px;
        outline: 0;
        border: 0;
        display: table-cell;

        &::placeholder {
          color: $color-black;
        }
      }

      a {
        display: table-cell;
        min-width: 15%;
        background: $color-yellow;
        text-align: center;
        border-radius: 3px;
        text-decoration: none;
        font-family: $font-family;
        font-weight: $font-weight-medium;
        font-size: 14px;

        &:after {
          content: '\f1d8';
          font-family: FontAwesome;
          padding-left: 12px;
          color: #FFF;
        }
      }
    }
  }

  .relatedInformation {
    margin-bottom: 30px;

    ul {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        width: 20%;
        text-align: center;
        border: 1px solid #DDD;
        padding: 20px 15px 15px;
        margin-right: 30px;
        margin-top: 20px;
        position: relative;
        display: flex;
        flex-flow: column wrap;

        .ieLessThan11 &, .no-flexbox & {
          display: block;
        }

        .container {
          display: flex;
          flex: 1;
          flex-flow: column wrap;
          text-decoration: none;

          .ieLessThan11 &, .no-flexbox & {
            display: block;
          }
        }

        .remove {
          position: absolute;
          top: 15px;
          right: 15px;
          color: $color-dark-gray;
          z-index: 5;
        }

        .image {
          height: 150px;
          width: 80%;
          margin: 0 auto;
          display: inline-block;

          .container {
            display: inline-block;
            vertical-align: middle;
            height: 150px;
            width: 100%;

            img {
              max-width: 100%;
              max-height: 100%;
              position: relative;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        .name {
          font-size: 20px;
          font-family: $font-family;
          font-weight: $font-weight-super;
          margin-top: 15px;
          display: block;
          word-wrap: break-word;
          padding-bottom: 15px;
        }

        .divider {
          border-top: 1px dotted $color-dark-gray;
          margin-top: 15px;
        }

        .product__footer {
          display: flex;
          flex: 1;

          .ieLessThan11 &, .no-flexbox & {
            display: block;
          }

          .container {
            align-self: flex-end;
            align-items: flex-end;
            flex: 1;

            .divider {
              align-self: flex-start;
              width: 100%;
            }
          }
        }

        .moreInfo {
          float: right;
          text-decoration: none;
          padding-top: 15px;
          font-family: $font-family;
          font-weight: $font-weight-medium;

          &:after {
            content: '\f054';
            font-family: FontAwesome;
            padding-left: 10px;
            color: #FFD300;
          }
        }

        &:after {
          display: table;
          clear: both;
          content: '';
        }

        @media all and (max-width: 900px) {
          width: 100%;
          margin-right: 0;
        }
      }
    }
  }

  @media all and (max-width: 950px) {
    .sv-column-6 {
      width: 100% !important;
    }

    .pagecontent {
      margin-top: 20px;
    }

    .imageBlock {
      padding-right: 0;
    }
  }
}
