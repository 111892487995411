// Misc styling
.eventList {
  & li {
    font-size: 20px !important;
    font-family: $font-family !important;
    font-weight: $font-weight-medium;

    & + li {
      margin-top: .75em;
    }

    & a {
      font-family: inherit;
      font-size: inherit;
      position: relative;
      color: $color-dark-gray;
      text-decoration: none;
      transition: color .2s $apple-ease;

      &:after {
        display: block;
        position: absolute;
        bottom: 1px;
        left: 0;
        right: 0;
        content: '';
        background-color: $color-gray;
        height: 1px;
        transition: background-color .2s $apple-ease;
      }

      &:hover {
        color: $color-black;

        &:after {
          background-color: $color-black;
        }
      }
    }

    & img {
      margin-top: 1em;
    }
  }
}

.downloads {
  margin-top: 20px;

  & > a {
    display: inline-block;
    vertical-align: top;
    width: calc(20% - 30px);
    border: solid 1px $color-light-gray;
    text-decoration: none;
    margin-right: 30px;
    margin-bottom: 30px;

    &:nth-child(n + 5) {
      margin-right: 0;
    }

    & img {
      width: 100%;
    }

    & h4 {
      padding: 20px;
    }
  }
}

// Usersnap button
#us_report_button {
  @include breakpoint(tablet) {
    display: none;
  }
}

::selection {
  background-color: $color-yellow;
  color: $color-black;
}

#scroll {
    position: fixed;
    bottom: 220px;
    right: 110px;
    z-index: 7;

    @include breakpoint(handheld) {
        right: 100px;
        bottom: 240px;
    }

    @include breakpoint(mobile) {
        display: none;
    }
}

#scrollController {
    position: relative;
    //bottom: 75px;
    //right: 324px;
    visibility: visible;

    & > a {
        padding-top: 60px;

        & > span {
          position: absolute;
          top: 0;
          left: 50%;
          width: 46px;
          height: 46px;
          margin-left: 0px;
          //border: 1px solid $color-black;
          border-radius: 100%;
          box-sizing: border-box;
          background-color: $color-yellow;
          box-shadow: 0 5px 10px rgba(0,0,0,0.15);

          &::after {
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 198;
              content: '';
              width: 16px;
              height: 16px;
              margin: -12px 0 0 -8px;
              border-left: 2px solid $color-white;
              border-bottom: 2px solid $color-white;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
              box-sizing: border-box;
            }

          &::before {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 197;
              content: '';
              width: 44px;
              height: 44px;
              box-shadow: 0 0 0 0 rgba(0,0,0,.1);
              border-radius: 100%;
              opacity: 0;
              -webkit-animation: sdb03 3s infinite;
              animation: sdb03 3s infinite;
              box-sizing: border-box;
            }

          @-webkit-keyframes sdb03 {
              0% {
                opacity: 0;
              }
              30% {
                opacity: 1;
              }
              60% {
                box-shadow: 0 0 0 60px rgba(255,255,255,.1);
                opacity: 0;
              }
              100% {
                opacity: 0;
              }
            }
            @keyframes sdb03 {
              0% {
                opacity: 0;
              }
              30% {
                opacity: 1;
              }
              60% {
                box-shadow: 0 0 0 60px rgba(255,255,255,.1);
                opacity: 0;
              }
              100% {
                opacity: 0;
              }
            }
        }
    }
}

#scrollToTop {
    position: relative;
    margin-bottom: 60px;
    //bottom: 135px;
    //right: 324px;
    visibility: hidden;

    & > a {
        padding-top: 60px;

        & > span {
          position: absolute;
          top: 0;
          left: 50%;
          width: 46px;
          height: 46px;
          margin-left: 0px;
          //border: 1px solid $color-black;
          border-radius: 100%;
          box-sizing: border-box;
          background-color: $color-yellow;
          box-shadow: 0 -5px 10px rgba(0,0,0,0.15);
          -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);

          &::after {
              position: absolute;
              top: 50%;
              left: 50%;
              z-index: 198;
              content: '';
              width: 16px;
              height: 16px;
              margin: -10px 0 0 -8px;
              border-left: 6px double $color-white;
              border-bottom: 6px double $color-white;
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
              box-sizing: border-box;
            }
        }
    }
}

/*
.demo a {
  position: absolute;
  left: 50%;
  z-index: 2;
  display: inline-block;
  color: $color-yellow;
  letter-spacing: .1em;
  text-decoration: none;
  transition: opacity .3s;

  & a {
      & :hover {
      opacity: .5;
    }
  }
}
*/