ul, ol {
    font-family: $font-family !important;
}

.sv-text-portlet {
  & ol {
    font-family: $font-family !important;
    list-style: decimal;
    list-style-position: inside;

    .sv-font-ingress {
      list-style-position: inside;
      list-style-type: decimal;
      font-weight: $font-weight-light;
      font-size: 20px !important;
      line-height: 1.4em !important;
      margin-bottom: 1em;

      @include breakpoint(mobile) {
        font-size: 17px !important;
      }

      & li + li {
        margin-top: .5em;
      }
    }
  }

  & ul {
    list-style-type: none !important;
    position: relative;
    padding-left: 15px;

    li {
      &:before {
        width: 6px;
        height: 6px;
        background: $color-black;
        position: absolute;
        content: '';
        display: block;
        margin-top: 8px;
        margin-left: -15px;
        border-radius: 50%;
      }
    }
  }
}

.normal li + li {
  margin-top: .5em;
}

ol.generated-list-name-Listalternative,
ul.generated-list-name-Listalternative {
  font-family: $font-family !important;
  list-style: none !important;
  padding-left: 0;

  & li {
    position: relative;
    padding-left: 20px;
    line-height: 1.3em;
    margin-bottom: 10px;

    & + li {
      margin-top: .25em;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      content: '+';
      color: $color-yellow;
      font-size: 16px;
      background-color: transparent;
      margin: 0;
    }

    & ol {
      &:first-child {
        margin-top: .25em;
      }
      &:last-child {
        margin-bottom: .25em;
      }
      & li {
        padding-left: 0px;
        counter-increment: item;
        list-style-type: none;
        padding-left: 20px;

        &:before {
          font-weight: 500;
          color: $color-black;
          content: counter(item) ".";
        }
      }
    }
  }

  &.sv-font-normal-white li:before {
    //color: #fff;
  }
}

ol.generated-list-name-Numberedalt,
ul.generated-list-name-Numberedalt {
  font-family: $font-family !important;
  list-style: none !important;
  counter-reset: greenList;
  columns: 2;
  column-gap: 30px;

  @include breakpoint(tablet) {
    columns: 1;
  }

  & li {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    margin-top: 5px;
    counter-increment: greenList;

    &:before {
      position: absolute;
      top: -3px;
      left: 0;
      display: inline-block;
      content: counter(greenList);
      color: #fff;
      font-family: $font-family-icon;
      font-size: 14px;
      background: $color-green;
      border-radius: 2px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      user-select: none;
    }
  }
}

ol.generated-list-name-NumberedYellowRound,
ul.generated-list-name-NumberedYellowRound {
  font-family: $font-family !important;
  list-style: none !important;
  counter-reset: yellowList;

  & li {
    display: block;
    vertical-align: top;
    position: relative;
    padding-left: 35px;
    margin-bottom: 15px;
    margin-top: 5px;
    counter-increment: yellowList;

    &:before {
      position: absolute;
      top: -3px;
      left: 0;
      display: inline-block;
      content: counter(yellowList);
      color: $color-black;
      font-family: $font-family-icon;
      font-weight: 900;
      font-size: 14px;
      background: $color-yellow;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      user-select: none;
    }
  }
}

ol.generated-list-name-Lettered,
ul.generated-list-name-Lettered {
  font-family: $font-family !important;
  list-style: none !important;
  counter-reset: letterList;
  columns: 2;
  column-gap: 30px;

  @include breakpoint(tablet) {
    columns: 1;
  }

  & li {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-left: 55px;
    margin-bottom: 15px;
    margin-top: 5px;
    counter-increment: letterList;

    &:before {
      position: absolute;
      top: 8px;
      left: 0;
      content: '';
      width: 20px;
      height: 2px;
      background-color: $color-yellow;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 32px;
      display: inline-block;
      content: counter(letterList, lower-alpha);
      color: $color-ultra-light-black;
      font-family: $font-family;
      font-weight: $font-weight-super;
      font-size: 16px;
      line-height: 1em;
      user-select: none;
    }
  }
}
