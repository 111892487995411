.grid {
  @include clearfix;
  width: $grid-width;
  max-width: $grid-max-width;
  margin-left: auto;
  margin-right: auto;
  font-size: 0; // Remove unwanted whitespace between inline-blocks

  &.-fluid {
    max-width: $grid-width;
  }

  &.-pad {
    padding-left: map-get($grid-gutter, 'desktop');
    padding-right: map-get($grid-gutter, 'desktop');

    @include breakpoint(handheld) {
      padding-left: map-get($grid-gutter, 'tablet');
      padding-right: map-get($grid-gutter, 'tablet');
    }

    @include breakpoint(mobile) {
      padding-left: map-get($grid-gutter, 'mobile');
      padding-right: map-get($grid-gutter, 'mobile');
    }
  }

  // When you want nested grid to align with parent grid
  &__reset {
    margin-left: -(map-get($grid-gutter, 'desktop') + map-get($grid-column-gutter, 'desktop'));
    margin-right: -(map-get($grid-gutter, 'desktop') + map-get($grid-column-gutter, 'desktop'));

    @include breakpoint(handheld) {
      margin-left: -(map-get($grid-gutter, 'tablet') + map-get($grid-column-gutter, 'tablet'));
      margin-right: -(map-get($grid-gutter, 'tablet') + map-get($grid-column-gutter, 'tablet'));
    }

    @include breakpoint(mobile) {
      margin-left: -(map-get($grid-gutter, 'mobile') + map-get($grid-column-gutter, 'mobile'));
      margin-right: -(map-get($grid-gutter, 'mobile') + map-get($grid-column-gutter, 'mobile'));
    }
  }

  &__column {
    display: inline-block;
    vertical-align: top;
    min-height: 1px;
    font-size: $font-size;

    .grid.-pad & {
      padding-left: map-get($grid-column-gutter, 'desktop');
      padding-right: map-get($grid-column-gutter, 'desktop');

      @include breakpoint(handheld) {
        padding-left: map-get($grid-column-gutter, 'tablet');
        padding-right: map-get($grid-column-gutter, 'tablet');
      }

      @include breakpoint(mobile) {
        padding-left: map-get($grid-column-gutter, 'mobile');
        padding-right: map-get($grid-column-gutter, 'mobile');
      }
    }

    &.-no-pad {
      padding-right: 0;
      padding-left: 0;
    }

    // Desktop columns
    &.-desktop {

      @for $i from 1 through $grid-columns {
        &--#{$i} {
          width: #{$i * $grid-columns-width * 1%};
        }
      }

      // Set default width tablet
      @include breakpoint(tablet) {
        @for $i from 1 through $grid-columns {
          &--#{$i} {
            width: 100%;
          }
        }
      }
    }

    // Tablet columns
    &.-tablet {
      @include breakpoint(tablet) {
        @for $i from 1 through $grid-columns {
          &--#{$i} {
            width: #{$i * $grid-columns-width * 1%};
          }
        }
      }
      // Set default width mobile
      @include breakpoint(mobile) {
        @for $i from 1 through $grid-columns {
          &--#{$i} {
            width: 100%;
          }
        }
      }
    }

    // Mobile columns
    &.-mobile {
      @include breakpoint(mobile) {
        @for $i from 1 through $grid-columns {
          &--#{$i} {
            width: #{$i * $grid-columns-width * 1%};
          }
        }
      }
    }

  }
}
