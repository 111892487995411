.subPage {
  .back {
    margin-bottom: 25px;

    a {
      display: inline-block;
      text-align: center;
      text-decoration: none;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      padding: 20px 25px 20px 20px;
      border-radius: 30px;
      font-size: 15px;
      border: 1px solid $color-light-gray;
      color: $color-ultra-dark-gray;

      &:before {
        content: '\f053';
        font-family: "FontAwesome";
        padding-right: 10px;
        color: $color-yellow;
      }
    }
  }

  .heroImage {
    width: 100%;
    height: 65vh;//500px;
    background-size: cover;
    margin-top: 0;
    margin-bottom: 25px;
    position: relative;

    &.-videoPointer {
      cursor: pointer;
    }

    img {
      position: absolute;
      top: 209.5px;
      left: 50%;
      transform: translateX(-40.5px);
    }
  }

  .heroImage-column {
    padding-right: 30px;
  }

  @media all and (max-width: 900px) {
    .sv-column-6 {
      width: 100% !important;
      padding-right: 0;

      .heroImage {
        width: 80% !important;
        margin: 0 auto 25px auto;
        max-height: 500px;

        img {
          top: 209.5px;
        }
      }
    }

    .heroImage {
      max-height: 500px;

      img {
        top: 109.5px;
      }
    }

    .sv-column-8 {
      width: 100% !important;
      left: 0 !important;
    }
  }

  @media all and (max-width: 520px) {
    .sv-column-6 {
      width: 100% !important;
      padding-right: 0;

      .heroImage {
        width: 80% !important;
        margin: 0 auto 25px auto;
        max-height: 300px;

        img {
          top: 209.5px;
        }
      }
    }

    .heroImage {
      max-height: 300px;

      img {
        top: 109.5px;
      }
    }

    .sv-column-8 {
      width: 100% !important;
      left: 0 !important;
    }
  }

  .video {
    display: none;
  }

  &__topContent {
    margin-top: 25px;
    margin-bottom: 25px;

    h1 {
      margin-bottom: 10px;
    }

    p.sv-font-ingress {
      margin-bottom: 13px;
    }
  }

}
