
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; /* Fix 401 */
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
}
.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
     its values is > 50%. */
  z-index: 10;
}
.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base {
  -webkit-transform: translate3d(0,0,0);
  transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 15px;
}
.noUi-horizontal .noUi-handle {
  width: 35px;
  height: 35px;
  left: -17px;
  top: -10px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}

/* Styling;
 */
.noUi-background {
  background: $color-light-gray;
}

.noUi-connect {
  background: $color-yellow;
  transition: background 450ms;
  height: 100%;
}

.noUi-origin {
  border-radius: 20px;
}

.noUi-target {
  border-radius: 20px;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: n-resize;
}

.noUi-base {
  background: $color-yellow;
  border-radius: 20px;
}

.noUi-origin {
  background: $color-light-gray;
}

.noUi-handle {
  border: 2px solid $color-dark-gray;
  border-radius: 50%;
  background: #FFF;
  cursor: default;

  transition: box-shadow 250ms $apple-ease;
}

.noUi-active {
  box-shadow: inset 0 0 1px #FFF,
  inset 0 1px 7px #DDD,
  0 3px 6px -3px #BBB;
}

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: $color-light-gray;
  left: 13px;
  top: 9px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #B8B8B8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border-radius: 3px;
  background: rgba($color-light-black, 0.8);
  color: $color-white;
  padding: 5px;
  padding-top: 10px;
  text-align: center;
  font-size: 15px;
}

.noUi-horizontal .noUi-handle-lower .noUi-tooltip {
  top: -40px;
  left: -3px;
  opacity: 0;

  transition: opacity 250ms $apple-ease;

  &:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-top-color: rgba($color-light-black, 0.8);
    border-width: 5px;
    margin-left: -5px;
  }
}

.noUi-horizontal .noUi-handle-upper .noUi-tooltip {
  bottom: -40px;
  left: -5px;

  opacity: 0;

  transition: opacity 250ms $apple-ease;

  &:after {
    bottom: 100%;
    left: 40%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-bottom-color: rgba($color-light-black, 0.8);
    border-width: 5px;
  }
}

.noUi-active .noUi-tooltip, .noUi-state-tap .noUi-tooltip {
  opacity: 1 !important;
}

.noUi-vertical .noUi-handle-lower .noUi-tooltip {
  left: 120%;
}
.noUi-vertical .noUi-handle-upper .noUi-tooltip {
  right: 120%;
}

