#bauma-form,
#hillhead-form,
#subscription-form {
  padding: 20px 0 30px;

  input[type="text"],
  input[type="email"],
  input[type="tel"],
  input[type="number"],
  textarea {
    width: 100%;
    padding: 15px;
    border-radius: 2px;
    border: solid 1px #dddddd;
    line-height: 18px;
    font-size: 15px;
    margin: 10px 0 20px;

    &::-webkit-input-placeholder {
       color: #666666;
       font-size: 15px;
       line-height: 18px;
    }

    &:-moz-placeholder {
       color: #666666;
       font-size: 15px;
       line-height: 18px;
    }

    &::-moz-placeholder {
       color: #666666;
       font-size: 15px;
       line-height: 18px;
    }

    &:-ms-input-placeholder {
       color: #666666;
       font-size: 15px;
       line-height: 18px;
    }

    &:focus {
      outline: none;
    }

    &.error {
      border-color: red;
    }
  }

  textarea {
    height: 200px;
  }

  .text-label {
    margin: 20px 0 5px;

    span {
      font-size: 20px;
      line-height: 25px;
    }
  }

  label {
    display: inline-block;
    margin: 5px 15px;

    span {
      display: inline-block;
      padding: 3px;
      font-size: 16px;
    }

    input[type="radio"] {
      margin: 5px;
    }

    &.error {
      color: red;
    }
  }

  .bottom-separator {
    border-bottom: solid 1px #ececec;
    padding: 10px 0;
  }

  .form-message {
    display: none;
    text-align: center;
    padding: 20px 0 10px;

    span {
      font-size: 25px;
      font-weight: bold;
    }
  }

  span.category-label {
    display: block;
    margin-top: 20px;
    font-size: 16px;
    font-weight: 500;
  }
}
