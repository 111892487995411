.bookmark {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 31px;
  height: 31px;
  border: solid 1px $color-gray;
  border-radius: 50%;
  font-size: 17px;
  text-align: center;
  color: $color-ultra-dark-gray;
  transition: color .2s $apple-ease, border .2s $apple-ease, background .2s $apple-ease;

  &:hover {
    border-color: $color-black;
    background-color: $color-black;
    color: $color-white;
  }

  &.-bookmarked {
    border-color: $color-yellow;
    background-color: $color-yellow;
    color: $color-white;
  }

  & i {
    position: absolute;
    top: 7px;
    left: 7px;
  }

  &__block {
    display: block;
  }
}
