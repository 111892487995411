.articlePage {
  //margin-top: 35px;
  margin-bottom: 35px;

  @include breakpoint(tablet) {
    //margin-top: 20px;
  }

  & img {
    max-width: 100% !important;
  }
  /*
  & .sv-image-portlet {
    //margin-bottom: 20px;

    & img {
      position: relative;
    }
  }
      */
  .articleTopImage {
    height: 55vh;
    @include breakpoint(tablet) {
        height: 500px;
    }
    @include breakpoint(mobile) {
        height: 300px;
    }
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;

      & .sv-image-portlet {
        //margin-bottom: 20px;

        & img {
          width: 100%;
          max-height: 100% !important;
          height: 100%;
          object-fit: cover;
          z-index: -1;
          position: absolute;
        }
      }
  }
}

.articleData {
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  align-items: center;
  margin-bottom: 15px;
  font-size: 0;

  @include breakpoint(tablet) {
    margin-bottom: 10px;
  }

  .ieLessThan11 &,
  .no-flexbox & {
    display: block;
  }

  &__date {
    display: inline-block;
    margin-top: 6px;
    font-size: 16px;
    font-family: $font-family;
    color: $color-ultra-dark-gray;

    & i {
      display: inline-block;
      margin-right: 5px;
    }

    @include breakpoint(tablet) {
      font-size: 12px;
      margin-right: 15px;
      margin-top: 0;
      margin-bottom: 6px;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      vertical-align: middle;
      width: 50%;
      text-align: left;
    }

  }

  &__tags {
    flex-grow: 1;
    text-align: right;
    align-self: flex-end;
    display: inline-block;

    @include breakpoint(tablet) {
      margin-bottom: 6px;
    }

    & li + li {
      margin-left: 5px;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      vertical-align: middle;
      width: 50%;
      text-align: right;
    }
  }

}
