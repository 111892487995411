.pageContainer {
  position: relative;
  width: 100%; // fallback
  //width: calc(100% - 250px);
  min-width: 320px;
  min-height: 100vh;
  background: $color-white;
  transition: width 500ms $apple-ease, transform 500ms $apple-ease;
  z-index: 2;
  //overflow: hidden;
  display: flex;
  flex-direction: column;

  .ieLessThan11 & {
    display: block;
  }

  // shadow that applies to whole page when context menus are open
  &:before {
    content: '';
    height: 100%;
    max-width: 0;
    width: 100%;
    background: $color-black;
    position: absolute;
    //top: $header-height;
    left: 0;
    z-index: 1001;
    transition: max-width 0ms $apple-ease 500ms, opacity 500ms $apple-ease 0ms;
    opacity: 0;
    @media all and (max-width: 1170px) {
        top: $header-height;
    }
  }

  @mixin showFullPageShadow() {
    max-width: 100%;
    opacity: 0.6;
    transition: max-width 50ms $apple-ease, opacity 500ms $apple-ease 50ms;
  }

  .-searchActivated &:before, .-languageActivated &:before {
    @include showFullPageShadow();
  }

  @media all and (max-width: 1170px) {
    width: 100%;

    &.-menuActivated {
      transform: translate3d(-250px, 0, 0);

      .no-csstransforms3d & {
        transform: translateX(-250px);
      }
    }

    &.-menuActivatedFixed {
      position: fixed;
    }

    &.-menuActivated:before {
      @include showFullPageShadow();
    }
  }
}