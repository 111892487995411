.videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}
.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.nobleImage {
  width: 100%;
    
  .owl-carousel {
    margin:0;
    width:100%;
    z-index:0;

    .item {
      opacity:1;
      //width: calc(100vw - 265px);
      width: 100vw;
      margin:0!important;

      height: $startpage-background-noble-height;
      min-height: $startpage-background-height;

      @include breakpoint(mobile) {
        height: auto;
        min-height: 500px;
      }

      @include breakpoint(desktop) {
        padding-right:0px;
        width: calc(100vw);
      }

      img {
        width: auto;
      }
    }

    .background-image { 
      position: relative;
      background-repeat: no-repeat;
      //background-position: center center;
      background-size: cover;
    }

    .active .item{
      opacity:1;
      margin:0;
    } 
   
    .owl-item {
      -webkit-backface-visibility: hidden;
      //margin-right: 0px!important;
      //margin-left: 20px!important;
      //margin-right: 0px!important;

      &.active {
          //margin-left: 0px!important;
          //margin-right: 0px!important;
      }

      + * {
          //margin-left: 0px!important;

          &.active {
          //margin-left: 0px!important;
        }
      }
    }

    .owl-stage {
        display:flex;
    }
    
    .owl-nav {
      &.disabled {
        display: block;
      }

      .owl-prev {
        position: absolute;
        height: 100%;
        color: rgba($color-white, 0.75);
        font-size: 50px;
        display: flex;
        align-items: center;
        cursor: pointer;
        opacity: 1;
        top: 0;
        left: 50px;
        background: none;
        border: none;
        outline: none;

        i {
          position: relative;
        }

        &.disabled {
          display: none;
        }
      }

      .owl-next {
        position: absolute;
        height: 100%;
        color: rgba($color-white, 0.75);
        font-size: 50px;
        display: flex;
        align-items: center;
        top: 0;
        right: 50px;
        cursor: pointer;
        opacity: 1;
        background: none;
        border: none;
        outline: none;

        i {
          position: relative;
        }

        &.disabled {
          display: none;
        }
      }

      @media all and (max-width: 1000px) {
        display: none !important;
      }
    }
    
    .owl-dots {
      
      display: none;
      
      @media all and (max-width: 1000px) {
          display: block;
        }

      @media all and (max-width: 1000px) {
        &.disabled {
          display: block;
        }

        text-align: center;
        position: absolute;
        bottom: 10px;
        width: 100%;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        backface-visibility: hidden;
      }
    }

    .owl-dot {
        border-radius: 50px;
        height: 10px;
        width: 10px;
        display: inline-block;
        background: rgba(127,127,127, 0.0);
        margin-left: 5px;
        margin-right: 5px;
        border: 2px solid rgba(255,211,0, 1);
        padding: 0;
    }

    .owl-dot.active {
        background: rgba(255,211,0, 1);
    }
  }
}

.featureBox {
  position: absolute;
  width: 270px;
  height: 350px;
  top: 30px;
  left: 30px;
  z-index: 2;
  box-shadow: 0px 0px 15px rgba(0,0,0,0.3);
}