.owning {
	padding: 0;
	display: none;
	width: 100%;

	&__head {
		background: url('/webdav/files/resources/img/system/huvudbild.jpg');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center bottom;
		display: inline-block;
		position: relative;
		width: 100%;
		height: 500px;
	}

	&__img-first {
		display: inline-block;
		width: 100%;
		height: 510px;
		background: url('/webdav/files/resources/img/system/img1.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		transform-origin: top left;
		transform: skewY(-1deg);
		-webkit-transform-origin: top left;
		-webkit-transform: skewY(-1deg);
		margin-top: 70px;
		margin-bottom: 50px;

		@media all and(max-width: 375px) {
			background-size: 300% auto;
			margin-top: 30px;
			margin-bottom: -30px;
		}
	}

	&__img-second {
		display: inline-block;
		width: 100%;
		height: 510px;
		background: url('/webdav/files/resources/img/system/img2.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: right top;
		transform-origin: top left;
		transform: skewY(1deg);
		-webkit-transform-origin: top left;
		-webkit-transform: skewY(1deg);
		margin-top: 70px;
		margin-bottom: 70px;

		@media all and(max-width: 375px) {
			background-size: 300% auto;
			margin-top: 30px;
			margin-bottom: -100px;
		}
	}

}
	.owning__columns {
		@media all and(max-width: 768px) {
			.sv-column-6 {
				width: 100%!important;
			}
			.sv-column-8 {
				width: 100%!important;
				left: 0!important;
			}
			img {
				max-width: 100%;
				overflow: hidden;
			}
		}
	}


