.pageLoader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  height: 100vh;
  padding-right: 250px;
  z-index: 4;
  display: none;

  @include breakpoint(desktop) {
    padding-right: 0;
  }

  .-loading & {
    display: table;
    background: rgba($color-black, 0.3);
    animation: pageLoaderOpacity 250ms $apple-ease;
    animation-fill-mode: forwards;

    @keyframes pageLoaderOpacity {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    svg {
      animation: fadeInSVG 1000ms $apple-ease;

      @keyframes fadeInSVG {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
    }
  }

  .pageLoader__container {
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .svg {
      display: inline-block;
      margin: 0 auto;

      @include pageLoader();
    }
  }
}
