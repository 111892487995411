.errorMessage {
  display: none;

  &.-activated {
    display: block;
  }
}

.errorMessage__container {
  margin-top: 50px;
  margin-bottom: 50px;

  .error {
    display: none;
  }

  .error-disconnected {
    display: none;
  }
}