.sv-text-portlet {
  & table {
    margin-bottom: 2em;

    & thead {
      border-bottom: solid 5px $color-yellow;
    }

    & a {
      font-family: inherit;
      font-size: inherit;
      text-decoration: none !important;
      font-family: $font-family !important;
    }

    & p {
      @extend .normal;
      margin-bottom: 0;
    }

    & th {
      padding: 15px;

      & p {
        font-family: $font-family !important;
        font-size: 15px !important;
        font-weight: $font-weight-medium !important;
        color: $color-black !important;
      }
    }

    & tbody {

      & tr {
        border-bottom: solid 1px $color-light-gray;

        &:nth-child(odd) {
          background-color: $color-ultra-light-gray-alt;
        }
      }

      & td {
        @extend .normal;
        margin-bottom: 0;
        padding: 10px 15px;

        & .normal {
          color: $color-ultra-dark-gray;
          font-size: 15px;
        }
      }

      .fileshare-archive {
          background-color:aqua;
          & td {
              padding: 20px 20px;
          }

      }
    }

    &.sv-table-type-highcontrast {
      margin-bottom: 2em;

      & thead {
        background-color: $color-yellow;
        border: 1px solid $color-yellow;
      }

      & a {
        font-family: inherit;
        font-size: inherit;
        text-decoration: none !important;
        font-family: $font-family !important;
      }

      & p {
        @extend .normal;
        margin-bottom: 0;
      }

      & th {
        padding: 15px;

        & p {
          font-family: $font-family !important;
          font-size: 15px !important;
          font-weight: $font-weight-medium !important;
          color: $color-black !important;
        }
      }

      & tbody {

        & tr {
          border-bottom: solid 1px $color-light-gray;

          &:nth-child(odd) {
            background: none;
          }
        }

        & td {
          @extend .normal;
          margin-bottom: 0;
          padding: 10px 15px;
          border: 1px solid $color-yellow;

          & .normal {
            color: $color-white;
            font-size: 15px;
          }
        }

        .fileshare-archive {
            background-color:aqua;
            & td {
                padding: 20px 20px;
            }

        }
      }
    }

  }
}

.sv-file-portlet {
  & table {
    margin-bottom: 2em;

    & thead {
      border-bottom: solid 5px $color-yellow;
    }

    & a {
      font-family: inherit;
      font-size: inherit;
      text-decoration: none !important;
      font-family: $font-family !important;
    }

    & p {
      @extend .normal;
      margin-bottom: 0;
    }

    & th {
      padding: 15px;

      & p {
        font-family: $font-family !important;
        font-size: 15px !important;
        font-weight: $font-weight-medium !important;
        color: $color-black !important;
      }
    }

    & tbody {

      & tr {
        border-bottom: solid 1px $color-light-gray;

        &:hover {
          background-color: $color-ultra-light-gray-alt;
        }

        &:nth-child(odd) {
          background-color: $color-ultra-light-gray-alt;

          &:hover {
            background-color: $color-ultra-light-gray;
          }
        }
      }

      & td {
        @extend .normal;
        margin-bottom: 0;
        padding: 10px 0px;

        & .normal {
          color: $color-ultra-dark-gray;
          font-size: 15px;
        }

        a:hover {
            font-weight: 500;
        }

        &.sv-file-portlet-fileicon {
          padding: 0 10px;

          img {
              margin: auto;
              width: 16px;
              max-width: inherit;
          }
        }
      }
    }
  }
}

