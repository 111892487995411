// Text helpers
.u-text {
  &.-left {
    text-align: left;
  }
  &.-center {
    text-align: center;
  }
  &.-right {
    text-align: right;
  }
  &.-italic {
    font-style: italic;
  }
  &.-no-wrap {
    white-space: nowrap;
  }
  &.-hide {
    text-indent: -9999px;
    white-space: nowrap;
    overflow: hidden;
  }
}

// Clearfix
.u-clearfix {
  @include clearfix;
}

// Aspect ratio helpers
.u-ratio {
  &__16-9 {
    position: relative;
    display: block;
    padding-bottom: 56.25%;
  }
}

// Float helpers
.u-float {
  &.-left {
    float: left;
  }
  &.-right {
    float: right;
  }
  &.-none {
    float: none;
  }
}
