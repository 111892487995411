.productRegisterPage {
  font-family: $font-family;
  font-weight: 400;
  font-size: 16px;

  & form > div {
    margin-top: 30px;
  }

  & table {
    width: 100%;
  }

  & p {
    line-height: 1.4em;
    margin-bottom: 1em;
  }

  & img {
    margin-bottom: 20px;
  }

  & #errors,
  & #message {
    margin-top: 30px;
    text-align: center;
    line-height: 1.4em;
  }

  & #loader {
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;

    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: 32px;
      height: 32px;
      border: solid 2px $color-yellow;
      border-left-color: $color-light-gray;
      border-radius: 50%;
      animation: spinner 1s linear infinite;
    }
  }

  & label, a {
    display: inline-block;
    vertical-align: middle;
    font-family: $font-family !important;
    font-weight: $font-weight-medium;
    font-weight: 400 !important;
    font-size: 14px;
    margin-bottom: 5px;
    color: $color-ultra-dark-gray;

    &.error {
      color: red;

      a {
          color: red;
      }
    }
  }

  & input[type="text"],
  & textarea {
    @include reset-input('text');
    display: block;
    width: 100%;
    background-color: $color-white;
    border: solid 1px $color-light-gray;
    border-radius: 2px;
    font-family: $font-family;
    font-size: 16px;
    padding: 12px 10px 10px;
    line-height: 1em;
    margin-bottom: 1em;
    color: $color-ultra-dark-gray;

    // color: $color-dark-gray;

    &:focus {
      border-color: $color-black;
    }
  }

  & input[type="checkbox"],
  & input[type="radio"] {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 5px;
  }

  & input[type="submit"] {
    & .is-loading {
      color: transparent;
    }
  }

  & .warranty-checkbox {
    & td {
      display: block;
      width: 100%;
    }
  }

  & .warranty-requirement {
    display: none;
    padding-top: 20px;

    & ol {
      list-style: decimal;
      list-style-position: inside;
      margin-bottom: 20px;

      & li + li {
        margin-top: 10px;
      }
    }
  }

  & #form-field-100 + label {
    width: calc(100% - 22px);
    margin-left: 4px;
  }

  & #form-field-100:checked ~ .warranty-requirement {
    display: block;
  }

  & .gray {
    display: block;
    background-color: $color-white;
    border: solid 1px $color-light-gray;
    border-radius: 2px;
    margin-bottom: 20px;

    & tbody {
      display: block;
    }

    & tr {
      display: block;
    }

    & td {
      padding: 20px 20px 15px;
    }
  }

  & table.three-cols {
    & td {
      display: inline-block;
      width: calc(33.333% - 20px);

      & + td {
        margin-left: 30px;
      }

      @include breakpoint(tablet) {
        width: 100%;

        & + td {
          margin-left: 0;
        }
      }
    }
  }

  & #your-information {
    // padding-bottom: 20px;

    & tr {
      display: block;
      font-size: 0;
    }

    & td {
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 15px);

      & + td {
        margin-left: 30px;
      }

      @include breakpoint(tablet) {
        width: 100%;

        & + td {
          margin-left: 0;
        }
      }
    }
  }

  .qualifications,
  .new_engcon_details,
  .existing_engcon_details,
  .type_of_work,
  .attachments,
  .location,
  .terms,
  .acceptance {

    &.displayTable {
      display: table;

      tbody {
        display: table-row-group;
      }

      tr {
        display: table-row;
      }

      td {
        display: table-cell;
      }
    }

    & p {
      color: #666;
      margin-bottom: 0;
    }

    .noPadding {
      padding: 0;
    }

    textarea {

    }

    input[type="text"],
    textarea {
      display: inline-block;
      width: auto;
      margin-left: 6px;
      padding: 5px;
      font-size: 14px;
    }

    textarea {
      width: 100%;
    }

  }
}
