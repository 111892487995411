.startpageHero {
  position: relative;
  height: 55vh;
  overflow: hidden;
  width: 100%;

  @include breakpoint(mobile) {
    height: auto;
    min-height: 300px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: $startpage-background-height + $header-height;
    width: 100%;
    background-position: center (-$header-height);
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;

    @include breakpoint(mobile) {
      background-position: center center;
    }
  }

  & > .videoContainer {
    & .videoTopHeadline {
      position: absolute;
      right: 0;
      top: 30px;
      left: 0;
      max-width: 1170px;
      width: 100%;

      @include breakpoint(mobile) {
        position: static;
        margin-top: 90px;
        margin-bottom: 20px;
      }
    }

    & > .sv-layout {
      position: absolute;
      right: 0;
      bottom: 30px;
      left: 0;
      max-width: 1170px;
      width: 100%;

      @include breakpoint(mobile) {
        position: static;
        margin-top: 90px;
        margin-bottom: 20px;
      }
    }
  }

  &.nobleArt {
    height: 55vh;
    min-height: 55vh;

    @include breakpoint(mobile) {
    //height: auto;
    min-height: 300px;
    }
  }

  & > .startpageHeroTop {
      & > .sv-layout {
        position: absolute;
        left: 0;
        top: 30px;
        right: 0;
        
        & > .heading, h1 {
            font-size: 4em!important;
            color:#fff!important;
            line-height:1em!important;
        }

        @include breakpoint(mobile) {
          //position: static;
          //margin-top: 14px;

          & > .heading, h1 {
            font-size: 2.5em!important;
          }
        }

        @include breakpoint(tablet) {
          //position: static;
          //margin-top: 14px;
        } 
     }
  }

    & > .startpageHeroBottom {
      & > .sv-layout {
        position: absolute;
        right: 0;
        bottom: 30px;
        left: 0;
        z-index: 2;
        
        & > .heading, h1 {
            font-size: 2em!important;
            color:#fff!important;
            //text-align:right;
        }

        & > .sv-font-heading-4, h4 {
            color:#fff!important;
            //text-align:right;
        }

        & .sv-text-portlet-content > .normal {
            color:#fff!important;
            //text-align:right;

            & + .normal {
                font-size:0.8em!important;
            }
        }

        @include breakpoint(mobile) {
          margin-top: 20px;
          margin-bottom: 0px;
          bottom: 14px;

          & > .heading, h1 {
            font-size: 1.5em!important;
          }
        }

        @include breakpoint(tablet) {
          margin-top: 20px;
          margin-bottom: 0px;
          bottom: 14px;
        }
     }
  }
  
  & > .sv-font-ingress {
      color: $color-white !important;
  }
}

.startpageFeatured {
  display: flex;
  flex-flow: column wrap;
  margin-top: 120px;
  position: relative;
  z-index: 1;
  padding: 60px 30px 30px;
  background-color: #fff;
  background-color: rgba(255,255,255,0.75);
  width: calc(50% - 15px);
  text-align: center;

  & + .startpageFeatured {
    margin-left: 30px;
  }

  @include breakpoint(tablet) {
    width: calc(50% - 7px);

    & + .startpageFeatured {
      margin-left: 14px;
    }
  }

  @include breakpoint(mobile) {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 15px;
  }

  @include breakpoint(tiny) {
    width: 100%;

    & + .startpageFeatured {
      margin-left: 0;
    }
  }

  .ieLessThan11 &,
  .no-flexbox & {
    display: inline-block;
    vertical-align: bottom;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-65%);
  }

  &__body {
    flex-grow: 1;
  }

  & p.sv-font-ingress {
    @include breakpoint(tablet) {
      font-size: 14px !important;
    }
  }

  & .btn {
    @include breakpoint(mobile) {
      font-size: 12px;
      padding: 18px 16px 16px;

      & i + span,
      & span + i {
        margin-left: 6px;
      }
    }
  }
}

.startpage__intro {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 650px;
  margin: 0 auto 0 auto;

  .content {
    position: relative;
    z-index: 2;
    padding: 15px;
    text-align: center;

    p {
      margin-top: 25px !important;
      line-height: 150%;
    }
  }

  @media all and (max-height: 950px) {
    &:after {
      display: none;
    }
  }
}

.startpage__content {
  .features {
    margin-bottom: 60px;

    .region-selector {
      float: right;
      font-size: 0;
      margin-right: 5px;
      position: relative;
      z-index: 5;

      .description {
        display: inline-block;
        padding-right: 15px;
        font-size: 16px;
        vertical-align: middle;
      }

      .foldout {
        .-empty {
          display: none;
        }
      }

      @media all and (max-width: 960px) {
        width: 100%;
        float: right;
        text-align: right;
        margin-bottom: 10px;

        .description {
          display: block;
          margin-bottom: 20px;
        }
      }
    }

    .features__container--empty {
      display: none;

      &.-activated {
        display: block;
        padding-top: 15px;
        font-family: $font-family;
        font-weight: $font-weight-medium;
      }
    }

    .features__container {
      @include clearfix;
      font-size: 0;
      padding-top: 60px;
      text-align: left;
      margin: 0 -15px;

      @include breakpoint(tablet) {
        margin: 0 -10px;
      }

      @include breakpoint(mobile) {
        margin: 0;
      }

      li {
        &.item {
          display: inline-block;
          vertical-align: top;
          margin-top: 15px;
          margin-bottom: 15px;
          padding: 0 15px;
          text-align: left;
          transition: transform 200ms $apple-ease;

          &:hover {
            transform: translateY(-5px);
          }

          @include breakpoint(tablet) {
            margin-top: 14px;
            margin-bottom: 0;
            padding: 0 10px;
          }

          @include breakpoint(mobile) {
            padding: 0 5px;
          }

          & a {
            position: relative;
            display: block;
            transition: box-shadow .2s $apple-ease;

            &:hover {
              box-shadow: 0 10px 50px rgba(0, 0, 0, 0.2);
            }
          }

          &.width-25 {
            width: 25%;

            @include breakpoint(tablet) {
              width: 50%;
            }
          }

          &.width-50 {
            width: 50%;

            @include breakpoint(tablet) {
              width: 50%;
            }
          }

          &.width-100 {
            width: 100%;
          }

          .feature {
            width: 100%;
            display: block;
          }

        }

      }

    }
  }
}

.smoke {
  background-color: #000;
  background-color: rgba(0,0,0,0.35);

  h2, p {
    color: #fff;
  }
}

.video-background {
  background: #000;
  position: fixed;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -99;
}

.video-container {
  position:absolute;
  top: 0;
  left: 0;
  height: $startpage-background-height + $header-height;
  width: 100%;
  overflow:hidden;
  pointer-events: none;

  @include breakpoint(desktop) {
    margin-top:$header-height;
    height:$startpage-background-height + $header-height + 23px;
  }

  & .color-overlay {
    position:absolute;
    top:0;
    left:0;
    background-color:$color-yellow;
    width:100%;
    height:100%;
    opacity:0.5;
    z-index:2;
  }

  & video {
    position:absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-height: 100%;
    min-width: 100%;
    z-index:1;
  }

  & iframe {
    height: 100%;
    width: 100%;
  }
}

.videoContainer {
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & iframe {
  /* optional */
    width: 100%;
    height: 100%; 
  }
}
@media (min-aspect-ratio: 16/9) {
.video-container { height: 300%; top: -100%; }
}
@media (max-aspect-ratio: 16/9) {
.video-container { width: 300%; left: -100%; }
}

.modalDialog.-configuratorOptions {
  .modalDialog__container {
    max-width: 500px;
  }

  .options {
    width: 70%;
    display: inline-block;
    text-align: left;
    padding-left: 20px;
    margin-top:20px;
    padding-bottom: 15px;

    h3 {
      line-height: 120%;
    }
  }

  .logotype {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    padding-right: 20px;
  }

  .divider {
    width: 100%;
    padding: 0 20px 0 20px;

    .border {
      border-top: 2px dotted $color-dark-gray;
    }
  }

  .buttons {
    padding-top: 15px;

    a {
      padding: 15px 25px 15px 25px;
      border: 1px solid $color-light-gray;
      display: inline-block;
      border-radius: 30px;
      text-decoration: none;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      color: $color-ultra-dark-gray;
      font-size: 14px;
    }

    a:first-child {
      margin-right: 10px;

      &:before {
        content: '\f00d';
        color: $color-yellow;
        font-family: "FontAwesome";
        margin-right: 10px;
      }
    }

    a:last-child {
      margin-left: 10px;

      &:before {
        content: '\f054';
        color: $color-yellow;
        font-family: "FontAwesome";
        margin-right: 10px;
      }
    }

    @media all and (max-width: 700px) {
      a {
        display: block;
        width: 70%;
      }

      a:first-child {
        margin: 0 auto;
      }

      a:last-child {
        margin: 0 auto;
        margin-top: 15px;
      }
    }
  }
}

.modalDialog.-configurator {
  .modalDialog__container {
    max-width: 800px;
  }

  .step1 {
    padding: 0 50px 40px 50px;
    min-height: 300px;

    .search {
      text-align: left;
      padding: 20px 5px 0 5px;

      .input {
        margin-top: 15px;
        display: table;
        width: 100%;

        input {
          display: table-cell;
          width: 100%;
          padding: 20px;
          vertical-align: top;
          border: 1px solid #DDD;
          border-right: 0;
          border-radius: 0;

          box-shadow: none;

          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          font-family: $font-family;
          font-size: 16px;

          outline: 0;
        }

        a {
          display: table-cell;
          background: $color-yellow;
          color: $color-white;
          width: 10%;
          text-align: center;
          height: 100%;
          vertical-align: top;
          line-height: 61px;

          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;

          @include breakpoint(tablet) {
            width: 20%;
          }
        }
      }

      .alphabet {
        margin-top: 15px;

        li {
          display: inline-block;
          margin-right: 5px;
          opacity: 0.5;
          cursor: pointer;
          font-size: 15px;

          &.-active {
            opacity: 1;
          }

          @include breakpoint(tablet) {
            font-size: 20px;
          }
        }
      }
    }

    ul {
      list-style-type: none;
      text-align: left;
    }

    .excavatorBrand {
      display: inline-block;
      width: calc(20% - 10px);
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 20px;
      padding: 10px 0 10px 0;
      border: 1px solid $color-light-gray;
      text-align: center;

      transition: border-color 250ms $apple-ease;

      img {
        width: 80%;
        margin: 0 auto;
      }

      .divider {
        border-top: 2px dotted $color-light-gray;
        width: 80%;
        margin: 0 auto;
        padding-bottom: 10px;
      }

      p {
        margin-top: 0 !important;
        font-family: $font-family;
        font-size: 15px !important;
      }

      @include breakpoint(tablet) {
        width: calc(33.333% - 10px);
      }

      @include breakpoint(mobile) {
        width: calc(50% - 10px);
      }

      &:hover, &:focus {
        border-color: $color-dark-gray;
      }
    }
  }

  .step2 {
    padding: 0 50px 40px 50px;
    min-height: 300px;

    &__header {
      text-align: left;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-light-gray;

      ul {
        li {
          display: inline-block;
          text-align: center;
          vertical-align: middle;

          img {
            display: block;
          }

          &.-image {
            height: 150px;
            width: 150px;
            background-size: cover;
            margin-left: 15px;
            float: right;

            @include breakpoint(tablet) {
              display: none;
            }
          }

          a {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            font-family: $font-family;
            font-weight: $font-weight-medium;
            padding: 8px 20px 8px 25px;
            border-radius: 30px;
            font-size: 15px;
            border: 1px solid $color-light-gray;
            color: $color-light-black;

            &:before {
              content: '\f053';
              font-family: "FontAwesome";
              font-size: 13px;
              position: relative;
              left: -8px;
              color: $color-yellow;
            }
          }
        }
      }
    }

    &__models {
      text-align: left;
      margin-top: 20px;

      ul {
        display: flex;
        flex-wrap: wrap;

        .ieLessThan11 &, .no-flexbox & {
          display: block;
        }

        li {
          display: flex;
          flex-flow: column wrap;
          width: 16.6%;
          padding-right: 10px;
          margin-top: 10px;
          vertical-align: middle;
          .ieLessThan11 &, .no-flexbox & {
            display: inline-block;
          }

          &:nth-child(6n + 6) {
            padding-right: 0;
          }

          a {
            border: 1px solid $color-light-gray;
            padding: 15px 10px 15px 10px;
            text-align: center;
            text-decoration: none;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            transition: border-color 250ms $apple-ease;

            span {
              width: 80%;
              margin: 0 auto;
              display: inline-block;
              word-wrap: break-word;
              padding-top: 5px;
              padding-bottom: 5px;
            }

            &:hover, &:focus {
              border-color: $color-dark-gray;
            }
          }

          @include breakpoint(tablet) {
            width: 33.333%;

            &:nth-child(6n + 6) {
              padding-right: 10px;
            }
          }

          @include breakpoint(mobile) {
            width: 50%;
          }
        }
      }
    }
  }

  .progress {
    position: fixed;
    bottom: 0;
    background: $color-white;
    border-top: 1px dotted $color-light-gray;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: right;
    left: 0;

    ul {
      padding: 0 50px 0 50px;

      li {
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
        opacity: 0.5;
        font-size: 14px;


        &.-active {
          opacity: 1;
        }

        &:last-child {
          border-left: 1px dotted $color-light-gray;
        }
      }
    }
  }
}
