.tag {
  display: inline-block;
  vertical-align: middle;
  padding: 6px 12px;
  border: solid 1px $color-light-gray;
  border-radius: 30px;
  font-size: 12px;
  font-family: $font-family;
  color: $color-ultra-dark-gray;
  line-height: 1em;
  background-color: $color-white;
  text-decoration: none;

  &.js-tag-search:hover {
    cursor: pointer;
  }

  & .fa {
    margin-left: 4px;
  }

  @include breakpoint(mobile) {
    font-size: 8px;
    padding: 5px 8px 4px;
  }
}
