.segmentsPage {

  &__nav {
    margin-top: 1.5em;

    & > a {
      position: relative;
      display: inline-block;
      vertical-align: top;
      margin-right: 20px;
      padding: 20px;
      background-color: #000;
      color: $color-white;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      text-decoration: none;
      text-align: center;
      min-height: 100px;
      min-width: 120px;

      @include breakpoint(tablet) {
        display: none;
      }

      &.-active::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        width: 0;
        margin: 0 auto;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-top: 20px solid #000;
      }
    }

    & img {
      height: 60px !important;
      max-height: none;
      width: auto !important;
      max-width: none !important;
    }

    & span {
      display: block;
    }

    &.-yellow {

      & > a {
        background-color: $color-yellow;
        color: $color-black;

        &.-active::after {
          border-top: 20px solid $color-yellow;
        }
      }
    }
  }

  &__select {
    width: 100%;
    display: none;

    @include breakpoint(tablet) {
      display: block;
    }

    & ul {
      width: calc(100% - 44px);
    }
  }

  &__segment {
    display: none;

    &.-active {
      display: block;
    }

    & .sv-row + .sv-row {
      margin-top: 50px;
    }
  }

  & .sv-empty-layout.sv-column-8 {
    display: inline !important;
  }

  .filter {
      font-size: 1em;
      width: 100%;
      background: $color-white;
      display: inline-block;
      vertical-align: top;
      //padding: 0 10px;
      transition: padding 500ms $apple-ease;

      p {
        margin-top: 0;
      }

      &.-deactivated {
        display: none;
      }

      p {
        margin-bottom: 0;
        color: $color-black;
      }

      .slider__header {
        .min {
          float: left;
          //font-size: 13px !important;
          //color: $color-dark-gray;
          margin-top: 8px !important;
          max-height: 900px;
          overflow: hidden;

          transition: max-height 500ms $apple-ease;

          &.-hide {
            max-height: 0;
          }
        }

        .max {
          float: right;
          //font-size: 13px !important;
          //color: $color-dark-gray;
          margin-top: 8px !important;
          display: none !important;
        }

        &:after {
          display: table;
          clear: both;
          content: '';
        }
      }

      &__slider {
        margin: 20px auto 20px auto;
        z-index: 3;
      }

      .dropdown {
        width: 100%;
        margin-top: 13px;
        z-index: 1;

        &.dropdown--filter {
          z-index: 2;
        }
      }
    }
  /*
  input[type="number"] {
    width: 100%;
    padding: 15px;
    border-radius: 2px;
    border: solid 1px #dddddd;
    line-height: 18px;
    font-size: 15px;

    &::-webkit-input-placeholder {
       color: #666666;
       font-size: 15px;
       line-height: 18px;
    }

    &:-moz-placeholder {
       color: #666666;
       font-size: 15px;
       line-height: 18px;
    }

    &::-moz-placeholder {
       color: #666666;
       font-size: 15px;
       line-height: 18px;
    }

    &:-ms-input-placeholder {
       color: #666666;
       font-size: 15px;
       line-height: 18px;
    }

    &:focus {
      outline: none;
    }

    &.error {
      border-color: red;
    }
  }
      */
    .quantity {
      position: relative;
      height: 45px;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button
    {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type=number]
    {
      -moz-appearance: textfield;
    }

    .quantity input {
      //width: 45px;
      height: auto;
      font-size: 16px;
      //line-height: 1.65;
      float: left;
      display: block;
      padding: 0;
      margin: 0;
      //padding-left: 20px;
      padding: 13px 64px 13px 17px;
      border: 1px solid #ddd;
      border-right: 0;
      border-radius: 2px;
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    .quantity input:focus {
      outline: 0;
    }

    .quantity-nav {
      float: left;
      position: relative;
      height: 46px;
    }

    .quantity-button {
      position: relative;
      cursor: pointer;
      //border-left: 1px solid #eee;
      width: 44px;
      text-align: center;
      color: #fff;
      font-size: 13px;
      font-weight: 900;
      line-height: 1.7;
      -webkit-transform: translateX(-100%);
      transform: translateX(-100%);
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      -o-user-select: none;
      user-select: none;
    }

    .quantity-button.quantity-up {
      position: absolute;
      height: 50%;
      top: 0;
      //border-bottom: 1px solid #eee;
      background-color: $color-yellow;
    }

    .quantity-button.quantity-down {
      position: absolute;
      bottom: -1px;
      height: 50%;
      background-color: $color-yellow;
    }

  &__section {
      margin-bottom: 1em;
      clear: both;
      display: none;

      &.show {
          display: block;
      }

      h4 {
          margin-bottom: .5em;
          display: block;
      }
  }
}

.modalDialog.-segmentsPage {
  padding: 100px 0;
  font-size: 0;

  &::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }

  & .modalDialog__container {
    display: inline-block !important;
    vertical-align: middle !important;
    width: 100%;
    top: 0;
    margin-bottom: 0 !important;
    height: auto !important;

    @include breakpoint(tablet) {
      max-width: calc(100% - 30px) !important;
    }
  }

  & .close-icon {
    position: absolute !important;
    top: -30px;
    right: 0 !important;
  }

  & .modalDialog__content {
    position: relative;
    display: block;
    height: 0;
    padding-bottom: 56.25%;

    & iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
    }
  }
}

div.tabContainer > div { display: none; }
div.tabContainer > div.current { display: block; }  