.contact {
	display: block;
	position: relative;
	padding-top: 40px;
	padding-bottom: 40px;

	&__wrap {
		padding-bottom: 0;
	}

	&__title {
		font-family: $font-family;
    font-weight: $font-weight-super;
		font-size: 40px;
		line-height: 50px;
		color: $color-black;
	}
	&__body {
		font-family: $font-family;
		color: $color-black;
		font-size: 16px;
		line-height: 22px;
	}

	&__filter {
		display: block;
        background: url('/webdav/files/resources/img/patterns/dark-grey.png');
        background-repeat: repeat;
		padding: 25px 50px;
		margin-top: 25px;

		.filterTitle {
			font-family: $font-family;
      font-weight: $font-weight-super;
			color: $color-black;
			padding: 0 0 20px 0;
		}

		.dropdown {
          z-index: 1;

          .arrow {
            height: 62%;
          }

          &.-contactdrop:nth-child(1) {
              width: 39% !important;
              margin: 5px 8px 5px 0px;
          }

          &.-contactdrop:nth-child(3) {
              width: 28%;
              margin: 5px 5px 6px 10px;
          }
        }

    .contactlabel {
      display: inline-block;
      color: $color-white;
      font-family: $font-family;
      font-weight: 500;
      padding-bottom: 4px;
    }

      .filterSearch {
        display: inline-block;
        width: 28%;
        position: relative;
        margin: 6px 0px 5px 5px;
        top: 4px;

        .ieLessThan11 & {
          vertical-align: top;
          top: -2px;
        }

        input {
          width: 89%;
          outline: 0;
          border: 1px solid $color-light-gray;
          padding: 12px 20px 12px 17px;
          font-family: $font-family;
          font-size: 16px;
          border-radius: 3px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
          display: table-cell;
        }

        a {
          position: absolute;
          right: 0;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          background: $color-yellow;

          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;

          text-decoration: none;

          width: 14%;
          height: 62%;

          padding-left: 25px;
          padding-right: 25px;

          font-family: $font-family;
          font-weight: $font-weight-medium;

          color: $color-white;

          .ieLessThan11 & {
            top: 28px;
            right: 30px;
          }

          .fa {
          	position: relative;
          	top: 15px;
          	right: 8px;
          }
        }
      }

	}

      @media all and(min-width: 900px) and (max-width: 1267px) {
        .contactlabel {
          width: 100%;
        }
        .dropdown {
          &.-contactdrop:nth-child(1) {
            width: 39% !important;
            margin: 6px 3px 5px 5px !important;

          }
          &.-contactdrop:nth-child(3) {
            width: 28% !important;
            margin: 6px 0px 5px 5px !important;
          }
        }
        .filterSearch {
          width: 28%;
        }
      }

      @media all and(min-width: 841px) and (max-width: 900px) {
        .contactlabel {
          width: 100%;
        }
        .dropdown {
          &.-contactdrop:nth-child(1) {
            width: 100% !important;
            margin: 6px 3px 5px 5px !important;

          }
          &.-contactdrop:nth-child(3) {
            width: 100% !important;
            margin: 6px 0px 5px 5px !important;
          }
        }
        .filterSearch {
          width: 100%;
          input {
            width: 95%;
          }
          a {
            max-width: 44px;
          }
        }
      }

      @media all and (max-width: 840px) {
        .contact__filter {
          padding: 25px !important;
        }

        .filterSearch {
          width: 100%;
          input {
            width: 93%;
          }
          a {
            max-width: 44px;
          }
        }
        .contactlabel {
          width: 100%;
        }
        .dropdown {
          &.-contactdrop:nth-child(1) {
            width: 100% !important;
            margin: 6px 3px 5px 5px !important;
          }
          &.-contactdrop:nth-child(3) {
            width: 100% !important;
            margin: 6px 0px 5px 5px !important;
          }
        }
      }

  @media all and (max-width: 1450px) {
    .contact__title {
      padding-left: 20px;
      padding-right: 20px;
    }
    .contact__body {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}
