.input {

  &__text {
    @include reset-input('text');
    font-family: $font-family;
    font-weight: $font-weight-normal;
    background-color: $color-white;
    font-size: 16px;
    line-height: 1em;
    border: solid 1px $color-light-gray;
    border-radius: 2px;
    color: $color-ultra-dark-gray;
    padding: 13px 15px 10px;
    height: 46px;
    transition: border .2s $apple-ease;

    &:focus {
      border-color: $color-gray;
    }

    &::-webkit-input-placeholder {
      line-height: 1em;
      font-family: $font-family;
      color: $color-ultra-dark-gray;
      padding-top: 3px;
    }

    &[type="search"] {
      width: calc(100% - 80px);
      float: left;
      border-radius: 2px 0 0 2px;
      border-right: transparent;

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }

      & + [type="submit"] {
        @include reset-input('button');
        float: left;
        width: 80px;
        height: 46px;
        border-radius: 0 2px 2px 0;
        border-bottom: solid 1px #e6bf00;
        font-size: 16px;
        text-align: center;
        background-color: $color-yellow;
        color: $color-white;
      }
    }
  }

}
