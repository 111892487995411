.ourProducts {
  &__hero {
    background: url('/webdav/files/resources/img/ourProducts/hero.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: 0;

    h1 {
      color: $color-white;
    }

    p.normal,
    p.sv-font-ingress {
      color: $color-white;
    }

    p.sv-font-ingress {
      margin-bottom: .5em;
    }

    & .sv-text-portlet:last-child {
      margin-bottom: 30px;
    }

    .filter {
      font-size: 1em;
      width: calc(50% - 15px);
      background: $color-white;
      display: inline-block;
      vertical-align: top;
      padding: 30px;
      transition: padding 500ms $apple-ease;
      border: 1px solid $color-light-gray;

      p {
        margin-top: 0;
      }

      &.-deactivated {
        display: none;
      }

      &--excavator {
        display: none;

        &.-activated {
          display: inline-block;
        }

        .options {
          display: inline-block;
          width: 70%;
        }

        .logotype {
          display: inline-block;
          width: 30%;
          vertical-align: top;
        }

        h3 {
          line-height: 150%;
        }

        a {
          padding: 15px 25px;
          border: 1px solid #DDD;
          display: inline-block;
          border-radius: 30px;
          text-decoration: none;
          color: #666;
          font-size: 14px;
          font-family: $font-family;
          font-weight: $font-weight-medium;
          margin-top: 15px;

          &:before {
            content: '\f00d';
            font-family: FontAwesome;
            color: $color-yellow;
            margin-right: 10px;
          }
        }
      }

      p {
        margin-bottom: 0;
        color: $color-black;
      }

      .slider__header {
        .min {
          float: left;
          font-size: 13px !important;
          color: $color-dark-gray;
          margin-top: 8px !important;
          max-height: 900px;
          overflow: hidden;

          transition: max-height 500ms $apple-ease;

          &.-hide {
            max-height: 0;
          }
        }

        .max {
          float: right;
          font-size: 13px !important;
          color: $color-dark-gray;
          margin-top: 8px !important;
          display: none !important;
        }

        &:after {
          display: table;
          clear: both;
          content: '';
        }
      }

      &__slider {
        margin: 20px auto 20px auto;
        z-index: 3;
      }

      .dropdown {
        width: 100%;
        margin-top: 13px;
        z-index: 1;

        &.dropdown--filter {
          z-index: 2;
        }
      }

      .filterSearch {
        display: table;
        width: 100%;
        margin-top: 20px;

        input {
          width: 100%;
          outline: 0;
          border: 1px solid $color-light-gray;
          padding: 14px 20px 14px 17px;
          font-family: $font-family;
          font-size: 16px;
          border-radius: 2px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
          display: table-cell;
        }

        a {
          display: table-cell;
          text-align: center;
          background: $color-yellow;

          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;

          text-decoration: none;

          width: 20%;

          padding-left: 25px;
          padding-right: 25px;

          font-family: $font-family;
          font-weight: $font-weight-medium;

          color: $color-white;

          position: relative;

          .ieLessThan11 & {
            i {
              position: absolute;
              top: 15px;
              transform: translateX(15px);
            }
          }
        }

      }

      &.-expand {
        padding-bottom: 260px;
      }
    }

    .product-guide {
      width: calc(50% - 15px);
      float: right;
      display: inline-block;
      background-color: $color-white;
      border: 1px solid $color-light-gray;
      padding: 30px;
      vertical-align: top;

      p {
        margin-top: 5px;
        margin-bottom: 1em;
        color: $color-black;
      }

      .button {
        margin-top: 25px;

        a:after {
          content: '\f054';
          font-family: FontAwesome;
          color: $color-white;
          position: relative;
          left: 10px;
          top: 1px;
        }
      }
    }

    @media all and (max-width: 900px) {
      .filter {
        width: 100%;
        margin-bottom: 25px;
      }

      .product-guide {
        width: 100%;
      }
    }

    &:after {
      content: '';
      display: table;
      clear: both;
    }
  }
}

.productsContainer {
  background: $color-ultra-light-gray;
  padding-bottom: 25px;

  .categoriesBar {
    background: $color-white;
    padding-bottom: 25px;
    border-top: 1px solid $color-light-gray;
    border-bottom: 1px solid $color-light-gray;

    .filterNotice {
      padding-top: 25px;
      display: none;

      &.-deactivated {
        display: none;
      }

      &.-activated {
        display: block;
      }
    }

    ul {
      li {
        display: inline-block;
        padding-right: 29px;
        margin-top: 25px;
        position: relative;

        a {
          text-decoration: none;
          font-family: $font-family;
          font-weight: $font-weight-medium;

          span {
            opacity: .5;
            transition: opacity 250ms $apple-ease;

            &:hover {
              opacity: 1;
            }
          }

          &:after {
            content: '·';
            margin-left: 12.5px;
            display: inline-block;
            font-size: 40px;
            font-family: "Helvetic Neue";
            position: absolute;
            top: -14px;
            color: $color-yellow;
          }
        }

        &:last-child {
          a {
            &:after {
              content: '';
            }
          }
        }
      }
    }
  }

  .campaigns {
    a {
      text-decoration: none;
      position: relative;

      .tag {
        background: url('/webdav/files/resources/img/ourProducts/campaign_tag.png');
        display: inline-block;
        padding: 25px 25px 25px 25px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        font-family: $font-family;
        font-weight: $font-weight-medium;
        position: absolute;
        top: -20px;
        left: -14px;
        border: none;
        z-index: 5;
      }
    }

    .campaign {
      padding: 30px;
      background: url('/webdav/files/resources/img/patterns/black.jpg');
      background-size: cover;
      margin-top: 25px;
      position: relative;
      text-align: center;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
      margin-bottom: 25px;

      h3 {
        color: $color-white;
      }

      p {
        color: $color-white;
      }

      @media all and (max-width: 900px) {
        padding-top: 65px;
      }
    }
  }

  .category {

    .category__link {
      text-decoration: none;
    }

    &:first-child {
      margin-top: 25px;
    }
  }
}

.modalDialog.-favoritesShare {
  .share__container {
    p {
      padding-top: 15px;
      padding-bottom: 8px;
      display: inline-block;
    }

    input {
      width: 60%;
      margin: 0 auto;
      margin-top: 15px;
      display: block;
      outline: 0;
      border: 1px solid #DDD;
      padding: 14px 20px 14px 25px;
      font-family: $font-family;
      font-size: 16px;
      border-radius: 3px;
    }

    .divider {
      border-top: 1px dotted $color-dark-gray;
      width: 80%;
      margin: 25px auto 15px auto;
    }

    .sendButton {
      display: inline-block;

      margin-left: 10px;
      min-width: 20%;
      background: $color-yellow;
      padding: 15px 20px 15px 20px;
      font-size: 14px;
      border-radius: 2.5px;
      text-decoration: none;
      font-family: $font-family;
      font-weight: $font-weight-medium;

      transition: transform 250ms $apple-ease;

      &::before {
        content: '\f1d8';
        font-family: "FontAwesome";
        padding-right: 10px;
        color: $color-white;

        .ieLessThan11 & {
          display: none;
        }
      }
    }
  }
}

.modalDialog.-configuratorOptions {
  .modalDialog__container {
    max-width: 500px;
  }

  .options {
    width: 70%;
    display: inline-block;
    text-align: left;
    padding-left: 20px;
    margin-top:20px;
    padding-bottom: 15px;

    h3 {
      line-height: 120%;
    }
  }

  .logotype {
    width: 30%;
    display: inline-block;
    vertical-align: top;
    padding-right: 20px;
  }

  .divider {
    width: 100%;
    padding: 0 20px 0 20px;

    .border {
      border-top: 2px dotted $color-dark-gray;
    }
  }

  .buttons {
    padding-top: 15px;

    a {
      padding: 15px 25px 15px 25px;
      border: 1px solid $color-light-gray;
      display: inline-block;
      border-radius: 30px;
      text-decoration: none;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      color: $color-ultra-dark-gray;
      font-size: 14px;
    }

    a:first-child {
      margin-right: 10px;

      &:before {
        content: '\f00d';
        color: $color-yellow;
        font-family: "FontAwesome";
        margin-right: 10px;
      }
    }

    a:last-child {
      margin-left: 10px;

      &:before {
        content: '\f054';
        color: $color-yellow;
        font-family: "FontAwesome";
        margin-right: 10px;
      }
    }

    @media all and (max-width: 700px) {
      a {
        display: block;
        width: 70%;
      }

      a:first-child {
        margin: 0 auto;
      }

      a:last-child {
        margin: 0 auto;
        margin-top: 15px;
      }
    }
  }
}

.modalDialog.-configurator {
  .modalDialog__container {
    max-width: 800px;
  }

  .step1 {
    padding: 0 50px 40px 50px;
    min-height: 300px;

    .search {
      text-align: left;
      padding: 20px 5px 0 5px;

      .input {
        margin-top: 15px;
        display: table;
        width: 100%;

        input {
          display: table-cell;
          width: 100%;
          padding: 20px;
          vertical-align: top;
          border: 1px solid #DDD;
          border-right: 0;
          border-radius: 0;

          box-shadow: none;

          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;

          font-family: $font-family;
          font-size: 16px;

          outline: 0;
        }

        a {
          display: table-cell;
          background: $color-yellow;
          color: $color-white;
          width: 10%;
          text-align: center;
          height: 100%;
          vertical-align: top;
          line-height: 61px;

          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;

          @include breakpoint(tablet) {
            width: 20%;
          }
        }
      }

      .alphabet {
        margin-top: 15px;

        li {
          display: inline-block;
          margin-right: 5px;
          opacity: 0.5;
          cursor: pointer;
          font-size: 15px;

          &.-active {
            opacity: 1;
          }

          @include breakpoint(tablet) {
            font-size: 20px;
          }
        }
      }
    }

    ul {
      list-style-type: none;
      text-align: left;
    }

    .excavatorBrand {
      display: inline-block;
      width: calc(20% - 10px);
      margin-right: 5px;
      margin-left: 5px;
      margin-top: 20px;
      padding: 10px 0 10px 0;
      border: 1px solid $color-light-gray;
      text-align: center;

      transition: border-color 250ms $apple-ease;

      img {
        width: 80%;
        margin: 0 auto;
      }

      .divider {
        border-top: 2px dotted $color-light-gray;
        width: 80%;
        margin: 0 auto;
        padding-bottom: 10px;
      }

      p {
        margin-top: 0 !important;
        font-family: $font-family;
        font-size: 15px !important;
      }

      @include breakpoint(tablet) {
        width: calc(33.333% - 10px);
      }

      @include breakpoint(mobile) {
        width: calc(50% - 10px);
      }

      &:hover, &:focus {
        border-color: $color-dark-gray;
      }
    }
  }

  .step2 {
    padding: 0 50px 40px 50px;
    min-height: 300px;

    &__header {
      text-align: left;
      margin-top: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid $color-light-gray;

      ul {
        li {
          display: inline-block;
          text-align: center;
          vertical-align: middle;

          img {
            display: block;
          }

          &.-image {
            height: 150px;
            width: 150px;
            background-size: cover;
            margin-left: 15px;
            float: right;

            @include breakpoint(tablet) {
              display: none;
            }
          }

          a {
            display: inline-block;
            text-align: center;
            text-decoration: none;
            font-family: $font-family;
            font-weight: $font-weight-medium;
            padding: 8px 20px 8px 25px;
            border-radius: 30px;
            font-size: 15px;
            border: 1px solid $color-light-gray;
            color: $color-light-black;

            &:before {
              content: '\f053';
              font-family: "FontAwesome";
              font-size: 13px;
              position: relative;
              left: -8px;
              color: $color-yellow;
            }
          }
        }
      }
    }

    &__models {
      text-align: left;
      margin-top: 20px;

      ul {
        display: flex;
        flex-wrap: wrap;

        .ieLessThan11 &, .no-flexbox & {
          display: block;
        }

        li {
          display: flex;
          flex-flow: column wrap;
          width: 16.6%;
          padding-right: 10px;
          margin-top: 10px;
          vertical-align: middle;
          .ieLessThan11 &, .no-flexbox & {
            display: inline-block;
          }

          &:nth-child(6n + 6) {
            padding-right: 0;
          }

          a {
            border: 1px solid $color-light-gray;
            padding: 15px 10px 15px 10px;
            text-align: center;
            text-decoration: none;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            transition: border-color 250ms $apple-ease;

            span {
              width: 80%;
              margin: 0 auto;
              display: inline-block;
              word-wrap: break-word;
              padding-top: 5px;
              padding-bottom: 5px;
            }

            &:hover, &:focus {
              border-color: $color-dark-gray;
            }
          }

          @include breakpoint(tablet) {
            width: 33.333%;

            &:nth-child(6n + 6) {
              padding-right: 10px;
            }
          }

          @include breakpoint(mobile) {
            width: 50%;
          }
        }
      }
    }
  }

  .progress {
    position: fixed;
    bottom: 0;
    background: $color-white;
    border-top: 1px dotted $color-light-gray;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: right;
    left: 0;

    ul {
      padding: 0 50px 0 50px;

      li {
        display: inline-block;
        padding-right: 5px;
        padding-left: 5px;
        opacity: 0.5;
        font-size: 14px;


        &.-active {
          opacity: 1;
        }

        &:last-child {
          border-left: 1px dotted $color-light-gray;
        }
      }
    }
  }
}
