.navigationBar {
  border-top: solid 1px $color-light-gray;
  border-bottom: solid 1px $color-light-gray;
  background-color: #fff;

  &__list {
    font-size: 0;
  }

  &__item {
    display: inline-block;
    vertical-align: middle;
    padding: 24px 15px 23px 0;

    & + li {
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 15px;
        background-color: $color-yellow;
      }
    }
  }

  &__link {
    display: inline-block;
    vertical-align: middle;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 17px;
    line-height: 1em;
    color: $color-ultra-dark-gray;
    text-decoration: none;
    transition: color .2s $apple-ease;

    &:hover,
    &:focus {
      color: $color-black;
    }
  }
}
