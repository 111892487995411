.dropdown {
  display: inline-block;
  cursor: pointer;
  position: relative;
  user-select: none;
  font-size: 0;
  text-align: left;

  transform-style: preserve-3d;
  transition: transform 200ms $swift-out;

  &.-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.-inactive {
      display: none;
  }

  &.-fullWidth .dropdown__container {
    width: 100%;
    width: calc(100% - 44px);
  }

  .dropdown__container {
    display: inline-block;
    vertical-align: middle;
    margin-right: 44px;

    li {
      background: $color-white;
      font-size: 16px;

      font-family: $font-family;

      opacity: 0;
      height: 0;

      overflow: hidden;

      visibility: hidden;

      border: 0;

      padding: 0 21px 0 26px;

      transition: border-radius 200ms $swift-out;

      &.-activated {
        opacity: 1;
        height: auto;

        padding: 14px 20px 14px 17px;
        vertical-align: bottom;

        visibility: visible;

        border: 1px solid $color-light-gray;
        border-right: 0;

        border-radius: 2px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .arrow {
    background: $color-yellow;
    color: $color-white;
    padding: 14px;
    font-size: 16px;
    position: absolute;
    right: 0;
    height: 100%;

    display: inline-block;

    vertical-align: middle;

    border-radius: 2px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    transition: border-radius 200ms $swift-out;

    .country {
      height: 13px;
    }

    .fa {
      position: relative;
      top: 2px;

      transition: transform 200ms $swift-out;

      .country {
        top: -2px;
      }

    }
  }

  .foldout {
    position: absolute;
    background: $color-white;
    width: 100%;
    z-index: 5;

    border: 1px solid $color-light-gray;
    border-top: 0;
    border-bottom: 0;
    border-radius: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    max-height: 0;

    overflow-y: auto;
    overflow-x: hidden;

    transition: max-height 200ms $swift-out;

    &.-activated {
      border-bottom: 1px solid $color-light-gray;
    }

    li {
      font-size: 16px;
      padding: 14px 20px 14px 17px;
      width: 100%;

      border-bottom: 1px solid $color-light-gray;

      transition: transform 200ms $swift-out;

      a {
        font-family: $font-family;
        font-size: 16px;
      }

      span {
        transition: transform 200ms $swift-out;

        transform: translate3d(0, 0, 0);

        display: inline-block;
      }

      &:hover {
        span {
          transform: translate3d(2px, 0, 0);
        }
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    &.-activated {
      max-height: 200px;
    }
  }

  &.-activated {
    z-index: 2 !important;

    .dropdown__container {
      li {
        border-bottom-left-radius: 0;

        &.-activated {
          //border-bottom: 1px solid transparent;
        }
      }
    }

    .arrow {
      border-bottom-right-radius: 0;

      .fa {
        transform: rotate(180deg);
      }
    }
  }

  &:active {
    transform: translateZ(2em);
  }
}
