.relatedInfo {
  border-top: 1px solid $color-light-gray;
  padding-top: 25px;
  padding-bottom: 25px;

  &__list {
    display: flex;
    flex-flow: row wrap;
  }

  &__item {

  }

}
