.imageBlock {
  width: 50%;
  display: inline-block;
  font-size: 1em;
  padding: 0 20px 0 0;

  .primary {
    width: 100%;
    border: 1px solid $color-light-gray;
    height: 0;
    padding-top: 100% !important;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-height: 100%;
      max-width: 100%;
      display: block;
      margin: 0 auto;

      .ieLessThan11 & {
        flex: 0 0 auto;
      }
    }
  }

  &.-youtube .primary {
    //padding: 0;

    iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .owl-carousel {
    margin-top: 20px;
    position: relative;

    .owl-item {
      overflow: hidden;
    }

    .owl-nav {
      &.disabled {
        display: block;
      }

      .owl-prev {
        position: absolute;
        height: 100%;
        background: rgba($color-white, 1);
        color: $color-dark-gray;
        font-size: 15px;
        display: flex;
        align-items: center;
        padding: 0 5px 0 5px;
        cursor: pointer;
        opacity: 1;
        top: 0;
        margin-right: 10px;
        left: -20px;

        i {
          position: relative;
        }

        &.disabled {
          display: none;
        }
      }

      .owl-next {
        position: absolute;
        height: 100%;
        background: rgba($color-white, 1);
        color: $color-dark-gray;
        font-size: 15px;
        display: flex;
        align-items: center;
        padding: 0 5px 0 5px;
        top: 0;
        right: -20px;
        cursor: pointer;
        opacity: 1;

        i {
          position: relative;
        }

        &.disabled {
          display: none;
        }
      }

      @media all and (max-width: 1000px) {
        display: none !important;
      }
    }

    .thumbnail {
      display: block;
      width: 170px;
      height: 0;
      padding-top: 100% !important;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      text-align: center;
      //border: 1px solid $color-dark-gray;

      @include breakpoint(tablet) {
        width: 50vw;
      }

      @media all and (max-width: 1000px) {
        width: 50vw;
      }

      img {
        display: block;
        width: 50px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) translateX(-50%);
        left: 50%;
      }
    }
  }

  @media all and (max-width: 900px) {
    width: 100%;
    padding-right: 0;
  }
}

.productPage {
  .imageBlock {
    .primary {
      background-size: 70%;
      background-repeat: no-repeat;
      background-position: center;
    }

    .thumbnail {
      background-size: contain;
    }
  }
}
