.campaignPage {

  @include breakpoint(tablet) {

    & [class^="sv-column"],
    & [class*="sv-column"] {
      left: 0 !important;
      width: 100% !important;
    }
  }

  &__hero {
    position: relative;
    padding-top: 56.25%;
    height: 0;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    &.-videoPointer {
      cursor: pointer;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    & video,
    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }

    & .btn {
      position: absolute;
      z-index: 2;
      top: 25px;

      @include breakpoint(tablet) {
        display: none;
      }
    }

    .heading {
      font-size: 2vw !important;//2em !important;
      line-height: 2.4em !important;
      position: absolute;
      bottom: 0;
      padding-bottom: calc( 1vh * 10 );
      -webkit-transform: rotate(-7deg) skew(-7deg, 0);
         -moz-transform: rotate(-7deg) skew(-7deg, 0);
          -ms-transform: rotate(-7deg) skew(-7deg, 0);
           -o-transform: rotate(-7deg) skew(-7deg, 0);
              transform: rotate(-7deg) skew(-7deg, 0);
      
      @include breakpoint(tablet) {
        font-size: 1.5em !important;
        line-height: 2.5em !important;
        padding-bottom: 60px;
        left: -4px;
      }

      @include breakpoint(mobile) {
        font-size: .8em !important;
        line-height: 2.5em !important;
        padding-bottom: 30px;
      }
      span {
        background: #000;
        color: #ffcb00;
        padding: .5em;
        position: relative;
        text-transform: uppercase;
        box-shadow: .1em .1em .2em #111;
      }
    }
  }

  .video {
    display: none;
  }

  & img {
    max-width: 100% !important;
  }

  
  & .stretch {
    & img {
      width: 100% !important;
    }
  }

  .articleTopImage {
  height: 65vh;
  @include breakpoint(tablet) {
      height: 500px;
  }
  @include breakpoint(mobile) {
      height: 300px;
  }
  overflow: hidden;
  position: relative;

    & .backgroundImage {

      & img {
        width: 100%;
        max-height: 100% !important;
        height: 100%;
        object-fit: cover;
        z-index: -1;
        position: absolute;
      }
    }
  }
}

.campaignpageHero {
  position: relative;
  height: $startpage-background-height + $header-height;
  overflow: hidden;
  width: 100%;
  background-size: cover;

  @include breakpoint(mobile) {
    height: auto;
    min-height: 500px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: $startpage-background-height + $header-height;
    width: 100%;
    background-position: center (-$header-height);
    background-repeat: no-repeat;
    image-rendering: -webkit-optimize-contrast;

    @include breakpoint(mobile) {
      background-position: center center;
    }
  }

  &.nobleArt {
    height: $startpage-background-noble-height;
    min-height: $startpage-background-height;

    @include breakpoint(mobile) {
    height: auto;
    min-height: 500px;
    }
  }

  & > .campaignpageHeroTop {
      & > .sv-layout {
        position: absolute;
        left: 0;
        top: 30px;
        right: 0;
        
        & > .heading, h1 {
            font-size: 4em!important;
            color:#fff!important;
            line-height:1em!important;
        }

        @include breakpoint(mobile) {
          //position: static;
          //margin-top: 14px;

          & > .heading, h1 {
            font-size: 2.5em!important;
          }
        }

        @include breakpoint(tablet) {
          //position: static;
          //margin-top: 14px;
        } 
     }
  }

    & > .campaignpageHeroBottom {
      & > .sv-layout {
        position: absolute;
        right: 0;
        bottom: 30px;
        left: 0;
        
        & > .heading, h1 {
            font-size: 2em!important;
            color:#fff!important;
            //text-align:right;
        }

        & > .sv-font-heading-4, h4 {
            color:#fff!important;
            //text-align:right;
        }

        & .sv-text-portlet-content > .normal {
            color:#fff!important;
            //text-align:right;

            & + .normal {
                font-size:0.8em!important;
            }
        }

        @include breakpoint(mobile) {
          margin-top: 20px;
          margin-bottom: 0px;
          bottom: 14px;

          & > .heading, h1 {
            font-size: 1.5em!important;
          }
        }

        @include breakpoint(tablet) {
          margin-top: 20px;
          margin-bottom: 0px;
          //bottom: 14px;
        }
     }
  }
}
