.menu {
	width: 100%;
    letter-spacing: 0.5px;
	@include breakpoint(desktop) {
		position: fixed;
		top: 0;
		right: 0;//-250px;
		height: 100%;
        width: 250px;
        letter-spacing: normal;
	}
	//display: table;
	//background: $color-light-black;
	background: url('/webdav/files/resources/img/engcon-menu-background-yellow.jpg');
    @include breakpoint(desktop) {
        background: url('/webdav/files/resources/img/engcon-menu-background-dark.jpg');
    }
	background-size: cover;
	background-position: top center;
	z-index: 1;
	overflow-y: auto;
	backface-visibility: hidden;
	.header {
        @include breakpoint(desktop) {
            display: none;
        }
        width: 100%;
		.logo {
            //display: table-cell;
            width: auto;
			margin: auto;
			//vertical-align: middle;
            height: 120px;
			float: left;
			padding-left: 25px;
			@include breakpoint(desktop) {
				display: none;
				padding-left: calc(50% - 50px);
				padding-top: 18px;
				padding-bottom: 13px;
			}
			//background-color:#FFD300;
			//background: $color-yellow;
			.logotype {
				//width: 100%;
                display: inline-block;
				position: relative;
				z-index: 2;
				margin: auto;
                padding-top:25px;
				&__link {
					display: inline-block;
					width: 230px;
					text-decoration: none;
					svg {
						width: 230px;
						height: 50px;
					}
					.image {
						width: 100%;
					}
				}
			}
		}
        &::after {
            content: '';
            display: table;
            clear: both;
        }
	    .secondaryTopLevel {
		    //margin-bottom: 35px;
		    float: right;
		    //width: 100%;
		    //display: table-cell;
            margin-top: 15px;
            margin-right: 15px;
		    &__item {
			    text-align: left;
				float: right;
                display: inline;
                margin-left: -1px;
                .secondarySubLevel {
					position: absolute;
					opacity: 0;
					overflow-y: hidden;
					min-width: 200px;
				}
			    & > a {
				    display: inline-block;
				    width: 100%;
				    font-size: 14px;
				    color: $color-black;
                    @include breakpoint(desktop) {
                        color: $color-yellow;
                    }
				    text-decoration: none;
				    padding: 10px 0px 10px 10px;
				    font-family: $font-family;
				    font-weight: $font-weight-light;
				    text-transform: uppercase;
                    &.-activated {
                        color: $color-white;
                        background-color: $color-black;
                        i {
                        }
				    }
                    &:hover {
                        color: $color-white;
                        background-color: $color-black;
                        i {
                        }
                    }
				    i {
					    padding-right: 10px;
                        border-right: 1px dotted $color-ultra-dark-gray;
					    word-break: break-word;
				    }
				    /*
            &::before {
              position: absolute;
              display: inline-block;
              content: '\f054';
              font-family: $font-family-icon;
              font-size: 10px;
              padding-right: 10px;
              margin-top: 4px;
              color: $color-white;
              transition: transform 150ms $apple-ease, color 150ms $apple-ease, opacity 150ms $apple-ease;
              opacity: 0.5;
            }

            &:hover {
              &::before {
                opacity: 1;
              }
            }

            &.-activated {
              &::before {
                opacity: 1;
              }
            }
            */
			    }
			    .secondarySubLevel {
				    max-height: 0;
				    opacity: 0;
				    overflow: hidden;
                    position: absolute;
                    z-index: 9999;
					&.-activated {
						overflow-y: auto;
					}
				    //transform: scale(0.8);
				    //transition: max-height 1s $apple-ease, opacity 500ms $apple-ease, transform 500ms $apple-ease;
				    &__item {
                        background: $color-black;
						border-top: 1px solid #333333;
					    & > a {
						    display: inline-block;
						    font-size: 13px;
						    color: $color-white;
						    text-decoration: none;
						    opacity: 0.5;
						    padding: 10px;
						    width: 100%;
						    //transform: translate3d(0, 0, 0);
						    //transition: opacity 150ms $apple-ease;
						    font-family: $font-family;
						    font-weight: $font-weight-light;
						    &:hover {
							    opacity: 1;
						    }
						    &.-activated {
							    opacity: 1;
						    }
					    }
					    &:first-child {
						    margin-top: 0;
					    }
					    &:last-child {
						    margin-bottom: 15px;
					    }
				    }
				    &.-activated {
					    max-height: 0px;
					    opacity: 0;
					    //transform: scale(1);
				    }
			    }
			    &.-activated {
				    & > a::before {
					    display: inline-block;
					    color: $color-yellow;
					    transform: rotate(90deg) translate3d(5px, 5px, 0);
				    }
			    }
			    &.-noSubLevel {
				    & > a {
					    //transition: color 150ms $apple-ease;
					    &::before {
						    visibility: hidden;
					    }
				    }
				    &.-activated {
					    & > a {
						    color: $color-white;
					    }
				    }
			    }
				&:hover {
                    background-color: $color-black;
                    & > a {
                        color: $color-white;
                    }
					.secondarySubLevel {
						max-height: 2000px;
						opacity: 1;
					}
				}
		    }
            & > a {
                text-align: left;
				float: right;
                display: inline;
                & i {
                    display: inline-block;
				    width: 100%;
				    font-size: 14px;
				    color: $color-black;
				    text-decoration: none;
				    padding: 10px 10px 10px 10px;
                    margin-top: -1px;
                    &:hover {
                        color: $color-white;
                        background-color: $color-black;
                    }
                }
            }
	    }
    }
	.main {
        width: 100%;
        height: 100%;
        //display: table;
        //background-color: $color-black;
		.topLevel {
			//margin-top: $header-height;
			//margin-bottom: 35px;
            @include breakpoint(desktop) {
                float: right;
                margin-bottom: 35px;
			    width: 250px;
            }
			//float: right;
			//width: 250px;
			//display: table-row;
			list-style-type: none;
			//display: table-cell;
			clear: both;
			//padding-left: 25px;
			width: 100%;
			&__item {
				// TODO: fix color
				//border-top: 1px solid #333333;
				text-align: left;
				float: left;
                display: inline;
                margin-left: -1px;
                &:first-child {
                    margin-left: 0px;
                }
                @include breakpoint(desktop) {
                    float: none;
                    margin: 0;
                    border-top: 1px solid #333333;
                    display: block;
                }
				/*&:last-of-type {
					text-align: right;
					.subLevel {
						position: absolute;
						right: 28px;
						a {
							padding: 15px 15px 15px 40px;
						}
					}
				}*/
				.subLevel {
					position: absolute;
					opacity: 0;
					overflow-y: hidden;
					min-width: 250px;
				}
                &.-activated {
                    background-color: $color-black;
                }
				&:hover {
                    background-color: $color-black;
                    & > a {
                        color: $color-white;
                    }
					.subLevel {
						max-height: 2000px;
						opacity: 1;
						//-ms-transform: scale(1);
						//transform: scale(1);
						//border-top: 10px solid rgba(0, 0, 0, 0.25);
						//margin-top: -10px;
					}
				}
				& > a {
					display: inline-block;
					//color: $color-yellow;
                    color: $color-black;
					text-decoration: none;
					font-size: 16px;
                    @include breakpoint(desktop) {
                        font-size: 18px;
                    }
					padding: 25px 0px 25px 25px;
					font-family: $font-family;
					font-weight: $font-weight-medium;
					text-transform: uppercase;
                    @include breakpoint(desktop) {
                        text-transform: none;
                    }
					position: relative;
					width: 100%;
					.ieLessThan11 & {
						//width: calc(100% - 17px);
						//margin-left: 17px;
					}
                    @include breakpoint(desktop) {
                        color: $color-yellow;
					    &::before {
						    display: inline-block;
						    content: '\f054';
						    font-family: $font-family-icon;
						    font-size: 10px;
						    //padding-right: 10px;
						    position: absolute;
						    //margin-top: 3px;
						    //height: 100%;
                            color: $color-white;
						    //transition: transform 250ms $apple-ease, color 150ms $apple-ease, opacity 150ms $apple-ease;
						    opacity: 0.5;
						    padding-left: 5px;
					    }
                        &::after {
                            display: inline-block;
                            content: '';
                            position: absolute;
                            width: 5px;
                            height: 100%;
                            left: 0;
                            top: 0;
                            background: $color-yellow;
                            //transform: translate3d(-100%, 0, 0);
                            //transition: transform 250ms $apple-ease, opacity 150ms $apple-ease;
                            opacity: 0;
                        }
                    }
					i {
						word-break: break-word;
                        padding-right: 25px;
                        border-right: 1px dotted $color-ultra-dark-gray;
                        @include breakpoint(desktop) {
                            padding-left: 20px;
                            padding-right: 0px;
                            display: block;
                            border: none;
                        }
					}
					/*&::after {
            display: inline-block;
            content: '';
            position: absolute;
            width: 5px;
            height: 100%;
            left: 0;
            top: 0;
            background: $color-yellow;
            //transform: translate3d(-100%, 0, 0);
            //transition: transform 250ms $apple-ease, opacity 150ms $apple-ease;
            opacity: 0;
        }*/
					&:hover {
						&::before {
							opacity: 1;
						}
					}
					&.-activated {
                        color: $color-white;
						&::after {
							//transform: translateX(0);
							opacity: 1;
						}
						&::before {
							opacity: 1;
						}
					}
					@media all and (max-width: 970px) {
						padding: 20.5px 10px 20.5px 30px;
					}
				}
				.subLevel {
					opacity: 0;
					max-height: 0;
					//transition: max-height 1s $apple-ease, opacity 500ms $apple-ease, transform 500ms $apple-ease;
					overflow: hidden;
					//transform: scale(0.8);
					z-index: 9999;
                    //margin-left: -25px;
					&.-activated {
						//max-height: 2000px;
						//opacity: 1;
						//transform: scale(1);
						overflow-y: auto;
					}
					&__item {
						// TODO: fix color
						background: $color-black;
						border-top: 1px solid #333333;
						& > a {
							display: inline-block;
							color: $color-white;
							font-size: 15px;
							text-decoration: none;
							padding: 15px 25px 15px 25px;
							opacity: 0.5;
							//transition: opacity 150ms $apple-ease;
							//transform: translate3d(0, 0, 0);
							font-family: $font-family;
							font-weight: $font-weight-medium;
							font-weight: 400;
							width: 100%;
							&:hover {
								opacity: 1;
							}
							&.-activated {
								opacity: 1;
							}
						}
					}
				}
				&.-activated {
					& > a::before {
						display: inline-block;
						color: $color-yellow;
						transform: rotate(90deg);// translate3d(5px, 5px, 0);
					}
				}
				&:last-child {
					// TODO: fix color
					//border-bottom: 1px solid #333333;
				}
				&.-noSubLevel {
					& > a::before {
						opacity: 0;
					}
				}
                &:last-child {
                    & > a {
                        & > i {
                            border-right: none;
                            //padding-right: 0;
                        }
                    }
                }
			}
		}
	}
	@media all and (max-width: 1170px) {
		opacity: 0;
		animation: menuOpacity 0ms forwards;
		@keyframes menuOpacity {
			0% {
				opacity: 1;
			}
			100% {
				opacity: 0;
			}
		}
		&.-activated {
			opacity: 1;
			animation: menuOpacityReverse 0ms forwards;
			@keyframes menuOpacityReverse {
				0% {
					opacity: 0;
				}
				100% {
					opacity: 1;
				}
			}
		}
	}

    .mobileSecondaryTopLevel {
            display: none;
            @include breakpoint(desktop) {
                display: block;
            }

                margin-bottom: 35px;
    float: right;
    width: 250px;

    &__item {
      display: block;
      width: 100%;

      & > a {
        display: inline-block;
        width: 100%;
        font-size: 16px;
        color: $color-yellow;
        text-decoration: none;
        padding: 10px 40px 10px 40px;
        font-family: $font-family;
        font-weight: $font-weight-medium;

        i {
          padding-left: 20px;
          display: block;
          word-break: break-word;
        }

        &::before {
          position: absolute;
          display: inline-block;
          content: '\f054';
          font-family: $font-family-icon;
          font-size: 10px;
          padding-right: 10px;
          margin-top: 4px;
          color: $color-white;
          transition: transform 150ms $apple-ease, color 150ms $apple-ease, opacity 150ms $apple-ease;
          opacity: 0.5;
        }

        &:hover {
          &::before {
            opacity: 1;
          }
        }

        &.-activated {
          &::before {
            opacity: 1;
          }
        }
      }

      .secondarySubLevel {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        transform: scale(0.8);
        transition: max-height 1s $apple-ease, opacity 500ms $apple-ease, transform 500ms $apple-ease;

        &__item {
          margin-top: 15px;
          display: block;
          width: 100%;

          & > a {
            display: inline-block;
            font-size: 16px;
            color: $color-white;
            text-decoration: none;
            opacity: 0.5;
            padding: 0 0 0 60px;
            width: 100%;
            transform: translate3d(0, 0, 0);
            transition: opacity 150ms $apple-ease;
            font-family: $font-family;
            font-weight: $font-weight-medium;

            &:hover {
              opacity: 1;
            }

            &.-activated {
              opacity: 1;
            }
          }

          &:first-child {
            margin-top: 0;
          }

          &:last-child {
            margin-bottom: 15px;
          }
        }

        &.-activated {
          max-height: 500px;
          opacity: 1;
          transform: scale(1);
        }
      }

      &.-activated {
        & > a::before {
          display: inline-block;
          color: $color-yellow;
          transform: rotate(90deg) translate3d(5px, 5px, 0);
        }
      }

      &.-noSubLevel {
        & > a {
          transition: color 150ms $apple-ease;

          &::before {
            visibility: hidden;
          }
        }

        &.-activated {
          & > a {
            color: $color-white;
          }
        }
      }
    }
    }

    .secondaryTopLevel {
        &.mobile {

    }
        }

	.social {
		float: right;
		width: 250px;
		margin-bottom: $header-height;
		text-align: center;
		margin: 0 auto;
		li {
			display: inline-block;
			padding-right: 0px;
			i {
				color: $color-dark-gray;
				font-size: 20px;
				padding: 6px;
				transition: color 150ms $apple-ease;
				&.fa {
					&.fa-facebook {
						background-color: #3B5998;
						color: $color-white;
						width: 32px;
						height: 32px;
						border-radius: 2px;
					}
					&.fa-twitter {
						background-color: $color-white;
						color: #00aced;
						width: 32px;
						height: 32px;
						border-radius: 2px;
					}
					&.fa-youtube {
						background-color: #CC181E;
						color: $color-white;
						width: 32px;
						height: 32px;
						border-radius: 2px;
					}
					&.fa-instagram {
						background: /////// Use radial gradients to get the rounded
						/////// gradient effect in each corner
						// left bottom
						radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), // left top
						radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), //  right top
						radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), // right bottom
						radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), /////// create a base coat to smooth
						/////// corner gradients
						linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
						color: $color-white;
						width: 32px;
						height: 32px;
						border-radius: 2px;
					}
					&.fa-flickr {
						background: rgb(0, 99, 220);
						background: -moz-linear-gradient(left, rgb(0, 99, 220) 0%, rgb(0, 99, 220) 50%, rgb(255, 0, 132) 50%, rgb(255, 0, 132) 100%);
						background: -webkit-linear-gradient(left, rgb(0, 99, 220) 0%, rgb(0, 99, 220) 50%, rgb(255, 0, 132) 50%, rgb(255, 0, 132) 100%);
						background: linear-gradient(to right, rgb(0, 99, 220) 0%, rgb(0, 99, 220) 50%, rgb(255, 0, 132) 50%, rgb(255, 0, 132) 100%);
						-ms-filter: "progid:DXImageTransform.Microsoft.gradient( startColorstr='#0063dc', endColorstr='#ff0084',GradientType=1 )";
						color: $color-white;
						width: 32px;
						height: 32px;
						border-radius: 2px;
					}
				}
			}
			&:hover {
				i {
					color: $color-yellow;
				}
			}
		}
	}
}