.contactPersonPage {
  font-size: 0;
  width: 80%;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;

  .image {
    width: 30%;
    display: inline-block;

    img {
      width: 100%;
      border: 1px solid $color-light-gray;
    }
  }

  .info {
    width: 67.5%;
    left: 2.5%;
    display: inline-block;
    font-size: 1rem;
    position: relative;
    vertical-align: top;

    h1 {
      color: $color-black !important;
    }

    .title {
      color: $color-ultra-dark-gray;
    }

    .stationing {
      color: $color-ultra-dark-gray;
      font-family: $font-family !important;
      font-weight: $font-weight-normal !important;
    }

    .divider {
      width: 100%;
      margin-top: 15px;
      padding-top: 15px;
      border-top: 1px dotted $color-dark-gray;
    }

    a {
      text-decoration: none;
    }

    span {
      display: block;
      margin-top: 10px;
      color: $color-ultra-dark-gray;

      i {
        padding-right: 10px;

        color: $color-black;
      }
    }
  }

  @media all and (max-width: 900px) {
    .image {
      width: 100%;
      margin-bottom: 25px;
      margin-right: 0;
    }

    .info {
      width: 100%;
    }
  }
}
