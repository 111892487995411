.header {
    
    .logotype {
      display: none;
    }

    @include breakpoint(desktop) {
      background-color: $color-yellow;
      background: url('/webdav/files/resources/img/engcon-menu-background-yellow.jpg');
      padding-top: 23px;
      padding-bottom: 17px;
      max-height: $header-height;

      .logotype {
        display: block;
        width: 100%;
        position: relative;
        z-index: 2;

        &__link {
          display: inline-block;
          width: 130px;

          svg {
            width: 130px;
            height: 30px;
          }

          .image {
            width: 100%;
          }
        }
      }
  }

  .excavator {
    position: absolute;
    z-index: 2;
    top: 0;
    width: 100%;
    height: $startpage-background-height + $header-height;
    z-index: 1;
    display: none;
    transition: opacity 250ms $apple-ease;

    .image {
      background: url('/webdav/files/resources/img/header__excavator.png');
      background-size: cover;
      background-position: top center;
      image-rendering: -webkit-optimize-contrast;
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }

    &.-activated {
      display: block;
    }

    @media all and (max-width: 960px) {
      &.-activated {
        display: none;
      }
    }

    @media all and (max-width: 1170px) {
      .-menuActivated & {
        opacity: 0;
      }
    }
  }

  .search {
    position: absolute;
    height: $header-height;
    top: 0;
    left: 0;
    width: 100%;
    background: $color-white;
    z-index: 1002;
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: max-height 250ms $apple-ease, opacity 250ms $apple-ease;
    display: flex;
    align-items: center;

    &__container {
      width: 100%;
      margin: 0 auto;
      height: $header-height;
      position: relative;

      input {
        height: 100%;
        width: 100%;
        border: none;
        padding: 40px;
        padding-top: 0;
        padding-bottom: 0;
        font-size: 20px;
        font-family: $font-family;
        color: $color-ultra-dark-gray;
        outline: none;
      }

      p {
        position: absolute;
        right: 40px;
        top: 18px;

        i {
          font-size: 20px;
          color: $color-dark-gray;
          margin-top: 6px;
        }
      }
    }

    &.-activated {
      opacity: 1;
      max-height: $header-height;

      &:before {
        content: '';

        display: block;
        position: absolute;
        z-index: 1;

        box-shadow: 0 5px 10px rgba(0,0,0,0.08);

        width: 100%;
        height: 20px;
        top: $header-height;

        margin-top: -20px;
      }
    }
  }

  .search-foldout-container {
    width: 100%;
    text-align: center;
    position: absolute;
    display: none;

    top: $header-height + 5;
    z-index: 1003;

    &.-foldoutActivated::after {
      opacity: 1;
    }

    .-searchActivated & {
      display: block;
    }

    &::after {
      bottom: 100%;
      left: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(136, 183, 213, 0);
      border-bottom-color: $color-ultra-light-gray;
      border-width: 12px;
      margin-left: -12px;
      opacity: 0;

      transition: opacity 250ms $apple-ease;
    }
  }

  .search-foldout {
    display: none;

    height: auto;
    width: 90%;
    max-width: 1090px;

    margin: 0 auto;

    background: $color-ultra-light-gray;

    text-align: left;

    opacity: 1;
    max-height: 0;

    transition: opacity 250ms $apple-ease, max-height 250ms $apple-ease;

    transform: translate3d(0,0,0);

    overflow-y: auto;

    border-radius: 2.5px;

    position: relative;

    .result {
      transform: scale(0.8);
      transition: transform 500ms $apple-ease;
      height: 0;
      overflow: hidden;
      position: relative;

      .facet {
        padding: 20px 25px 0 25px;

        h2 {
          font-size: 20px;
          font-family: $font-family;
          font-weight: 800;
        }

        ul {
          margin-top: 15px;

          li {
            opacity: 0;
            width: calc(33% - 12.5px);
            margin-left: 16.6666px;
            margin-top: 16.6666px;
            vertical-align: top;
            display: flex;

            &:nth-child(1), &:nth-child(2), &:nth-child(3) {
              margin-top: 0;
            }

            &:first-child, &:nth-child(3n + 4) {
              margin-left: 0;
            }

            .page {
              background-color: $color-white;
              padding: 25px;
              flex: 1;
              border: 1px solid $color-light-gray;
              text-decoration: none;
              display: flex;
              flex-flow: column;
              width: 100%;

              .content {
                position: relative;
                max-height: 60px;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .breadcrumb {
                color: $color-dark-gray;
                margin-top: 5px;
              }
            }

            .product {
              background-color: $color-white;
              padding: 25px;
              flex: 1;
              border: 1px solid $color-light-gray;
              text-decoration: none;
              display: flex;
              flex-flow: row;
              width: 100%;

              h3 {
                word-break: break-all;
              }

              .image {
                width: 20%;

                img {
                  max-height: 150px;
                }
              }

              .content {
                width: 80%;
                padding-left: 20px;
                padding-right: 20px;
              }
            }

            .contact {
              background-color: $color-white;
              padding: 25px;
              padding-right: 15px;
              flex: 1;
              border: 1px solid $color-light-gray;
              text-decoration: none;
              display: flex;
              flex-flow: row;
              width: 100%;

              .image {
                width: 30%;

                img {
                  max-height: 150px;
                }
              }

              p {
                display: block !important;

                &.-stationing, &.-title {
                  color: $color-dark-gray;
                }
              }

              .content {
                width: 70%;
                padding-left: 20px;

                .details {
                  padding-top: 5px;
                  border-top: 1px dotted $color-dark-gray;

                  p {
                    display: block;
                    word-break: break-all;
                    font-size: 13px !important;
                    margin-top: 5px;
                    color: $color-dark-gray;

                    i {
                      padding-right: 5px;
                      color: $color-black;
                    }
                  }
                }
              }
            }

            @include breakpoint(tablet) {
              width: calc(50% - 12.5px);

              &:nth-child(3) {
                margin-top: 16.6666px;
              }

              &:nth-child(3n + 4) {
                margin-left: 16.6666px;
              }

              &:nth-child(2n + 3) {
                margin-left: 0;
              }
            }

            @include breakpoint(mobile) {
              width: 100%;
              margin-left: 0;
              margin-right: 0;

              &:nth-child(3n + 4) {
                margin-left: 0;
              }

              &:nth-child(2) {
                margin-top: 16.6666px;
              }
            }
          }
        }

        &:last-child {
          padding-bottom: 50px;
        }
      }
    }

    &.-activated {
      max-height: calc(100vh - #{$header-height + 70});
      opacity: 1;

      .result {
        transform: scale(1);
      }
    }
  }

  @include breakpoint(desktop) {
      &:before {
        content: '';

        display: block;
        position: absolute;
        z-index: 1;

        box-shadow: 0 5px 10px rgba(0,0,0,0.15);

        width: 100%;
        height: $header-height;//20px;
        top: 0px;//$header-height;

        //margin-top: -20px;
      }
      /*
      &:after {
        content: '';

        display: block;
        position: absolute;

        width: 100%;
        height: 20px;
        top: $header-height;

        margin-top: -25px;

        background-color: $color-yellow;
      }*/
  }
}
