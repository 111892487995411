$tweakpoints: (
  tp-mobile: 420px
);

.archiveSeparator {
  border-bottom: solid 1px $color-light-gray;
  margin-top: 20px;
  margin-bottom: 40px;
}

// TODO: Break out ot own component
.archiveFeatured {
  background-color: $color-white;
  border: solid 1px $color-light-gray;
  margin-bottom: 30px;
  margin-right: 30px;
  width: calc(50% - 15px);

  &:nth-child(2n + 2) {
    margin-right: 0;
  }

  @include breakpoint(tablet) {
    width: calc(50% - 7px);
    margin-right: 14px;
  }

  @include breakpoint(mobile) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 14px;
  }

  .ieLessThan11 &,
  .no-flexbox & {
    display: inline-block;
    vertical-align: top;
    font-size: 0;
  }

  &__header {
    padding: 22px 30px 18px;
    border-bottom: solid 1px $color-light-gray;

    & h2 {
      margin-bottom: 0;
    }

    @include breakpoint(tablet) {
      padding: 18px 20px 14px;
    }

    @include breakpoint(mobile) {
      padding: 18px 14px 14px;
    }
  }

  &__body {
    display: flex;
    flex-flow: row nowrap;

    @include breakpoint(tablet) {
      flex-flow: column wrap;
    }

    @include breakpoint(mobile) {
      flex-flow: row wrap;
    }
  }

  &__image {
    width: 170px;
    min-height: 215px;
    background-image: url('../img/patterns/feature-grey-small.png');
    background-position: center center;
    background-size: cover;

    @include breakpoint(tablet) {
      width: 100%;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      display: inline-block;
      vertical-align: top;
    }
  }

  &__content {
    flex-grow: 1;
    padding: 25px 30px;

    @include breakpoint(tablet) {
      padding: 25px 20px;
    }

    @include breakpoint(mobile) {
      padding: 25px 14px;
    }

    .ieLessThan11 &,
    .no-flexbox & {
      display: inline-block;
      vertical-align: top;
      width: calc(100% - 170px;)
    }

    & header {
      border-bottom: 1px dotted $color-gray;
      padding-bottom: 5px;
      margin-bottom: 15px;
    }

    & h3 {
      margin-bottom: 0;
    }

    & h6 {
      font-size: 15px;
      line-height: 1.4em;
      color: $color-ultra-dark-gray;
      font-family: $font-family;
      font-weight: $font-weight-medium;
    }

    & p {
      font-size: 15px !important;
      color: $color-ultra-dark-gray;
      font-family: $font-family;

      @include breakpoint(mobile) {
        font-size: 13px !important;
      }
    }

    & a {
      transition: color .2s $apple-ease;

      &:hover {
        color: $color-black;
      }
    }

    & a,
    & span {
      display: inline-block;
      color: inherit;
      text-decoration: none;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      white-space: nowrap;

      & span {
        display: inline-block;
        vertical-align: top;
        white-space: normal;
        word-break: break-all;
      }

      & + a,
      & + span {
        margin-top: 3px;
      }

      & i {
        display: inline-block;
        margin-right: 6px;
        color: $color-black;
      }
    }

    & span {
      display: block;
    }
  }

  &.-social {

    & .archiveFeatured__content {
      width: 100%;
    }

    & p {
      color: $color-black;
      font-family: $font-family !important;
    }

    & ul {
      margin-top: 3px;
      margin-bottom: 30px;

      @include breakpoint(tablet) {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0;

        @include breakpoint(tablet) {
          margin-bottom: 20px;
        }
      }

    }

    & li a {
      display: inline-block;
      width: 100%;
    }

    & a {
      font-family: $font-family;
      font-weight: $font-weight-medium;
      font-size: 15px;
      line-height: 1.6em;
      color: $color-ultra-dark-gray;
      transition: color .2s $apple-ease;

      @include breakpoint(mobile) {
        font-size: 13px !important;
      }

      &:hover {
        color: $color-black;
      }

      & i {
        color: $color-yellow;
      }
    }
  }

  & .sv-grid-main {
    @include breakpoint(tablet) {
      [class^='sv-column-'],
      [class*=' sv-column-'] {
        display: block;
        width: 100%;
        left: 0;
      }
    }
  }
}

// TODO: Break out to own component
.archiveNav {
  border-top: solid 1px $color-light-gray;
  border-bottom: solid 1px $color-light-gray;
  background-color: #fff;

  &__list {
    font-size: 0;
  }

  &__item {
    display: inline-block;
    vertical-align: middle;
    padding: 24px 15px 23px 0;

    & + li {
      &:before {
        content: '';
        display: inline-block;
        vertical-align: middle;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        margin-right: 15px;
        background-color: $color-yellow;
      }
    }
  }

  &__link {
    display: inline-block;
    vertical-align: middle;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 17px;
    line-height: 1em;
    color: $color-ultra-dark-gray;
    text-decoration: none;
    transition: color .2s $apple-ease;

    &:hover,
    &:focus,
    .-active & {
      color: $color-black;
    }
  }
}
