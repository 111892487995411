.staff {
	padding-bottom: 20px;

	&:nth-child(n+4) {
		border-top: 1px solid $color-light-gray;
	}

	&__group {
		padding-top: 30px;
		padding-bottom: 10px;
	}

	.faq {
    margin-bottom: 20px;
	}

	h2 {
		padding-top: 20px;
		padding-bottom: 10px;
	}

	&__card {
		display: inline-block;
		width: calc(50% - 15px);
		background: $color-white;
		height: auto;
		margin-right: 20px;
		margin-bottom: 20px;
		vertical-align: top;
    border: 1px solid $color-light-gray;

    transition: border 250ms $apple-ease;

    &:hover,
    &:focus {
      border: 1px solid $color-dark-gray;
    }

		&:nth-child(2n + 2) {
			margin-right: 0;
		}

		.staff__photo {
			width: 28%;
	    background: url('/webdav/files/resources/img/patterns/feature-grey.jpg');
    	background-repeat: repeat;
			display: block;
			position: relative;
			float: left;
		}
		.staff__info {
			display: block;
			width: 71%;
			padding: 13px 20px;
			position: relative;
			float: left;
		}
		.staff__name {
			display: inline-block;
			width: 100%;
		}
		.staff__title {
			display: inline-block;
			width: 100%;
			color: $color-ultra-dark-gray;
			font-size: 14px !important;
			font-weight: 600;
		}
		.staff__station {
			display: inline-block;
			width: 100%;
			color: $color-ultra-dark-gray;
			font-size: 14px !important;
			padding-bottom: 5px;
			border-bottom: 1px solid $color-light-gray;

		}
		a.staff__email {
			display: inline-block;
			width: 100%;
			color: $color-ultra-dark-gray;
			font-size: 14px !important;
			padding-top: 8px;
			text-decoration: none;
			&:before {
				padding-right: 9px;
				font-family: FontAwesome;
				content: '\f003';
				color: $color-black;
				font-size: 14px;
			}
		}

		a.staff__phone, a.staff__mobile {
			display: inline-block;
			width: 100%;
			color: $color-ultra-dark-gray;
			font-size: 14px !important;
      text-decoration: none;
			&:before {
				padding-right: 10px;
				font-family: FontAwesome;
				content: '\f095';
				color: $color-black;
				font-size: 14px;
			}
		}
		.staff__mobile {
			display: inline-block;
			width: 100%;
			color: $color-ultra-dark-gray;
			font-size: 14px !important;
			&:before {
				padding-right: 10px;
				font-family: FontAwesome;
				content: '\f095';
				color: $color-black;
				font-size: 14px;
			}
		}
	}



@media all and (max-width: 900px) {
	.staff__photo {
		display: none;
	}
	.staff__info {
		width: auto;
	}
}

@media all and (max-width: 650px) {
  .staff__card {
    width: 100%;
    margin-right: 0;
  }
}

} //ending class=staff



