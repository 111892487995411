.flyover {
  position: fixed;
  bottom: 25px;
  right: 340px;//275px;
  z-index: 1000;
  backface-visibility: hidden;

  @media all and (max-width: 1170px) {
    right: 25px;
  }

  .excavator {
    background: $color-white;
    padding: 10px 20px 10px 20px;
    border-radius: 30px;
    border: 1px solid $color-dark-gray;
    //box-shadow: 0 0 20px rgba($color-dark-gray, 0.5);
    cursor: pointer;
    display: none;

    &.-activated {
      display: inline-block;
    }

    .icon {
      display: inline-block;
      vertical-align: top;
      position: relative;
      top: 4px;
    }

    .name {
      display: inline-block;
      margin-left: 8px;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      font-size: 12px;

      .brand {
        display: block;
      }

      .model {
        display: block;
      }
    }
  }

  .star {
    position: relative;
    top: -2px;
    margin-left: 10px;
    display: none;
    cursor: pointer;

    &.-activated {
      display: inline;
    }

    .badge {
      position: absolute;
      font-size: 10px;
      right: 7px;
      top: -8px;
      font-family: $font-family;
      font-weight: $font-weight-medium;
      width: 12px;
    }

    .icon {
      i {
        padding: 11px;
        background: $color-white;
        border-radius: 50%;
        border: 1px solid $color-dark-gray;
        //box-shadow: 0 0 20px rgba($color-dark-gray, 0.5);
        font-size: 23px;
      }
    }
  }
}
