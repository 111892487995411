.tabBarAttachments {
  margin-top: 25px;
  margin-bottom: 25px;

  .navigation {
    margin-right: 15px;
    //float: right;
    text-align: right;
    width: 100%;
    
    ul {
        a {
          text-decoration: none;
          display: inline-block;
          font-family: $font-family;
          font-weight: $font-weight-medium;
          color: $color-dark-gray;
          width: 100%;
          border-bottom: 4px solid transparent;

          &:hover {
            color: $color-black;
            border-bottom-color: $color-yellow;
          }

          li {
            display: inline-block;
            //margin-right: 35px;
            text-decoration: none;
            padding: 10px 15px 10px 0;
            display: inline-block;
            font-family: $font-family;
            font-weight: $font-weight-medium;
            //color: $color-light-black;

            &.-active {
              border-bottom: 4px solid $color-yellow;

              a {
                color: $color-light-black;
              }
            }
          } 
        }

      }
    }   

  .tableContainer {
    //display: table-cell;
    float: left;
    margin-top: 25px;
    //width: calc((100% - 260px) / 9);
    width: calc(100%  / 9);
    padding-bottom: 35px;

    @include breakpoint(handheld) {
        width: calc((100% - 230px) / 7);
    }        
    @include breakpoint(tablet) {
        width: calc((100% - 230px) / 5);
    }
    @include breakpoint(mobile) {
        width: calc((100% - 230px) / 1);
    }
    &.first-column {
        min-width: 260px;
        @include breakpoint(handheld) {
            min-width: 230px;
        }
    }

    &:hover {
      .tab {
        .dataTable {
          ul {
            li {
              background: $color-light-gray;
              &.-odd {
                background: $color-ultra-light-gray;
              }
            }
          }
        }
      }
    }
  }

  .tab {
    //display: none;

    // TODO fix colors
    //border-top: 2px solid #DCDCDC;
    //border-bottom: 1px solid #DCDCDC;
    //background: #FBFBFB;

    .dataTable {
      ul {
        width: 100%;
        float: left;
        
        &:hover {
          li {
            background: $color-light-gray;
            &.-odd {
              background: $color-ultra-light-gray;
            }
          }
        }
        //border-bottom: 4px solid $color-light-gray;

        &:last-child {
          //border-top: 2px solid $color-light-gray;
          border-bottom: 0;
        }

        li {
          padding: 0 15px;
          background: #FBFBFB;
          border-bottom: 1px solid #DCDCDC;
          font-family: $font-family;
          font-weight: 400;
          color: $color-ultra-dark-gray;
          font-size: 0;
          //text-transform: capitalize;
          display: table;
          width: 100%;
          height: 46px;

          &:last-child {
            border-bottom: 1px solid transparent;
          }

          &.-odd {
            background: $color-white;
          }

          span {
              vertical-align: middle;
          }

          .key {
            //width: 30%;
            display: none;
            font-size: 15px;

            &.-active {
                display: table-cell;
            }
          }

          .value {
            //width: 70%;
            display: table-cell;
            text-align: right;
            font-size: 15px;
            //text-transform: lowercase;

            @include breakpoint(tablet) {
              text-align: right;
            }
          }

          &:after {
            content: '';
            display: table;
            clear: both;
          }
        }
      }
    }

    .documents {
      padding: 10px;

      ul {
        display: flex;
        flex-flow: row wrap;

        li {
          display: flex;
          flex-flow: column wrap;
          border: 1px solid $color-light-gray;
          display: inline-block;
          vertical-align: top;
          background: $color-white;
          width: calc(20% - 30px);
          max-width: 200px;
          min-width: 180px;
          margin: 10px;

          @include breakpoint(tablet) {
            width: calc(33% - 30px);
          }

          @include breakpoint(mobile) {
            width: calc(100%);
          }

          a {
            display: flex;
            flex-flow: column wrap;
            text-decoration: none;
          }

          figure {
            position: relative;
            overflow: hidden;
            width: 100%;

            & .thumb {
              position: absolute;
              top: 10px;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              width: 50%;
            }
          }

          img {
            width: 100%;
          }

          h4 {
            flex-grow: 1;
            padding: 20px;
          }
        }
      }
    }

    &.-active {
      display: block;
    }
  }

  @media all and (max-width: 900px) {
    .navigation {
      ul {
        display: table;
        width: 100%;

        li {
          display: table-cell;
          text-align: center;
          margin-right: 0;
          word-wrap: break-word;
          padding: 10px;
        }
      }
    }
  }
}

.tabBar {
  //margin-top: 25px;
  //margin-bottom: 25px;

  .navigation {
    ul {
      li {
        display: inline-block;
        border-bottom: 4px solid transparent;
        margin-right: 35px;

        a {
          text-decoration: none;
          padding: 10px 0 10px 0;
          display: inline-block;
          font-family: $font-family;
          font-weight: $font-weight-medium;
          color: $color-dark-gray;
        }

        &.-active {
          border-bottom: 4px solid $color-yellow;

          a {
            color: $color-light-black;
          }
        }
      }
    }
  }

  .tab {
    //display: none;

    // TODO fix colors
    //border-top: 2px solid #DCDCDC;
    //border-bottom: 1px solid #DCDCDC;
    //background: #FBFBFB;

    .dataTable {
      ul {
        width: 100%;
        border-bottom: 4px solid $color-light-gray;

        &:last-child {
          border-bottom: 0;
        }

        li {
          padding: 15px;
          background: #FBFBFB;
          border-bottom: 1px solid #DCDCDC;
          font-family: $font-family;
          font-weight: 400;
          color: $color-ultra-dark-gray;
          font-size: 0;
          //text-transform: capitalize;
          display: table;
          width: 100%;

          &:last-child {
            border-bottom: 1px solid transparent;
          }

          &.-odd {
            background: $color-white;
          }

          .key {
            width: 30%;
            display: table-cell;
            font-size: 15px;
          }

          .value {
            width: 70%;
            display: table-cell;
            float: right;
            font-size: 15px;
            text-align: right;
            //text-transform: lowercase;

            @include breakpoint(tablet) {
              text-align: right;
            }
          }

          &:after {
            content: '';
            display: table;
            clear: both;
          }
        }
      }
    }

    .documents {
      //padding: 10px;

      ul {
        display: flex;
        flex-flow: row wrap;

        li {
          display: flex;
          flex-flow: column wrap;
          border: 1px solid $color-black;
          display: inline-block;
          vertical-align: top;
          background: $color-black;
          width: calc(20% - (80px / 5));
          //max-width: 200px;
          //min-width: 180px;
          margin-right: 10px;
          margin-left: 0px;
          position: relative;
          transition: transform 250ms $apple-ease;

          @include breakpoint(tablet) {
            width: calc(33% - 30px);
          }

          @include breakpoint(mobile) {
            width: calc(100%);
          }

          &:before
            {
              content:"";
              position:absolute;
              z-index: -1;
              box-shadow:0 0 20px rgba(0,0,0,0.2);
              bottom: 7px;
              left: 5%;
              border-radius:100px / 10px;
              width: calc(90% - 5px);
              height: calc(90% - 7px);
              opacity: 0;

              animation: initFeatureShadow 500ms forwards;

              transition: box-shadow 250ms $apple-ease, height 250ms $apple-ease;

              transform: translateY(4px);

              @keyframes initFeatureShadow {
                0% {
                  opacity: 0;
                }
                100% {
                  opacity: 1;
                }
              }
            }

            &:hover {
              transform: translateY(-5px);

              &:before {
                box-shadow:0 0 50px rgba(0,0,0,0.4);
              }
            }

          a {
            display: flex;
            flex-flow: column wrap;
            text-decoration: none;
            background-color: $color-black;
          }

          figure {
            position: relative;
            overflow: hidden;
            width: 100%;

            & .thumb {
              position: absolute;
              top: 10px;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;
              width: 50%;
            }
          }

          img {
            width: 100%;
          }

          h4 {
            flex-grow: 1;
            padding: 20px;
            text-align: center;
            color: $color-yellow;
          }
        }

        li+li {
            margin-left: 10px;
        }

        li:last-of-type {
            margin-right: 0px;
        }
      }
    }

    &.-active {
      display: block;
    }
  }

  @media all and (max-width: 900px) {
    .navigation {
      ul {
        display: table;
        width: 100%;

        li {
          display: table-cell;
          text-align: center;
          margin-right: 0;
          word-wrap: break-word;
          padding: 10px;
        }
      }
    }
  }
}
