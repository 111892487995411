.historyLinks {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 35px;
  visibility: hidden;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: opacity 500ms $apple-ease, max-height 250ms $apple-ease;
  white-space: nowrap;
  font-size: 0;

  @include breakpoint(tablet) {
    margin-bottom: 20px;
  }

  .ieLessThan11 &,
  .no-flexbox & {
    display: block;
    @include clearfix;
  }

  &.-activated {
    visibility: visible;
    opacity: 1;
    max-height: 50px;
    overflow: hidden;

    &.articlePage {
      position: absolute;
      bottom: 0px;
      width: calc(100% - 50px);
      max-width: 1170px;

      @include breakpoint(desktop) {
        position: relative;
        bottom: 0px;
        width: 100%;
     } 
    }
  }

  &__item {
    display: inline-block;
    vertical-align: top;
    visibility: hidden;
    transition: opacity 500ms $apple-ease;
    opacity: 0;
    text-align: center;

    &.-activated,
    &.-list {
      visibility: visible;
      opacity: 1;
    }

    &.-prev {
      flex-basis: 30%;
      text-align: left;

      &.-cardActivated {
        .card {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &.-next {
      flex-basis: 30%;
      text-align: right;

      &.-cardActivated {
        .card {
          visibility: visible;
          opacity: 1;
          right: 0;
        }
      }
    }

    .card {
      position: absolute;

      width: 230px;

      margin-top: 25px;

      visibility: hidden;
      opacity: 0;

      transition: opacity 250ms $apple-ease;

      z-index: 300;

      margin-right: 0;
      margin-bottom: 0;

      .arrow {
        position: relative;
        top: 1px;

        &:after, &:before {
          bottom: 100%;
          left: 50%;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          margin-top: -1px;
        }

        &:after {
          border-color: rgba(255, 255, 255, 0);
          border-bottom-color: $color-white;
          border-width: 15px;
          margin-left: -15px;
        }

        &:before {
          border-color: rgba(51, 51, 51, 0);
          border-bottom-color: $color-light-gray;
          border-width: 16px;
          margin-left: -16px;
          transition: border .2s $apple-ease;
        }
      }

      &:hover {
        .arrow {
          &:before {
            border-bottom-color: $color-dark-gray;
          }
        }
      }
    }

    .ieLessThan11 &,
    .no-flexbox & {
      width: 33.333%;
      min-height: 1px;
    }
  }

}
