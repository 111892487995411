.modalDialogShader {
  background: rgba($color-solid-black, 0.5);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 100;
  opacity: 0;
  animation: modalDialogShader 500ms forwards;

  .no-cssanimations & {
    opacity: 1;
  }

  @keyframes modalDialogShader {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &.-hide {
    opacity: 1;

    animation: modalDialogShaderHide 500ms forwards;

    @keyframes modalDialogShaderHide {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }
}

.modalDialog {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  text-align: center;
  transform: translate3d(0, -100%, 0);
  animation: modalDialog 500ms forwards;
  transform-style: preserve-3d;

  @keyframes modalDialog {
    0% {
      transform: translate3d(0, -100%, 0) scale(0.95);
    }
    100% {
      transform: translate3d(0, 0, 0) scale(1);
    }
  }

  &.-hide {
    animation: modalDialogHide 500ms forwards;

    @keyframes modalDialogHide {
      0% {
        transform: translate3d(0, 0, 0) scale(1);
      }
      100% {
        transform: translate3d(0, 200%, 0) scale(0.95);
      }
    }
  }

  .subPage--image & {
    animation: modalDialogSubPage 500ms forwards;
    transform: translate3d(0, 0, 0);
    opacity: 0;

    @keyframes modalDialogSubPage {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    &.-hide {
      animation: modalDialogSubPageHide 500ms forwards;

      @keyframes modalDialogSubPageHide {
        0% {
          opacity: 1;
        }
        100% {
          opacity: 0;
        }
      }
    }
  }

  &__container {
    width: auto;
    height: auto;
    margin: 0 auto;
    min-width: 550px;
    max-width: 500px;
    max-height: 50%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-white;
    -webkit-overflow-scrolling: touch;

    @supports (overflow:-webkit-marquee) and (justify-content:inherit)
    {
      max-height: 80vh !important;
    }

    border-radius: 3px;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .ieLessThan11 &, .no-flexbox & {
      display: block;
      overflow: auto;
    }

    .modalDialog__header {
      background-color: $color-ultra-light-gray;
      width: 100%;
      padding: 15px 0 13px;
      border-bottom: 1px solid $color-light-gray;
      text-align: center;
      position: relative;
      z-index: 106;

      @supports (overflow:-webkit-marquee) and (justify-content:inherit)
      {
        padding-bottom: 30px;
      }

      .close {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: $color-dark-gray;

        transition: color 250ms $apple-ease;

        &:hover {
          color: $color-black;
        }
      }
    }

    .modalDialog__content {
      flex-grow: 1;
      overflow: auto;
      padding-bottom: 20px;
      display: inline-block;
    }

    .message {
      width: 80%;
      text-align: center;
      padding-top: 30px;
      padding-bottom: 30px;
      margin: 0 auto;

      border-bottom: 1px dotted $color-light-gray;
    }

    .outer-svg {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba($color-black, 0.3);
      z-index: 105;
    }

    .svg.-loader {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      left: 0;

      svg {
        margin: 0 auto;
        display: block;
      }

      @include pageLoader();
    }

    .button__holder {
      margin: 0 auto;
      text-align: center;
      width: 80%;
      padding-top: 30px;
      padding-bottom: 30px;

      .btn {
        &:first-child {
          margin-right: 10px;
        }

        &:last-child {
          margin-left: 10px;
        }
      }
    }
  }

  &.-image {
    padding-left: 25px;
    padding-right: 25px;

    .modalDialog__container {
      max-width: 1170px;
      max-height: 95%;
      min-width: 0;
      display: inline-block;
      background-color: $color-white;
      overflow: auto;

      .modalDialog__content {
        width: 100%;
        height: 100%;
        display: inline-block;
        padding-bottom: 0;
        padding-top: 0;
        padding: 30px;

        img {
          margin: 0 auto;
        }
      }
    }
  }

  &.-video {
    overflow: auto;

    .close-icon {
      position: absolute;
      z-index: 1;
      top: -25px;
      right: 0;
      width: 20px;
      height: 20px;

      i {
        color: $color-white;
        cursor: pointer;
      }
    }

    iframe {
      width: 100%;
      height: 100%;
      display: table-cell;
    }

    .modalDialog__header {
      display: none;
    }

    .modalDialog__container {
      display: block;
      max-width: 1200px !important;
      top: ($header-height + 35px);
      transform: none;
      max-height: none;
      border-radius: 0;
      margin-bottom: 100px;
      background: none;
      overflow: visible;
      padding: 20px;
      min-height: 500px;

      .subPage--image & {
        top: ($header-height + 110px);
      }

      .subPage--vertical & {
        .modalDialog__content {
          max-width: 50%;
          padding-right: 30px;
        }
      }

      @media all and (max-width: 900px) {
        height: 300px;
        max-width: 70vw !important;

        .subPage--vertical & {
          height: 500px;

          .modalDialog__content {
            max-width: 100%;
            padding-right: 0;
          }
        }
      }
    }

    & .modalDialog__content {
      display: block;
      position: relative;
      padding-bottom: 56.25%;
      width: 100%;
      height: 0;
      overflow: visible;

      & iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }

  }

  @media all and (max-width: 1170px) {
    width: 100%;

    .modalDialog__container {
      min-width: 0;
      min-height: 0;
      max-height: 85vh !important;
      max-width: 95vw !important;
    }
  }
}

.-noScroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}
