.facebook-banner {
  position: fixed;
  bottom: calc(100%);
  right: 0;
  padding: 0;
  z-index: 6;
  width: 250px;
  @include breakpoint(mobile) {
    width: 250px;
  }
  transform: translate3d(100%, 0, 0);

  transition: transform 500ms $apple-ease;

  .no-csstransforms3d & {
    left: 100%;
  }

  &.-activated {
    transform: translate3d(0, 0, 0);

    .no-csstransforms3d & {
      left: 0;
    }

    .content {
      &__container {
        .description {
          opacity: 1;
        }

        .button-secondary {
          opacity: 1;
        }
      }
    }
  }

  #close {
      position: absolute;
      right: 0;
      z-index: 7;
      padding: 5px;
      background: $color-yellow;

      i {
          cursor: pointer;
          &:hover {
              color: #fff
          }
      }
  }

  .content {
    width: 100%;

    &__container {
      max-width: 250px;
      padding: 0;

      .description {
        position: relative;
        top: 12.5px;

        opacity: 0;

        transition: opacity 1000ms $apple-ease;

        .no-cssanimations & {
          opacity: 1;
        }

        a {
          font-size: 15px;
        }
      }

      .button-secondary {
        float: right;
        opacity: 0;

        transition: opacity 1000ms $apple-ease;

        .no-cssanimations & {
          opacity: 1;
        }
      }

      &:after {
        clear: both;
        display: table;
        content: '';
      }
    }

    @media all and (max-width: 1170px) {
      width: 100%;
    }
  }


  @media all and (max-width: 1170px) {
    .-menuActivated &.-activated {
      transform: translate3d(-250px, 0, 0);

      .no-csstransforms3d & {
        transform: translateX(-250px);
      }
    }
  }
  
  @media all and (max-width: 750px) {
    text-align: center;

    .content {
      &__container {
        .description {
          display: block;
          position: static;
        }
      }
    }
  }
}