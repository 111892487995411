*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;
  overflow-y: auto;

  &.-searchActivated {
    overflow: hidden;
    position: fixed;
    width: 100%;
  }
}

body {
  position: relative;
  overflow: hidden;
  cursor: default;

  // For js detection of media queries
  &:after {
    display: none;
    content: 'desktop';

    @include breakpoint(handheld) {
      content: 'handheld';
    }

    @include breakpoint(tablet) {
      content: 'tablet';
    }

    @include breakpoint(mobile) {
      content: 'mobile';
    }
  }
}

img {
  display: inline-block;
  vertical-align: middle;
  max-width: 100%;
  height: auto;
  -ms-interpolation-mode: bicubic;
}

.-block {
  display: block;
  width: 100%;
}
