.flexboxGrid {
  display: flex;
  flex-flow: row wrap;

  .ieLessThan11 &,
  .no-flexbox & {
    display: block;
    font-size: 0;
  }

  &.-rowWrap {
    flex-flow: row wrap;
  }

  &.-columnWrap {
    flex-flow: column wrap;
  }

  &.-column4 {

    & > * {
      width: calc(25% - 22.5px);
      margin-right: 30px;
      margin-top: 15px;
      margin-bottom: 15px;

      &:nth-child(4n + 4) {
        margin-right: 0;
      }

      @include breakpoint(handheld) {
        width: calc(50% - 15px);

        &:nth-child(2n + 2) {
          margin-right: 0;
        }
      }

      @include breakpoint(tablet) {
        width: calc(50% - 7px);
        margin-right: 14px;
        margin-top: 14px;
        margin-bottom: 0;
      }

      @include breakpoint(tiny) {
        width: 100%;
        margin-right: 0;
      }
    }
  }

}
