.accordion {
  position: relative;
  border: solid 1px $color-light-gray;
  background-color: $color-white;
  width: calc(100% - 24px);
  margin-left: 24px;
  transition: border .2s $apple-ease, box-shadow .2s $apple-ease;

  &:hover,
  &:focus {
    outline: none;
    border-color: $color-ultra-dark-gray;
  }

  &.-active {
    box-shadow: 0 0 8px rgba(0,0,0,.2);
  }

  &:after {
    content: '';
    position: absolute;
    top: 34px;
    left: -19px;
    bottom: -54px;
    width: 1px;
    background-color: $color-light-gray;
  }

  &:last-child:after {
    display: none;
  }

  & + .accordion {
    margin-top: 20px;
  }

  &__header {
    position: relative;
    padding: 15px 20px;
    cursor: pointer;
    user-select: none;

    &:before {
      content: '';
      position: absolute;
      z-index: 1;
      top: calc(50% - 7px);
      left: -25px;
      width: 14px;
      height: 14px;
      border-radius: 2px;
      background-color: $color-dark-gray;
      transition: background-color .2s $apple-ease;

      .-active & {
        background-color: $color-yellow;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: -11px;
      width: 10px;
      height: 1px;
      background-color: $color-light-gray;
    }
  }

  &__title {
    color: $color-ultra-dark-gray;
    font-weight: $font-weight-medium !important;
    transition: color .2s $apple-ease;
    padding-right: 53px;

    .-active & {
      color: $color-black;
    }
  }

  &__toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 20px;
    color: $color-yellow;

    & .fa-minus {
      display: none;
    }

    .-active & {
      & .fa-plus {
        display: none;
      }

      & .fa-minus {
        display: inline-block;
      }
    }
  }

  a {
    text-decoration: none;
  }

  &__body {
    height: 0;
    overflow: hidden;
    padding: 0 53px 0 20px;

    & p.sv-font-ingress {
      font-size: 16px !important;
      font-weight: $font-weight-normal !important;

      & a {
        font-family: inherit;
        font-size: inherit;
        font-weight: inherit;
      }
    }

    & ol.sv-font-ingress {
      font-size: 16px !important;
      font-weight: $font-weight-normal !important;
    }
  }

  &__content {
    @include clearfix;
  }

  &__link {
      
      &.-active {
        position: absolute;
        bottom: 16px;
        right: 16px;
        width: 40px;
        height: 40px;
        margin-left: 0px;
        border: 1px solid #ddd;
        border-radius: 50%;
        box-sizing: border-box;

        i {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 198;
            margin: -8px 0 0 -8px;
            box-sizing: border-box;
            font-size: 20px;
            color: #FFD300;
        }

        &:hover {
            border-color: $color-black;
        }
      }
  } 
}