.feature {
  height: 350px;
  width: 270px;
  background-color: $color-white;
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  display: inline-block;
  text-align: center;
  z-index: 1;

  &.-width-200{
    height: 700px;
  }

  @include breakpoint(mobile) {
    height: 250px;
  }

  .feature__container {
    position: absolute;
    left: 0;
    bottom: 30px;
    width: 100%;
    text-align: left;

    @include breakpoint(mobile) {
      bottom: 20px;
    }

    .content {
      background: $color-white;
      padding: 5px;
      transform: skew(-5deg);
      padding-left: 65px;
      padding-right: 15px;
      left: -50px;
      position: relative;
      transition: transform 250ms $apple-ease;
      max-width: 460px;

      @include breakpoint(mobile) {
        width: 100%;
        left: 0;
        transform: none;
        padding-left: 20px;
      }

      .logotype {
        position: absolute;
        transform: skew(5deg);
        display: inline-block;
        top: -45px;
        height: 33px;
        left: 75px;

        @include breakpoint(mobile) {
          transform: none;
          left: 15px;
        }
      }

      &__container {
        transform: skew(5deg);

        .ie9 &, .ie10 & {
          width: 155px;
        }

        @include breakpoint(mobile) {
          transform: none;
        }

        h3 {
          font-size: 18px !important;
        }

        p {
          font-family: $font-family;
          font-size: 16px;
          word-wrap: break-word;
          width: 100%;
          margin: 0 !important;

          @include breakpoint(mobile) {
            font-size: 14px !important;
          }
        }
      }
    }
  }
}
