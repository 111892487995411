.documentsListingPage {

    #subscribe {
        margin-bottom: 30px;

        &.-activated {
            margin-bottom: 0px;
        }
    }

    .-inactive {
      display: none;
    }

    #subscription-form {
        display: none;

        &.-activated {
            display: block;
        }

        .error {
          border-color: $color-red;
        }

        label {
          &.error {
            color: $color-red;

            a {
              color: $color-red;
            }
          }
        }
    }

    .printarchive__subscription {
        display: none;

        &.-activated {
            display: block;
        }
    }

    .printarchive__subscribe {
        //padding-top: 30px;
    }

    .sv-font-button-outlined-subscribe {
      width: 100%;
      //margin-top: 1em;

      & a {
        @extend .btn;
        @extend .-outlined;

        width: 100%;

        &:after {
          display: inline-block;
          vertical-align: baseline;
          content: '\f078';
          font-family: $font-family-icon;
          color: $color-yellow;
          margin-left: 8px;
          margin-top: -2px;
          transition: transform 200ms $swift-out;
        }
      
        &.-activated {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }

    .dropdown--country, .dropdown--state {
        width: 100%;
        margin: 10px 0 20px;
    }

    .dropdown.dropdown--country .dropdown__container li.-activated, .dropdown.dropdown--state .dropdown__container li.-activated {
        font-size: 15px;
        padding: 16px;
    }
}