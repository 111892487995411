.reference {
	display: block;
	position: relative;
	background: #f0f0f0;
  padding-bottom: 50px;
  padding-top: 25px;

	&__wrap {
		padding-bottom: 20px;
		border-bottom: 1px solid $color-light-gray;
	}

	&__title {
		font-family: $font-family;
    font-weight: $font-weight-super;
		font-size: 40px;
		line-height: 50px;
		color: $color-black;
	}

	&__body {
		font-family: $font-family;
		color: $color-black;
		font-size: 16px;
		line-height: 22px;
	}

	&__filter {
		display: block;
		background: #FFFFFF;
		padding: 20px;
		margin-top: 25px;

		.filterTitle {
			font-family: $font-family;
      font-weight: $font-weight-super;
			color: $color-black;
			padding: 0;
		}

		.dropdown {
			&.-referencedrop:nth-child(2) {
				width: 28%;
				margin: 5px 5px 5px 0px;
			}
			&.-referencedrop:nth-child(3) {
				width: 28%;
				margin: 5px 5px 6px 5px;
			}
			// &.-referencedrop:nth-child(4) {
			// 	width: 41% !important;
			// 	margin: 5px 0px 5px 5px;
			// }

			&--referenceCountry {
				z-index: 6;
			}

			&--referenceCategory {
				z-index: 6;
			}
		}

      .filterSearch {
        //display: table;
        display: inline-block;
        width: 41%;
        position: relative;
        margin: 6px 0px 5px 5px;
        top: 3px;

        input {
          width: 89%;
          outline: 0;
          border: 1px solid $color-light-gray;
          padding: 12px 20px 12px 17px;
          font-family: $font-family;
          font-size: 16px;
          border-radius: 3px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-right: 0;
          display: table-cell;
        }

        a {
          //display: table-cell;
          position: absolute;
          right: 0;
          display: inline-block;
          text-align: center;
          vertical-align: middle;
          background: $color-yellow;

          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;

          text-decoration: none;

          width: 14%;
          height: 100%;

          padding-left: 25px;
          padding-right: 25px;

          font-family: $font-family;
          font-weight: $font-weight-medium;

          color: $color-white;

          .fa {
          	position: relative;
          	top: 12px;
          	right: 2px;
          }
        }
      }

      @media all and(min-width: 900px) and (max-width: 1297px) {
        .dropdown {
          &.-contactdrop:nth-child(1) {
            width: 28% !important;
            margin: 6px 3px 5px 5px !important;

          }
          &.-contactdrop:nth-child(3) {
            width: 28% !important;
            margin: 6px 0px 5px 5px !important;
          }
        }
        .filterSearch {
          width: 40%;
        }
      }

      @media all and(min-width: 711px) and (max-width: 997px) {
      	.filterSearch {
      		width: 98%;
      	}
      	.dropdown {
	      	&.-referencedrop:nth-child(2) {
	      		width: 48% !important;
	      		margin: 6px 3px 5px 5px !important;
  	    	}
	      	&.-referencedrop:nth-child(3) {
	      		width: 48% !important;
	      		margin: 6px 0px 5px 5px !important;
  	    	}
      	}
    	}
      @media all and(max-width: 700px) {
      	.filterSearch {
      		width: 100%;
      	}
      	.dropdown {
	      	&.-referencedrop {
	      		width: 100% !important;
	      		margin: 6px 0px 5px 5px !important;
  	    	}
      	}
    	}
	}

  .noResults {
    margin-top: 25px;
    &.-deactivated {
      display: none;
    }
  }

}
