.productPage {
  font-size: 0;
  position: relative;

  & img {
    max-width: 100% !important;
  }

  .articleTopImage {
    height: 55vh;
    @include breakpoint(tablet) {
        height: 500px;
    }
    @include breakpoint(mobile) {
        height: 300px;
    }
    margin-bottom: 20px;
    overflow: hidden;
    position: relative;

      & .sv-image-portlet {

        & img {
          width: 100%;
          max-height: 100% !important;
          height: 100%;
          object-fit: cover;
          z-index: -1;
          position: absolute;
        }
      }
  }

  .imageBlock {
    .bookmark {
      position: absolute;
      margin-top: 20px;
      margin-left: 20px;
      z-index: 2;
    }
  }

  .info {
    width: 50%;
    display: inline-block;
    font-size: 1em;
    vertical-align: top;
    padding: 10px 0 0 20px;

    .description {
      margin-top: 15px;

      p {
        display: block !important;
        margin-top: 15px;
      }

      /*
      ul {
        font-size: 16px;

        & li {
          position: relative;
          padding-left: 20px;
          margin-top: 10px;
          font-family: $font-family;
          line-height: 150%;

          & + li {
            margin-top: .25em;
          }

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            content: '+';
            color: $color-yellow;
            font-family: $font-family;
            font-size: 16px;
          }

          & ol {
              &:first-child {
                margin-top: .25em;
              }
              &:last-child {
                margin-bottom: .25em;
              }
              & li {
                padding-left: 0px;
                counter-increment: item;
                list-style-type: none;
                padding-left: 20px;

                &:before {
                  font-weight: 500;
                  color: $color-black;
                  content: counter(item) ".";
                }
              }
            }
        }
      }*/
      /*
      ol {
        font-size: 16px;

        & li {
          position: relative;
          padding-left: 20px;
          margin-top: 10px;
          font-family: $font-family;
          line-height: 150%;

          & + li {
            margin-top: .25em;
          }

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            content: '+';
            color: $color-yellow;
            font-family: $font-family;
            font-size: 16px;
          }
        }
      }*/
      /*
      ol.generated-list-name-NumberedYellowRound,
        ul.generated-list-name-NumberedYellowRound {
          list-style: none !important;
          counter-reset: yellowList;

          & li {
            display: block;
            vertical-align: top;
            position: relative;
            padding-left: 35px;
            margin-bottom: 10px;
            margin-top: 5px;
            counter-increment: yellowList;

            &:before {
              position: absolute;
              top: -3px;
              left: 0;
              display: inline-block;
              content: counter(yellowList);
              color: $color-black;
              font-family: $font-family-icon;
              font-weight: 900;
              font-size: 14px;
              background: $color-yellow;
              border-radius: 50%;
              width: 25px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              user-select: none;
            }
          }
        }*/
    }

    .markings {
      margin-top: 25px;
      margin-bottom: 25px;

      li {
        font-size: 12px;
        display: inline-block;
        padding: 10px 15px;
        border-radius: 30px;
        border: 1px solid $color-dark-gray;
        font-family: $font-family;
        font-weight: $font-weight-medium;
        margin-right: 10px;
      }
    }

    .info__footer {
      margin-top: 15px;

      .markingLogos {
        display: inline-block;
        width: 40%;
        position: relative;
        top: -5px;

        ul {
          li {
            display: inline-block;
            margin-right: 15px;
            max-height: 80px;

            img {
              max-height: 80px;
            }
          }
        }
      }

      .compatible {
        display: none;
        border: 1px solid $color-light-gray;
        transform: skewX(-12deg);
        width: 60%;
        float: right;

        &.-activated {
          display: block;
        }

        .container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          padding: 2px 20px 10px 20px;
          transform: skewX(12deg);
        }

        .text {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: center;

          .text__holder {
            display: block;
            width: 100%;
          }

          p {
            display: block;
            width: 100%;

            .brand, .model {
              font-family: $font-family;
              font-weight: $font-weight-super;
              font-size: 18px;
            }
          }
        }

        .logo {
          width: 25%;
          display: inline-block;
          overflow: hidden;

          img {
            width: 100%;
          }
        }
      }
    }

    .button {
      width: 100%;
      margin-top: 25px;

      .button__container {
        width: 100%;
        text-align: center;

        a {
          width: 100%;
        }
      }

      &.-contact {
        a {
          &:before {
            content: '\f0e5';
            font-family: "FontAwesome";
            color: $color-white;
            margin-right: 10px;
          }
        }
      }

      &.-readMore {
        a {
          &:after {
            content: '\f054';
            font-family: "FontAwesome";
            color: $color-white;
            margin-left: 10px;
          }
        }
      }
    }

    @media all and (max-width: 900px) {
      width: 100%;
      padding-left: 0;
    }
  }

    .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 24px;
    

        input { 
            opacity: 0;
            width: 0;
            height: 0;

            &:checked + .slider {
                background-color: #000;
            }

            &:focus + .slider {
                box-shadow: 0 0 1px #000;
            }

            &:checked + .slider:before {
                -webkit-transform: translateX(26px);
                -ms-transform: translateX(26px);
                transform: translateX(26px);
            }
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #000;
        -webkit-transition: .4s;
        transition: .4s;
        border-radius: 24px;
    
        &:before {
            position: absolute;
            content: "";
            height: 16px;
            width: 16px;
            left: 4px;
            bottom: 4px;
            background-color: #ffcb00;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }
    }

  .unitSwitcher {
      position: relative;
      height: 100px;

    &-wrapper {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        div {
            display: inline;
            vertical-align: middle;

            p {
                padding: 15px;
                color: $color-light-gray;
                font-weight: 500; 
                -webkit-transition: .4s;
                transition: .4s;

                &.active {
                    color: $color-black;
                    -webkit-transition: .4s;
                    transition: .4s;
                }
            }
        }
    }
  }

  .tabBarAttachments {
    margin-top: 0px;
    margin-bottom: 0px;

    .tableContainer {
        margin-top: 0px;
    }
  }

  .new-product-wrapper {
      position: relative;

      .new-product {
          position: absolute;
          width: 30px;
          height: 30px;
          top: -15px;
          right: -15px;
          background-position: top right;
          background-size: contain;
          background-repeat: no-repeat;
          z-index:-1;
      }
  }
}

.relatedProducts {

    ul {
    display: flex;
    flex-flow: row wrap;

    li {
        display: flex;
        flex-flow: column wrap;
        border: 1px solid $color-light-gray;
        display: inline-block;
        vertical-align: top;
        background-color: $color-ultra-light-gray;
        background-image: url('/webdav/images/Patterns/background-grey-small.jpg');
        //background-size: cover;
        width: calc((100% / 6) - (100px / 6));
        //max-width: 200px;
        //min-width: 180px;
        margin: 10px;
        margin-left: 0px;
        position: relative;
        transition: transform 250ms $apple-ease;

        &:before
        {
          content:"";
          position:absolute;
          z-index: -1;
          box-shadow:0 0 20px rgba(0,0,0,0.2);
          bottom: 7px;
          left: 5%;
          border-radius:100px / 10px;
          width: calc(90% - 5px);
          height: calc(90% - 7px);
          opacity: 0;

          animation: initFeatureShadow 500ms forwards;

          transition: box-shadow 250ms $apple-ease, height 250ms $apple-ease;

          transform: translateY(4px);

          @keyframes initFeatureShadow {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        }

        &:hover {
          transform: translateY(-5px);

          &:before {
            box-shadow:0 0 50px rgba(0,0,0,0.4);
          }
        }

        @include breakpoint(tablet) {
        width: calc(33% - 30px);
        }

        @include breakpoint(mobile) {
        width: calc(100%);
        }

        a {
        display: flex;
        flex-flow: column wrap;
        text-decoration: none;
        background-image: url('/webdav/images/Patterns/background-grey-small.jpg');
        }

        figure {
        position: relative;
        overflow: hidden;
        width: 100%;
        background-color: $color-white;

        & .thumb {
            position: absolute;
            top: 10px;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            width: 50%;
        }

        }

        img {
        width: 100%;
        padding: 20px;
        }

        h4 {
        flex-grow: 1;
        padding: 20px;
        text-align: center;
        }

    }

    li+li {
        margin-left: 10px;
    }

    li:last-of-type {
        margin-right: 0px;
    }
    }
}

.productReferences {
  margin-top: 50px;
  margin-bottom: 50px;
}

.productFAQ__container {
  background: $color-ultra-light-gray;
  border-top: 1px solid $color-light-gray;
  padding-top: 50px;
  padding-bottom: 50px;
}

    .expand-down {
        position: relative;
        //padding: 30px;
        //text-align:center;
        vertical-align:middle;

        i {
            position: absolute;
            top: -12px;
            font-size: 18px;
            //left: calc(50% - 23px);
            width: 48px;
            height: 48px;
            border-radius: 100%;
            border: 1px solid $color-light-gray;
            background-color: $color-white;
            font-weight: 800;
            color: $color-yellow;
            //margin-top: 30px;
            //margin-bottom: 30px;

            animation: colorchange 3s infinite; /* animation-name followed by duration in seconds*/
            /* you could also use milliseconds (ms) or something like 2.5s */
            -webkit-animation: colorchange 3s infinite; /* Chrome and Safari */

            @keyframes colorchange
            {
              0%   {border-color: $color-light-gray;}
              50%  {border-color: $color-dark-gray;}
              100% {border-color: $color-light-gray;}
            }

            @-webkit-keyframes colorchange /* Safari and Chrome - necessary duplicate */
            {
              0%   {border-color: $color-light-gray;}
              50%  {border-color: $color-dark-gray;}
              100% {border-color: $color-light-gray;}
            }

            &:before {
              margin: 14px 0 0 17px;
              position: absolute;
            }

            &:hover {
                animation: none;
                -webkit-animation: none;
                border-color: $color-dark-gray;
            }
        }
        a {
            position: relative;
        }
        h4 {
            padding-right: 10px;
            //margin-left: -46px;
        }
    }

    #foldoutContainer {
        display: none;
    }

    .expand-down {
        &.-activated {
            a {
                i {
                    transform: rotate(180deg);
                }
            }
        }
    }

    div > .section {
        //border-top: solid 1px #ddd;
    }

    .sv-font-button-outlined-expand {
      width: 100%;
      margin-top: 1em;

      & a {
        @extend .btn;
        @extend .-outlined;

        width: 100%;

        &:after {
          display: inline-block;
          vertical-align: baseline;
          content: '\f078';
          font-family: $font-family-icon;
          color: $color-yellow;
          margin-left: 8px;
          margin-top: -2px;
          transition: transform 200ms $swift-out;
        }
      
        &.-activated {
          &:after {
            transform: rotate(180deg);
          }
        }
      }
    }