.conceptPage {

  & .pagecontent .section:nth-child(2) {
    background-color: #FDFCFE;
  }

  & h2 {
    @extend .title__h1;

    @media all and (max-width: 950px) {
      font-size: 36px !important;
    }
  }

  & img {
    max-width: 100% !important;
    width: auto !important;
  }

  & video {
    width: 100%;
  }

  @include breakpoint(desktop) {
    & .sv-column-3 {
      float: left !important;
      width: calc(50% - 15px) !important;
      margin-left: 0 !important;
      margin-right: 30px !important;
      margin-top: 30px;

      @include breakpoint(mobile) {
          margin-right: 0px !important;
      }

      &:nth-child(2n + 2) {
        margin-right: 0 !important;
      }
    }
  }

  & .sv-row + .sv-row {
    margin-top:30px;
    
    @include breakpoint(handheld) {
      margin-top:0px;
    }
  }

  @include breakpoint(handheld) {
    & .sv-row {
      & > .sv-push-3 {
        left:0px!important;
      }
    }
  }

  &__mobile {
    display: none;
    text-align: center;
    margin-top: -30px;

    @include breakpoint(tablet) {
      display: block;
    }

    & > img {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    & > .sv-layout > .sv-layout {
      @include clearfix;
      text-align: left;

      & .sv-image-portlet {
        float: left;
      }

      & .sv-layout {
        overflow: hidden;
        margin-bottom: 60px;
        padding-left: 20px;
      }
    }
  }

  &__machine {
    background-color: #FDFCFE;
    padding-bottom: 120px;

    & + .section {
      position: relative;
      z-index: 2;
    }

    @include breakpoint(tablet) {
      height: auto;

      & > .sv-fluid-grid:not(.conceptPage__mobile) {
        display: none;
      }
    }

    // Texts
    & .sv-grid-responsive.sv-fluid-grid .sv-column-4 {

      & > .sv-layout,
      & .scrollmagic-pin-spacer > .sv-layout {

        & > .sv-layout {
          padding-bottom: 60px;

          & > .sv-layout {
            @include clearfix;

            & .sv-image-portlet {
              float: left;
              margin-right: 20px;
              width: 25%;

              & img {
                max-width: 100% !important;
              }
            }

            & .sv-layout {
              display: block;
              overflow: hidden;
            }
          }
        }

      }
    }

  }

}
