.sharebuttonlist {
  margin-top: 15px;
  margin-bottom: 15px;

  padding-top: 15px;
  padding-bottom: 15px;

  border-top: 1px solid $color-light-gray;
  border-bottom: 1px solid $color-light-gray;

  li {
    display: inline-block;

    a {
      text-align: center;
      background: $color-black;
      display: inline-block;
      padding: 12px 15px 10px 15px;
      width: 50px;

      i {
        color: $color-white;
        display: inline-block;
      }

      &.sharebutton--facebook {
        background: $color-facebook;
      }

      &.sharebutton--twitter {
        background: $color-twitter;
      }

      &.sharebutton--linkedin {
        background: #0077B5;
      }

      &.sharebutton--gplus {
        background: #dd4b39;
      }

      &.sharebutton--email {
        background: $color-ultra-dark-gray;
      }
    }
  }
}