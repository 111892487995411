.keyProductsPage {


  & h2 {
    font-size: 32px !important;
  }

  & .sv-image-portlet {
    text-align: center;
    @include breakpoint(tablet) {
      margin-bottom: 20px !important;
    }
  }
}
