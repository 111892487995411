.eventListingPage {

  & .dropdown {
    width: 100%;
  }

  & .filterSearch {
    position: relative;
    @include clearfix;

    @include breakpoint(mobile) {
      margin-top: 10px;
    }

    & input {
      @include reset-input('text');
      float: left;
      display: block;
      width: calc(100% - 80px);
      background-color: $color-white;
      border: solid 1px $color-light-gray;
      border-radius: 2px 0 0 2px;
      font-family: $font-family;
      font-size: 16px;
      padding: 13px 15px 10px;
      color: $color-ultra-dark-gray;

      &:focus {
        border-color: $color-gray;
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }

    }

    & button {
      @include reset-input('button');
      position: relative;
      display: inline-block;
      float: left;
      width: 80px;
      height: 46px;
      background-color: $color-yellow;
      text-align: center;
      color: $color-white;
      border-radius: 0 2px 2px 0;
      font-size: 16px;

      & i {
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 50%;
        color: $color-white;
        transform: translateY(-50%) translateX(-50%);
      }
    }

  }

}
